import React, { useState } from "react";
import { Collapse } from 'reactstrap';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import './style.css'
const EventItem = ({event}) => {
  const [isExpended, setIsExpended] = useState(false);
  return (
    <div>
      <div className="event-header" onClick={() => setIsExpended(!isExpended)}>
        <div style={{width: '45%'}}><h6>Event/Producer</h6></div>
        <div style={{width: '45%'}}><h6>City</h6></div>
        <div style={{width: '10%'}}>
          {isExpended ?
          <MdKeyboardArrowUp color="grey" />
          :
          <MdKeyboardArrowDown color="grey" />
        }
        </div>
      </div>
      <div className="event-data">
        <div style={{width: '45%'}}><p>{event.eventName}</p></div>
        <div style={{width: '45%'}}><p>{event.eventCity}</p></div>
        <div style={{width: '10%'}}></div>
      </div>
      
      <Collapse isOpen={isExpended}>
        <div className="event-data">
          <div style={{width: '45%'}}><h6>Event Start Date</h6></div>
          <div style={{width: '45%'}}><h6>Event End Date</h6></div>
          <div style={{width: '10%'}}></div>
        </div>
        <div className="event-data">
          <div style={{width: '45%'}}><p>{event.eventStartDate ? event.eventStartDate : event.eventDate}</p></div>
          <div style={{width: '45%'}}><p>{event.eventEndDate ? event.eventEndDate : event.eventDate}</p></div>
          <div style={{width: '10%'}}></div>
        </div>
        <div className="event-data">
          <div style={{width: '45%'}}><h6>Divison</h6></div>
          <div style={{width: '45%'}}><h6>Team Number</h6></div>
          <div style={{width: '10%'}}></div>
        </div>
        <div className="event-data">
          <div style={{width: '45%'}}><p>{event.eventDivision}</p></div>
          <div style={{width: '45%'}}><p>{event.teamNumber}</p></div>
          <div style={{width: '10%'}}></div>
        </div>
      </Collapse>
    </div>
  );
}
export default EventItem;