import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import { FaCheck } from 'react-icons/fa';
import { BsPencil } from 'react-icons/bs';
import { BsCalendar } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { db, storage, auth } from '../../../../firebase';
import DatePicker from "react-datepicker";
import MultiSelect from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import { captureException } from '../../../../utils/utility';

export default function CreateProgramModal(props) {
  const { onHide, currentUser, setCurrentUser, getUsers, setCreateModalShow, setEditModalShow, userObject, setUserObject, defaultUserObject, tempPass, setTempPass } = props

  const [startProgramDate, setStartProgramDate] = useState(Date.parse(new Date()));
  const [endProgramDate, setEndProgramDate] = useState(Date.parse(new Date()) + (535680*10000));
  const [editStart, setEditStart] = useState(false)
  const [editEnd, setEditEnd] = useState(false)
  const [addImageUrl, setAddImageUrl] = useState("")
  const [imageUrlChanged, setImageUrlChanged] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [disciplines, setDisciplines] = useState([]);
  const [disciplinesChanged, setDisciplinesChanged] = useState(false);
  const [selected, setSelected] = useState([]);
  const defaultImage = "https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/images%2FEE_NO_PROGRAM_IMAGE.jpg?alt=media&token=64d7af17-1926-4003-94c4-471d1190047c"

  //firebase update for user
  const userRef = db.collection('users');
  let newUid;

  const addOption = (item) => {
    return {
      label:item, 
      value:item,
    }
  }

  const signup = async (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password).then(cred => {
        // setUserObject(prev => ({...prev, uid: cred.user.uid}));
        let uid = cred.user.uid;
        newUid = uid;
        db.collection('users').doc(cred.user.uid).set({
            id: uid,
            theme: 'light',
            newUser: true
        }).then(console.log("User Created"))
        .then(addUserToDB(uid))
        .then(userRef.doc(uid).update({uid: uid}))
        .catch(err => console.log(err))
    })
  }

  const addUserToDB = async (id) => {
    console.log(userObject)
    try {
      await userRef.doc(id).update(userObject);

    } catch (e) {
      captureException({ message: `Failed to update user data for user id ${id}`, e});
    }
  }

  //function to save user to db and clean up
  const commitSave = () => {
    try {
      if(!currentUser) {
        signup(userObject.email, tempPass)
      }
      setTimeout(() => {
        if (!image) {
          let program = {
            disciplines: selected.map(item => item.value),
            imageUrl: addImageUrl,
            startDate: new Date(startProgramDate),
            endDate: new Date(endProgramDate),
          }
          userRef.doc(currentUser ? currentUser.id : newUid).set({...userObject, program: program})
          currentUser ? userRef.doc(currentUser.id ).update({programUser: true}) : userRef.doc(newUid).update({programUser: true, uid: newUid})
        } else {
          const uploadTask = storage.ref(`images/${image.name}`).put(image);
          uploadTask.on(
            "state_changed",
            () => {
              storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                  console.log("url", url)
                  let program = {
                    disciplines: selected.map(item => item.value),
                    imageUrl: url,
                    startDate: new Date(startProgramDate),
                    endDate: new Date(endProgramDate),
                  }
                  console.log(program)
                  userRef.doc(currentUser ? currentUser.id : newUid).set({...userObject, program: program})
                  currentUser ? userRef.doc(currentUser.id ).update({programUser: true}) : userRef.doc(newUid).update({programUser: true, uid: newUid})
                });
            }
          );
        }

        // getUsers()
        currentUser && setCurrentUser(null);
        // setDisciplines([]);
        setDisciplinesChanged(false);
        setSelected([]);
        setAddImageUrl("")
        setImage(null)
        setImagePreview(null)
        setUserObject(defaultUserObject)
        currentUser ? setEditModalShow(false) : setCreateModalShow(false)
        onHide()
      }, 1500);
    } catch (e) {
      captureException({ message: 'Failed during user sign up', e});
    }
  }



  const handleUrl = (e) => {
    setAddImageUrl(e.target.value)
    // handleSaveCheck()
  }

  const handleFile = (e) => {
    // handleSaveCheck()
    // URL.createObjectURL(event.target.files[0])
    setImagePreview(URL.createObjectURL(e.target.files[0]))
    setImage(e.target.files[0])
  }

  const handleStartDate = (date) => {
    setStartProgramDate(new Date(date))
    // setSponsorObject(prev => ({
    //   ...prev,
    //   startDate: new Date(date)
    // }))
    setEditStart(false)
    // setSponsorChanged(true)
  }
  const handleEndDate = (date) => {
    setEndProgramDate(new Date(date))
    // setSponsorObject(prev => ({
    //   ...prev,
    //   endDate: new Date(date)
    // }))
    setEditEnd(false)
    // setSponsorChanged(true)
  }

  useEffect(() => {
    if ((disciplinesChanged && addImageUrl) || (disciplinesChanged && image)) {
      setImageUrlChanged(true)
    }
  },[disciplinesChanged, image, addImageUrl])

  useEffect(() => {
    if(currentUser) {
      // console.log(currentUser.id)
      setUserObject(
        {
          bio: currentUser.data().bio ? currentUser.data().bio : "",
          name: currentUser.data().name,
          email: currentUser.data().email,
          phoneNumber: currentUser.data().phoneNumber,
          homeTown: currentUser.data().homeTown,
          role: currentUser.data()?.role ? currentUser.data().role : "User",
          following: currentUser.data().following ? [...currentUser.data().following] : [],
          onBoarded: currentUser.data().onBoarded ? currentUser.data().onBoarded : false,
          fcmToken: currentUser.data().fcmToken ? currentUser.data().fcmToken : "",
          listedHorses: currentUser.data().listedHorses ? [...currentUser.data().listedHorses] : [],
          photoUrl: currentUser.data().photoUrl ? currentUser.data().photoUrl : null,
          programUser: currentUser.data().programUser ? currentUser.data().programUser : false,
          status: currentUser.data().status ? currentUser.data().status : "",
          toggleCheckBox: currentUser.data().toggleCheckBox ? currentUser.data().toggleCheckBox : false,
          uid: currentUser.data().uid ? currentUser.data().uid : "",
          promoCode: currentUser.data().promoCode ? currentUser.data().promoCode : "",
          subscription: currentUser.data().subscription ? currentUser.data().subscription : [],
          homeTownCoordinates: currentUser.data().homeTownCoordinates ? currentUser.data().homeTownCoordinates : {lat: "", lng: ""},
          websiteURL: currentUser.data().websiteURL ? currentUser.data().websiteURL : "",
        }
      )
      // setSelected(currentUser.data().program.disciplines ? currentUser.data().program.disciplines.map(item => addOption(item)) : []);
    }
  }, [currentUser])
  
  useEffect(() => {

      const dropdownListRef = db.collection('my-horse-listing-dropdowns').doc('jXE8l6PiewX1VCfDoaKB');
      dropdownListRef.get()
        .then(data => setDisciplines(data.get('disciplines').map(item => addOption(item.text))))
        .catch(error => console.log(error));
  
  }, [])

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' size="xl">
      <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
            {userObject.name && `Create Program User: ${userObject.name}` }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        <Row className="w-100">
          <Col className="w-50">
              <Row className="w-100 align-items-center justify-content-between px-5">
                <h5 className="checkedText">Image:</h5>
                <img src={imagePreview ? imagePreview : addImageUrl ? addImageUrl : defaultImage} alt={`Program User Banner Ad`} className="imagesize"/>
                {/* <img src={userObject ? currentSponsor.data().image : defaultImage} alt={`${currentSponsor && convertTime(currentSponsor.data().name)} Sponsor Ad`} className="imagesize"/> */}
              </Row>
              <hr></hr>
            <Row className="w-100 m-0 justify-content-center">
            <h4 className="manageDropdownActiveText mb-4 text-center">Select image or paste URL for this program</h4>
            <h6 className="manageDropdownActiveText mb-4">Image size should be 480x320 pixels</h6>
              <input
                accept="image/*"
                // className={classes.fileInput}
                id="contained-button-file"
                type="file"
                onChange={handleFile}
          
              />
            <input type="text" className="form-control mt-4 w-50" id="addDisciplineInput" placeholder="http://" value={addImageUrl} onChange={e => handleUrl(e)}></input>
            </Row>
              </Col>
              <Col className="w-50">
              <Row className="w-100 align-items-center justify-content-between px-5">
              <h4 className="manageDropdownActiveText mb-4 text-center">Select start and end dates for this program</h4>
            <h5 className="checkedText m-0">Start Date:</h5>
            <Row className="justify-content-end align-items-center">
              {editStart ? <div className="w-100"><DatePicker selected={startProgramDate} onChange={(date) => handleStartDate(date)} /></div> :  <div className="w-100 d-flex flex-row align-items-center"><h5 className="checkedText pr-3">{new Date(startProgramDate).toLocaleDateString('en-US')}</h5><h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditStart(true)}} /></h4></div> }
          
              {/* {editStart ? <div className="w-50"><DatePicker selected={startProgramDate} onChange={(date) => handleStartDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor ? startDate.toLocaleDateString('en-US') : new Date(sponsorObject.startDate).toLocaleDateString('en-US')}</h5> } */}
            </Row>
          </Row>
          <Row className="w-100 align-items-center justify-content-between px-5">
            <h5 className="checkedText m-0">End Date:</h5>
            <Row className="justify-content-end align-items-center">
              {editEnd ? <div className="w-100"><DatePicker selected={endProgramDate} onChange={(date) => handleEndDate(date)} /></div> : <div className="w-100 d-flex flex-row align-items-center"><h5 className="checkedText pr-3">{new Date(endProgramDate).toLocaleDateString('en-US')}</h5><h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditEnd(true)}} /></h4></div> }
              {/* {editEnd ? <div className="w-50"><DatePicker selected={endProgramDate} onChange={(date) => handleEndDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor ? endDate.toLocaleDateString('en-US') : new Date(sponsorObject.endDate).toLocaleDateString('en-US')}</h5> } */}
          
            </Row>
          </Row>
          <hr></hr>
          <Col className="w-100 justify-content-center">
            <h4 className="manageDropdownActiveText mb-4 text-center">Select disciplines to show this program</h4>
            <h5 className="checkedText text-center">Disciplines</h5>
            <MultiSelect
              className="justify-content-center multi-select w-100"
              options={disciplines}
              value={selected}
              onChange={(values) => {
                setSelected(values);
                setDisciplinesChanged(true);
              }}
              labelledBy="Select Disciplines"
            />
          </Col>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
        <div className="manageDropdownButton px-3 py-2 mt-3 mr-4" onClick={() => {onHide()}}>Cancel</div>
          {imageUrlChanged ? <div className="manageDropdownButton px-4 py-2 mt-3" onClick={() => {commitSave()}}>Save Program</div> : 
            <div className="manageDropdownButtonUnchanged px-4 py-2 mt-3" >Save Program</div>}
        </Modal.Footer>
    </Modal>
  );
}
