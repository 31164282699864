import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebase';

import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { Container, Row } from 'react-bootstrap';
import Table from './Table/index';
import { GrCheckmark } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { BsPencil } from 'react-icons/bs';
import { BsCalendar } from 'react-icons/bs';
import { VscChromeClose } from 'react-icons/vsc';
import { RiCheckboxBlankCircleLine } from 'react-icons/ri';
import MultiSelect from "react-multi-select-component";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "./manageSponsors.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './sponsors.css';
import { captureException } from '../../../utils/utility';

const ManageSponsors = () => {
  // const [table, setTable] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [currentSponsor, setCurrentSponsor] = useState(null)
  const [newSponsors, setNewSponsors] = useState({})
  const [sponsorChanged, setSponsorChanged] = useState(false);
  const [resetSponsors, setResetSponsor] = useState(false);
  // const [url, setUrl] = useState(null);
  

  const convertTime = (timestamp) => {
    let timeInput = timestamp.toString();
    let timeString = timeInput.concat("000")
    return parseInt(timeString)
  }

  const handleEdit = (doc) => {
    // console.log("id", doc.id)
    setCurrentSponsor(doc)
    setOpenEditModal(true)
  }

  const handleDelete = (doc) => {
    // console.log("id", doc.id)
    setCurrentSponsor(doc)
    setOpenDeleteModal(true)
  }


  // Modal for adding image to sponsor beigng edited
  const AddImageModal = (props) => {
    const [addImageUrl, setAddImageUrl] = useState("")
    const [imageUrlChanged, setImageUrlChanged] = useState(false);
    const [image, setImage] = useState(null);
    const defaultImage = "https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/sponsors%2FEE_NO_IMAGE.jpg?alt=media&token=1da92f05-6bd9-4fb8-81dc-48ff7aad93be"
  
    const handleUpload = () => {
      const uploadTask = storage.ref(`sponsors/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        () => {
          storage
            .ref("sponsors")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
              console.log("url", url)
              props.setUrl(url);
            })
            .catch(e => captureException({ message: 'Failed to upload image and get its url', e}));
        }
      );
    }

    const handleUrl = (e) => {
      setAddImageUrl(e.target.value)
      setImageUrlChanged(true)
    }

    const handleFile = (e) => {
      setImageUrlChanged(true)
      setImage(e.target.files[0])
      
      // console.log(e)
    }

    const handleSaveImage = () => {
      if(addImageUrl && !image) {
        props.setUrl(addImageUrl);
        setSponsorChanged(true)
        // setOpenImageModal(false)
      } else if (image && !addImageUrl){
        handleUpload()
        // setSponsorChanged(true)
        // setResetSponsor(true)
        props.onHide()
      }
    }

    return (
      <Modal
        {...props}

        aria-labelledby="edit-sponsors-modal"
        centered
      >
       <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText justify-content-center text-center">
          {currentSponsor ? `Add or Replace Sponsor Image: ${currentSponsor && currentSponsor.data().name}` : `Add a sponsor image`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100 m-0 justify-content-center">
            <Row className="w-75 align-items-center justify-content-between">
              <h5 className="checkedText">Image:</h5>
              <img src={currentSponsor && currentSponsor.data().image ? currentSponsor.data().image : currentSponsor && currentSponsor.data().carouselImage ? currentSponsor.data().carouselImage : defaultImage} alt={`${currentSponsor && currentSponsor.data().name} Sponsor Ad`} className="imagesize"/>
            </Row>
          </Row>
          <hr></hr>
          <Row className="w-100 m-0 justify-content-center">
          <h4 className="manageDropdownActiveText mb-4 text-center">Select image or paste URL for this this sponsor</h4>
          {props.featured ?
            <h6 className="manageDropdownActiveText mb-4">Image size should be 428x200 pixels</h6> :
            <h6 className="manageDropdownActiveText mb-4">Image size should be 480x320 pixels</h6>
          }
            <input
              accept="image/*"
              // className={classes.fileInput}
              id="contained-button-file"
              type="file"
              onChange={handleFile}
              
            />
          <input type="text" className="form-control mt-4 w-50" id="addDisciplineInput" placeholder="http://" value={addImageUrl} onChange={e => handleUrl(e)}></input>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {imageUrlChanged ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleSaveImage()}}>Save</div> :
            <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3">Save</div>}
        </Modal.Footer>
      </Modal>
    )
  }

  const DeleteSponsorModal = (props) => {
    const [deleteConfirm, setDeleteConfirm] = useState("")

    const sponsorRef = db.collection('sponsors');

    const handleDeleteSponsor = () => {
      const sponsorID = currentSponsor.id;
      sponsorRef.doc(sponsorID).delete().then(() => {
        console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
      setResetSponsor(!resetSponsors)
      setOpenDeleteModal(false)
    }

    return (
      <Modal
        {...props}
        size="med"
        aria-labelledby="edit-sponsors-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
          {currentSponsor && `Delete this sponsor: ${currentSponsor.data().name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
        <Row className="w-75 align-items-center justify-content-between">
          <h5 className="text-center text-danger">Warning: This will permanently delete this sponsor and cannot be undone.</h5>
          <h6 className="text-center mt-3 mb-0">Type "DELETE" into the input below to confirm</h6>
        </Row>
          <hr></hr>
        <Row className="w-100 align-items-center justify-content-center">

          <input type="text" className="form-control mt-1 w-50" id="deleteConfrim" placeholder="Type 'DELETE' here" value={deleteConfirm} onChange={e => setDeleteConfirm(e.target.value)} /> 
        
        </Row>

        </Modal.Body>
        <Modal.Footer>
          {deleteConfirm === "DELETE" ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleDeleteSponsor()}}>Delete</div> :
            <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3" >Delete</div>}
        </Modal.Footer>
      </Modal>
    )
  }
  
  //Modal for editing sponsors
  const EditSponsorModal = (props) => {
    const [sponsorObject, setSponsorObject] = useState({
      name: "",
      image: "",
      carouselImage: "",
      disciplines: [],
      url: "",
      startDate: "",
      endDate: "",
      featuredStartDate: "",
      featuredEndDate: "",
    })
    const [url, setUrl] = useState(null);
    const [sponsorEditChanged, setSponsorEditChanged] = useState(false);
    const [featured, setFeatured] = useState(true);
    const [sponsorName, setSponsorName] = useState(currentSponsor && currentSponsor.data().name)
    const [sponsorUrl, setSponsorUrl] = useState(currentSponsor ? currentSponsor.data().url : "http://")
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [featuredStartDate, setFeaturedStartDate] = useState("");
    const [featuredEndDate, setFeaturedEndDate] = useState("");
    const [editName, setEditName] = useState(false);
    const [editUrl, setEditUrl] = useState(false);
    const [editStart, setEditStart] = useState(false)
    const [editEnd, setEditEnd] = useState(false)
    const [editFeatureStart, setEditFeatureStart] = useState(false)
    const [editFeatureEnd, setEditFeatureEnd] = useState(false)
    const [disciplines, setDisciplines] = useState([]);
    const [disciplinesChanged, setDisciplinesChanged] = useState(false);
    const [selected, setSelected] = useState([]);
    const [openImageModal, setOpenImageModal] = useState(false)


    const sponsorRef = db.collection('sponsors');

    const updateSponsorsToDB = (id, obj) =>{
      console.log(obj)
      sponsorRef.doc(id).update(obj)
    }
    const addSponsorsToDB = (obj) =>{
      sponsorRef.add(obj)
    }

    const handleSponsorChange = () => {
      setSponsorChanged(true)
    }
  
    const handleSponsorName = (name) => {
      setSponsorName(name.target.value)
      setSponsorEditChanged(true)
    }
    const handleURLChange = (url) => {
      setSponsorUrl(url.target.value)
      setSponsorEditChanged(true)
    }
    const handleStartDate = (date) => {
      setStartDate(new Date(date))
      setSponsorObject(prev => ({
        ...prev,
        startDate: new Date(date)
      }))
      setEditStart(false)
      setSponsorEditChanged(true)
    }
    const handleEndDate = (date) => {
      setEndDate(new Date(date))
      setSponsorObject(prev => ({
        ...prev,
        endDate: new Date(date)
      }))
      setEditEnd(false)
      setSponsorEditChanged(true)
    }
    const handleFeatureStartDate = (date) => {
      setFeaturedStartDate(new Date(date))
      setSponsorObject(prev => ({
        ...prev,
        featuredStartDate: new Date(date)
      }))
      setEditFeatureStart(false)
      setSponsorEditChanged(true)
    }
    const handleFeatureEndDate = (date) => {
      setFeaturedEndDate(new Date(date))
      setSponsorObject(prev => ({
        ...prev,
        featuredEndDate: new Date(date)
      }))
      setEditFeatureEnd(false)
      setSponsorEditChanged(true)
    }

    const handleSaveSponsor = () => {
      // console.log(sponsorObject)
      let newSponsorInfo = {};
      let sponsorId;
      if(currentSponsor){
        newSponsorInfo = {
          name: sponsorName,
          image: url ? url : currentSponsor.data().image ? currentSponsor.data().image : "",
          carouselImage: url ? url : currentSponsor.data().carouselImage ? currentSponsor.data().carouselImage : "",
          disciplines: selected ? selected.map(item => item.value) : currentSponsor.data().disciplines,
          url: sponsorUrl,
          "start-date": startDate ? startDate : currentSponsor.data()["start-date"],
          "end-date": endDate ? endDate : currentSponsor.data()["end-date"],
          "featured-start-date": featuredStartDate ? featuredStartDate : currentSponsor.data()["featured-start-date"],
          "featured-end-date": featuredEndDate ? featuredEndDate : currentSponsor.data()["featured-end-date"],
        }
        sponsorId = currentSponsor.id
      } else if(sponsorName && sponsorUrl.length > 7) {
        newSponsorInfo = {
          name: sponsorName,
          // image: url ? url : currentSponsor ? currentSponsor.data().image : sponsorObject.image,
          url: sponsorUrl,
          disciplines: [],
          "start-date": "",
          "end-date": "",
          "featured-start-date": "",
          "featured-end-date": "",
        }
        if (featured) {
          newSponsorInfo.image = url ? url : currentSponsor ? currentSponsor.data().image : sponsorObject.image;
          newSponsorInfo["featured-start-date"] = featuredStartDate ? featuredStartDate : new Date(sponsorObject.featuredStartDate);
          newSponsorInfo["featured-end-date"] = featuredEndDate ? featuredEndDate : new Date(sponsorObject.featuredEndDate);
        } else {
          newSponsorInfo.carouselImage = url ? url : currentSponsor ? currentSponsor.data().image : sponsorObject.image
          newSponsorInfo["start-date"] = featuredStartDate ? featuredStartDate : new Date(sponsorObject.featuredStartDate);
          newSponsorInfo["end-date"] = featuredEndDate ? featuredEndDate : new Date(sponsorObject.featuredEndDate);
          newSponsorInfo.disciplines = selected ? selected.map(item => item.value) : [];
        }
      } else {
        alert("Please enter a sponsor name and url")
        return
      }
      setNewSponsors(prev => ({
        ...prev,
        [sponsorId]: newSponsorInfo
      }))
      currentSponsor ? updateSponsorsToDB(currentSponsor.id, newSponsorInfo) : addSponsorsToDB(newSponsorInfo);
      setResetSponsor(!resetSponsors)
      setUrl(null)
      setOpenEditModal(false)
    }

    const addOption = (item) => {
      return {
        label:item, 
        value:item,
      }
    }

    useEffect(() => {
      if(currentSponsor) {
        // console.log("choice 1")
        if (currentSponsor.data().disciplines) setSelected(currentSponsor.data().disciplines.map(item => addOption(item)))
        if (currentSponsor.data().carouselImage) setFeatured(false)
        setSponsorObject(
          {
            name: sponsorName,
            image: currentSponsor.data().image,
            carouselImage: currentSponsor.data().carouselImage,
            disciplines: currentSponsor.data().disciplines ? currentSponsor.data().disciplines : [],
            url: sponsorUrl,
            startDate: currentSponsor.data()["start-date"] ? new Date(currentSponsor.data()["start-date"].seconds*1000) : null,
            endDate: currentSponsor.data()["end-date"] ? new Date(currentSponsor.data()["end-date"].seconds*1000) : null,
            featuredStartDate: currentSponsor.data()["featured-start-date"] ? new Date(currentSponsor.data()["featured-start-date"].seconds*1000) : null,
            featuredEndDate: currentSponsor.data()["featured-end-date"] ? new Date(currentSponsor.data()["featured-end-date"].seconds*1000) : null,
          }
        )
      } else {
        // console.log("choice 2")
        setSponsorObject(
          {
            name: "",
            image: "https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/sponsors%2FEE_NO_IMAGE.jpg?alt=media&token=1da92f05-6bd9-4fb8-81dc-48ff7aad93be",
            carouselImage: "https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/sponsors%2FEE_NO_IMAGE.jpg?alt=media&token=1da92f05-6bd9-4fb8-81dc-48ff7aad93be",
            url: "",
            startDate: Date.parse(new Date()),
            endDate: Date.parse(new Date()) + (535680*10000),
            featuredStartDate: Date.parse(new Date()),
            featuredEndDate: Date.parse(new Date()) + (535680*10000),
            disciplines: [],
          }
        )
      }

    },[currentSponsor])

    useEffect(() => {

      const dropdownListRef = db.collection('my-horse-listing-dropdowns').doc('jXE8l6PiewX1VCfDoaKB');
      dropdownListRef.get()
        .then(data => setDisciplines(data.get('disciplines').map(item => addOption(item.text))))
        .catch(error => console.log(error));
  
  }, [disciplines])

    // console.log(newSponsors)

    return (
      <Modal
        {...props}
        size="med"
        aria-labelledby="edit-sponsors-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
          {currentSponsor ? `Edit this sponsor: ${sponsorObject.name}` : `Create new sponsor` }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
        {!currentSponsor &&
          <>
            <div className="w-50 mb-3 d-flex justify-content-between">
            {featured ? <div className="manageSponsorsButton px-3 py-2 mt-3">Featured</div> :
              <div className="manageSponsorsButtonUnchanged px-3 py-2 mt-3" onClick={() => {setFeatured(true)}}>Featured</div>
            }
            {featured ? <div className="manageSponsorsButtonUnchanged px-3 py-2 mt-3" onClick={() => {setFeatured(false)}}>Carousel</div> :
              <div className="manageSponsorsButton px-3 py-2 mt-3">Carousel</div>
            }
            </div>
            {featured ? <p className="manageSponsorsActiveText">This sponsor will appear on the loading screen</p> :
              <p className="manageSponsorsActiveText">This sponsor will appear in the program carousels</p>
            }
          </>
        }

        <Row className="w-75 align-items-center justify-content-between">
          <h5 className="checkedText">Image:</h5>
          <img src={url ? url : sponsorObject.carouselImage ? sponsorObject.carouselImage : sponsorObject.image} alt={`${sponsorObject.name} Sponsor Ad`} className="imagesize"/>
          <h4 className="checkedText"><BsPencil className='pencil isClickable' onClick={() => {setOpenImageModal(true)}} /></h4>
        </Row>
          <hr></hr>
        <Row className="w-75 align-items-center justify-content-between">
          <h5 className="checkedText">Name:</h5>
          {editName ? <input type="text" className="form-control mt-1 w-50" id="addSponsorName" value={sponsorName} onChange={e => handleSponsorName(e)}></input> : <h4 className="checkedText">{sponsorName}</h4> }
          {editName ? <h4 className="checkedText"><FaCheck className='pencil isClickable' onClick={() => {setEditName(false)}} /></h4> : <h4 className="checkedText"><BsPencil className='pencil isClickable' onClick={() => {setEditName(true)}} /></h4> }
        </Row>
          <hr></hr>
        <Row className="w-75 align-items-center justify-content-start">
          <h5 className="checkedText m-0">Link Url:</h5>
        </Row>
        <Row className="w-75 align-items-center justify-content-between">
          {editUrl ? <input type="text" className="form-control mt-1 w-75" placeholder="http://" id="addSponsorUrl" value={sponsorUrl} onChange={e => handleURLChange(e)}></input> : <h6 className="checkedText w-75 overflow-hidden">{sponsorUrl}</h6> }
          {editUrl ? <h4 className="checkedText"><FaCheck className='pencil isClickable' onClick={() => {setEditUrl(false)}} /></h4> : <h4 className="checkedText"><BsPencil className='pencil isClickable' onClick={() => {setEditUrl(true)}} /></h4> }
        </Row>
          <hr></hr>
          
        {!featured &&
          <>
          <Col className="w-100 justify-content-center">
            <h4 className="manageDropdownActiveText mb-4 text-center">Select disciplines to show this sponsor</h4>
            <h5 className="checkedText text-center">Disciplines</h5>
            <MultiSelect
              className="justify-content-center multi-select w-100"
              options={disciplines}
              value={selected}
              onChange={(values) => {
                setSelected(values);
                setSponsorEditChanged(true);
              }}
              labelledBy="Select Disciplines"
            />
          </Col>
          <hr></hr>
            <Row className="w-75 align-items-center justify-content-between">
              <h5 className="checkedText m-0">Carousel Start Date:</h5>
              <Row className="justify-content-end align-items-center w-50 mr-0">
                {editStart ? <div className="w-50"><DatePicker selected={sponsorObject.startDate} onChange={(date) => handleStartDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor && startDate ? startDate.toLocaleDateString('en-US') : new Date(sponsorObject.startDate).toLocaleDateString('en-US')}</h5> }
                <h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditStart(true)}} /></h4>
              </Row>
            </Row>
            <Row className="w-75 align-items-center justify-content-between">
              <h5 className="checkedText m-0">Carousel End Date:</h5>
              <Row className="justify-content-end align-items-center w-50 mr-0">
                {editEnd ? <div className="w-50"><DatePicker selected={sponsorObject.endDate} onChange={(date) => handleEndDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor && endDate ? endDate.toLocaleDateString('en-US') : new Date(sponsorObject.endDate).toLocaleDateString('en-US')}</h5> }
                <h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditEnd(true)}} /></h4>
              </Row>
            </Row>
          </>
        }
        {featured &&
          <>
            <Row className="w-75 align-items-center justify-content-between">
            <h5 className="checkedText m-0">Featured Start Date:</h5>
            <Row className="justify-content-end align-items-center w-50 mr-0">
              {editFeatureStart ? <div className="w-50"><DatePicker selected={sponsorObject.featuredStartDate} onChange={(date) => handleFeatureStartDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor && featuredStartDate ? featuredStartDate.toLocaleDateString('en-US') :  new Date(sponsorObject.featuredStartDate).toLocaleDateString('en-US')}</h5> }
              <h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditFeatureStart(true)}} /></h4>
              </Row>
            </Row>
            <Row className="w-75 align-items-center justify-content-between">
            <h5 className="checkedText m-0">Featured End Date:</h5>
            <Row className="justify-content-end align-items-center w-50 mr-0">
              {editFeatureEnd ? <div className="w-50"><DatePicker selected={sponsorObject.featuredEndDate} onChange={(date) => handleFeatureEndDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor && featuredEndDate? featuredEndDate.toLocaleDateString('en-US') : new Date(sponsorObject.featuredEndDate).toLocaleDateString('en-US')}</h5> }
              <h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditFeatureEnd(true)}} /></h4>
              </Row>
            </Row>
          </>
        }

        <Row>
        
        </Row>
        </Modal.Body>
        <Modal.Footer>
          {sponsorEditChanged || sponsorChanged ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleSaveSponsor()}}>Save</div> :
            <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3" >Save</div>}
        </Modal.Footer>
        <AddImageModal
          show={openImageModal}
          onHide={() => setOpenImageModal(false)}
          featured={featured}
          url={url}
          setUrl={setUrl}
        />
      </Modal>
    )
  }

  return (
    <Container id="manageSponsorBody justify-content-start">
      <Row className="mb-5 justify-content-between align-items-center">
        <h3 className="manageSponsorsActiveText manageSponsorsPageTitle">Sponsors</h3>
        <div className="manageDropdownButton px-5 py-2 h-25" onClick={() => handleEdit(null)} >ADD SPONSOR</div>
      </Row>
      <Row className='content d-flex align-items-center m-0'>
        <Table handleEdit={handleEdit} handleDelete={handleDelete} convertTime={convertTime} resetSponsors={resetSponsors} />
      </Row>
      <EditSponsorModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
      />
      <DeleteSponsorModal
        show={openDeleteModal}
        onHide={() => setOpenDeleteModal(false)}
      />
    </Container>
    
  );
};

export default ManageSponsors;
