import React, {useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { db } from '../../../../firebase';
import { captureException, currencyFormat } from '../../../../utils/utility';

const Table = ({ table, setTable}) => {
 

  let tableColumns = {
    columns: [
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Sender',
        field: 'senderEmail',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Recipient',
        field: 'recipientEmail',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Amount',
        field: 'amount',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'STATUS',
        field: 'status',
        sort: 'asc',
        width: 50,
      },
    ],
  };
  //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //filter array and then set table state
  let tableRows = {
    rows: [{}],
  };

  const getTransactions = async () => {
    try{
      const transRef = db.collection('payment-transactions');
      const snapshot = await transRef.get();
      snapshot.forEach((doc) => {
          console.log('data', doc.data());
        const transaction = {
          date: doc.data().createdAt ? doc.data().createdAt.toDate().toDateString() : '',
          senderEmail: doc.data().senderEmail ? doc.data().senderEmail : '',
          recipientEmail: doc.data().recipientEmail ? doc.data().recipientEmail : '',
          status: doc.data()?.status ? doc.data()?.status : 'no info',
          uid: doc.data().uid,
          amount: doc.data().amount ? currencyFormat(doc.data().amount) : 0
        };
        tableRows.rows.push(transaction);
      });
      //table rows var will have array of current objects with current uid if needed to match for deletion or edit
      //filter array and then set table state
      setTable({ ...tableColumns, ...tableRows });

    } catch (e) {
      captureException({message: 'Failed to get transactions data',e});
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);
  console.log(table);

  return (
    <>
      <MDBDataTable
        entries={8}
        noBottomColumns
        fixed
        displayEntries={false}
        searching={false}
        className='col-xl-12'
        data={table}
      />
    </>
  );
};

export default Table;
