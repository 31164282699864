import React from 'react';
import './dashboard.css';
import { Route } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import SideNav from '../../components/SideNav';
import ResearchTicket from './ResearchTicket/index';
import ManageSponsors from './ManageSponsors/index';
import ManageFinances from './MangeFinances/index';
import ManageDropDown from './ManageDropDown/index';
import UserCredentials from './UserCredentials/index';
import ManageListings from './ManageListings/index';
import ManageEvents from './ManageEvents/index';
import ManageCalendar from './ManageCalendar/index';
import ManagePricing from './ManagePricing/index';
import ManageUsers from './ManageUsers/index';
import ManagePrograms from './MangePrograms/index';
import ManageCoaches from './ManageCoaches/index';
import ManageStudents from './ManageStudents/index';
import ManageOnboarding from './ManageOnboarding/index';
import ManageTransactions from './ManageTransactions/index';
import Overview from './Overview/index';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers'

// import MomentUtils from '@date-io/moment';

const Dashboard = () => {
  return (
    <>
      <Container fluid>
        <div className='dashboardContainer'>
          <Row>
            <Col xs={2} id='sidebar-container'>
              <SideNav />
            </Col>
            <Col xs={10} id='page-container'>
              <Route exact path='/dashboard/' component={Overview} />
              <Route path='/dashboard/research' component={ResearchTicket} />
              <Route path='/dashboard/sponsors' component={ManageSponsors} />
              <Route path='/dashboard/finances' component={ManageFinances} />
              <Route path='/dashboard/dropdowns' component={ManageDropDown} />
              <Route
                path='/dashboard/usercredential'
                component={UserCredentials}
              />
              <Route path='/dashboard/listings' component={ManageListings} />
              <Route path='/dashboard/events' component={ManageEvents} />
              <Route path='/dashboard/calendar' component={ManageCalendar} />
              <Route path='/dashboard/pricing' component={ManagePricing} />
              <Route path='/dashboard/users' component={ManageUsers} />
              <Route path='/dashboard/programs' component={ManagePrograms} />
              <Route path='/dashboard/coaches' component={ManageCoaches} />
              <Route path='/dashboard/students' component={ManageStudents} />
              <Route path='/dashboard/onboard' component={ManageOnboarding} />
              <Route path='/dashboard/transactions' component={ManageTransactions} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
