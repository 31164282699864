import currencyFormatter from 'currency-formatter';
import * as Sentry from "@sentry/react";

export const currencyFormat = value => {
  return currencyFormatter.format(value, { code: 'USD' });
};

export const currencyToNumber = value => {
  return currencyFormatter.unformat(value, { code: 'USD' });
};

export const captureException = ({ message, e}) => {
  Sentry.withScope( scope => {
    if(message)
      scope.setExtra('message', message);
    Sentry.captureException(e);
  })
}