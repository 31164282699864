import * as React from 'react';

export default function SvgSponsors(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400 400'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <path
        id='prefix__Path_1167'
        data-name='Path 1167'
        className='prefix__cls-1'
        d='M199.94 345.58c-82.27.54-149.4-66.39-149.94-149.5-.49-76.88 56.46-141.78 132.08-150.49a5.13 5.13 0 011.31 10.13C107.24 65 53 134.9 62.17 211.82s78.38 131.74 154.53 122.45 130.41-79.18 121.22-156.1C330.25 114 280.2 63.47 216.7 55.72a5.13 5.13 0 011.2-10.13C299.65 55 358.35 129.58 349 212.16c-8.63 76.39-72.88 133.92-149 133.42'
      />
      <path
        className='prefix__cls-1'
        d='M153.3 263.46l8.91-52-37.8-36.83 52.22-7.63L200 119.71 223.37 167l52.22 7.59-37.8 36.83 8.91 52L200 238.9zM200 231l37.41 19.66-7.14-41.66 30.28-29.5-41.83-6.09-18.72-37.9-18.72 37.9-41.83 6.09 30.28 29.5-7.14 41.66z'
        id='prefix__Path_458'
        data-name='Path 458'
      />
    </svg>
  );
}
