
import { COLOR_GREENISH } from '../../utils/constants';


export default {
    header: {
        position: 'sticky',
        top: 0,
        background: 'black',
        zIndex: 100,
    },

    navItem: {
        color: COLOR_GREENISH,
        


    }

}


