import React, { useState } from 'react';
import './popular-events.css';
import { Container, Row, Form,Button, NavDropdown } from 'react-bootstrap';
import Table from './Table/index';
import { MDBModal, MDBModalBody} from 'mdbreact';
import { db } from '../../../../firebase';
import { confirmAlert } from 'react-confirm-alert';
import { captureException } from '../../../../utils/utility';
//import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// require('dotenv').config();
const  PopularEvents = () => {
  const [isMoldelOpen, setIsModelOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [name, setName] = useState('');
  const [producer, setProducer] = useState('');
  const [city, setCity] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [time, setTime] = useState('');
  const [url, setUrl] = useState('');
  const [refresh, setRefresh] = useState('');

  const onEditEvent = (event) => {
    // console.log('event', isMoldelOpen);
    setIsModelOpen(!isMoldelOpen);
    setName(event.data().name);
    setProducer(event.data().producer);
    setCity(event.data().city);
    setDescription(event.data().description);
    setLocation(event.data().location);
    setStartDate(event.data().startDate);
    setEndDate(event.data().endDate);
    setTime(event.data().time);
    setUrl(event.data().url ? event.data().url : '');
    setSelectedRowData(event);
  }

  const onSave = async() => {
    // console.log('time', time);
    if(name.length < 1 || name === '') {
      alert('Name is required');
      return;
    }
    if(startDate.length < 1 || startDate === '') {
      alert('Start date is required');
      return;
    }
    if(endDate.length < 1 || setEndDate === '') {
      alert('End date is required');
      return;
    }
    setIsModelOpen(!isMoldelOpen);
    try{
      const docRef = db.collection('admin-events')
      if(selectedRowData) {
        docRef
        .doc(selectedRowData.id)
        .update({
          name: name,
          city: city,
          producer: producer,
          location: location,
          description: description,
          startDate: startDate,
          endDate: endDate,
          time: time,
          url: url,
        });

      } else {
        docRef
        .add({
          name: name,
          city: city,
          producer: producer,
          location: location,
          description: description,
          startDate: startDate,
          endDate: endDate,
          time: time,
          url: url,
        });
      }
      setRefresh(new Date());
    } catch(error) {
      console.log(error);
      captureException({ message: 'Failed to save competion', e: error});
    }
  }

  const onDeleteEvent = (eventUid) => {
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure, you want to delete this event',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onConfirmed(eventUid),
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    });
  }

  const onConfirmed = async(eventUid) => {

    try{
      await db.collection('admin-events').doc(eventUid).delete();
      setRefresh(new Date());
    } catch (error) {
      console.log(error);
      captureException({ message: 'Failed to delete competion', e: error});
    }
  }

  const onAddCompetition = () => {
    setIsModelOpen(!isMoldelOpen);
    setName('');
    setProducer('');
    setCity('');
    setDescription('');
    setLocation('');
    setStartDate('');
    setEndDate('');
    setTime('');
    setUrl('');
    setSelectedRowData(null);
  }
 /*
  const checkDateInput = () => {
    var input = document.createElement('input');
    input.setAttribute('type','datetime-local');

    var notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue); 

    return (input.value !== notADateValue);
}
*/

  return (
    <Container className='calendar-page-container' className='col-12'>
      <div className="add-button">
        <Button
          onClick={onAddCompetition}
        >Add a Competition</Button>
      </div>
      <Row className='calendar-content'>
        <Table
        className='col-xl-12'
        onEditRow={onEditEvent}
        onDeleteRow={onDeleteEvent}
        refresh={refresh}
      />
      </Row>
      <MDBModal isOpen={isMoldelOpen} toggle={() => setIsModelOpen(!isMoldelOpen)}>
        <MDBModalBody>
          <h1>{selectedRowData !== null ? 'Edit Competition' : 'Add Competition'}</h1>
          <div className="modal-body">
          <Form>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Event Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Event Name"
                    defaultValue={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Form.Group>
                {/*
                <Form.Group className="mb-3" controlId="producer">
                  <Form.Label>Producer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Producer"
                    defaultValue={producer}
                    onChange={(event) => setProducer(event.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    defaultValue={city}
                    onChange={(event) => setCity(event.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    defaultValue={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </Form.Group>
                
                
                <Form.Group className="mb-3" controlId="location">
                  <Form.Label>Location</Form.Label>
                  <div>
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                      selectProps={{
                        location,
                        onChange: (loc) => setLocation(loc.label),
                        defaultInputValue: location
                      }}
                      apiOptions={{ language: 'en', region: 'us' }}
                      autocompletionRequest={{
                        types: ['(cities)'],
                        componentRestrictions: {
                          country:['us']
                        }}}
                    />
                  </div>
                </Form.Group>
                */
                }
                <Form.Group controlId="startDate">
                    <Form.Label>Select Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Start Date"
                      defaultValue={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="End Date"
                      defaultValue={endDate}
                      onChange={(event) => setEndDate(event.target.value)}
                    />
                </Form.Group>
                {/*
                <Form.Group controlId="time">
                    <Form.Label>Time</Form.Label>
                    <Form.Control
                      type="time"
                      placeholder="Time"
                      defaultValue={time}
                      onChange={(event) => setTime(event.target.value)}
                    />
                </Form.Group>
                */}
                <Form.Group className="mb-3" controlId="url">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="URL"
                    defaultValue={url}
                    onChange={(event) => setUrl(event.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="button"
                  onClick={onSave}
                >
                  Save
                </Button>
              </Form> 
            </div>
        </MDBModalBody>
      </MDBModal>
    </Container>
  );
}

export default  PopularEvents
