import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { IoMdArrowBack } from 'react-icons/io';
import { db, auth} from '../../../../firebase';
import { captureException } from '../../../../utils/utility';

const OnboardStepOne = (props) => {

  const { closeStepOne, coachObject, setCoachObject, handleNextStep, handleBackStep, stepTwo } = props

  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="onboard-step-one-modal"
      centered
    >
      <Modal.Header closeButton className="align-items-center py-2">
        {/* <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
        Coaching Onboarding
        </Modal.Title> */}
        <IoMdArrowBack className='h4 text-secondary mb-0 mr-3 isClickable' onClick={() => handleBackStep()} />
        <h4 className="text-center pb-1">Coaching Onboarding</h4>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
      <Row className="w-75 align-items-center justify-content-center">
          <hr className="w-25" /><h6 className="text-primary">STEP ONE</h6><hr className="w-25" />
      </Row>
      <Row className="w-75 align-items-center justify-content-center">
        <input type="text" className="form-control text-center mb-2 w-100" id="addSponsorName" placeholder="First Name" value={coachObject.firstName} onChange={e => setCoachObject(prev => ({...prev, firstName: e.target.value}))}></input>
        <input type="text" className="form-control text-center mb-2 w-100" id="addSponsorName" placeholder="Last Name" value={coachObject.lastName} onChange={e => setCoachObject(prev => ({...prev, lastName: e.target.value}))}></input>
        <input type="text" className="form-control text-center mb-2 w-100" id="addSponsorName" placeholder="Phone Number" value={coachObject.phoneNumber} onChange={e => setCoachObject(prev => ({...prev, phoneNumber: e.target.value}))}></input>
        <input type="text" className="form-control text-center mb-2 w-100" id="addSponsorName" placeholder="Street Address" value={coachObject.address} onChange={e => setCoachObject(prev => ({...prev, address: e.target.value}))}></input>
        <input type="text" className="form-control text-center mb-2 w-100" id="addSponsorName" placeholder="City" value={coachObject.city} onChange={e => setCoachObject(prev => ({...prev, city: e.target.value}))}></input>
        <input type="text" className="form-control text-center mb-2 w-100" id="addSponsorName" placeholder="State" value={coachObject.state} onChange={e => setCoachObject(prev => ({...prev, state: e.target.value}))}></input>
        <input type="text" className="form-control text-center mb-2 w-100" id="addSponsorName" placeholder="Zip Code" value={coachObject.zipCode} onChange={e => setCoachObject(prev => ({...prev, zipCode: e.target.value}))}></input>
      </Row>
        <hr></hr>
      <Row className="w-100 align-items-center justify-content-center">

      
      </Row>

        {stepTwo ? <div className="manageDropdownButton px-3 py-2 my-3" onClick={() => {handleNextStep()}}>Continue to Next Step</div> :
          <div className="manageDropdownButtonUnchanged px-3 py-2 my-3" >Continue to Next Step</div>}
        <h6>© EQUITRADE 2021</h6>
      </Modal.Body>
    </Modal>
  )
}

export default OnboardStepOne;