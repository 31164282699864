import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import { FaCheck } from 'react-icons/fa';
import { BsPencil } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { db, auth } from '../../../../firebase';
import { captureException } from '../../../../utils/utility';

const generatePassword = require("password-generator");

export default function CreateModal(props) {
  const { show, onHide, currentUser, setCurrentUser, getUsers, setDeleteListingModalShow, userObject, setUserObject, defaultUserObject, setCreateProgramModalShow, tempPass, setTempPass } = props

  const [userChanged, setUserChanged] = useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [showAdminModal, setShowAdminModal] = useState(false);


  const [editToggle, setEditToggle] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    // homeTown: false,
    role: false,
    status: false,
    programUser: false,
    // promoCode: false,

  });

  const createPassword = () => {
    var maxLength = 10;
    var minLength = 8;
    var uppercaseMinCount = 2;
    var lowercaseMinCount = 2;
    var numberMinCount = 2;
    var specialMinCount = 1;
    var UPPERCASE_RE = /([A-Z])/g;
    var LOWERCASE_RE = /([a-z])/g;
    var NUMBER_RE = /([\d])/g;
    var SPECIAL_CHAR_RE = /([\?\-])/g;
    var NON_REPEATING_CHAR_RE = /([\w\d\?\-])\1{2,}/g;
  
    function isStrongEnough(password) {
      var uc = password.match(UPPERCASE_RE);
      var lc = password.match(LOWERCASE_RE);
      var n = password.match(NUMBER_RE);
      var sc = password.match(SPECIAL_CHAR_RE);
      var nr = password.match(NON_REPEATING_CHAR_RE);
      return password.length >= minLength &&
        !nr &&
        uc && uc.length >= uppercaseMinCount &&
        lc && lc.length >= lowercaseMinCount &&
        n && n.length >= numberMinCount &&
        sc && sc.length >= specialMinCount;
    }
  
    var password = "";
    var randomLength = Math.floor(Math.random() * (maxLength - minLength)) + minLength;
    while (!isStrongEnough(password)) {
      password = generatePassword(randomLength, false, /[\w\d\?\-]/);
    }
    setUserObject(prev => ({...prev, temporaryPassword: password}))
    setTempPass(password)
  }

  const checkFields = () => {
    if(userFirstName &&
      userLastName &&
      userObject.email &&
      userObject.phoneNumber &&
      // userObject.homeTown &&
      userObject.role &&
      userObject.status) {
        setUserChanged(true)
      }
  }

  
  const handleForeverFree = () => {
    // setEditForeverFree(!editForeverFree)
    setUserObject(prev => ({...prev, foreverFree: !userObject.foreverFree}))
  }


  useEffect(() => {checkFields();}, [userObject])

  const handleRoleSelect = (role) => {
    let newRole;
    switch (role) {
      case "user" :
        newRole = "User"
        break;
      case ("programUser") :
        newRole = "Program"
        break;
      case "admin" :
        newRole = "Admin"
        break;
      default :
        newRole = "Invalid Role"
    }
    setUserObject(prev => ({...prev, role: newRole}))
  }
  
  const handleStatusSelect = (status) => {
    let newStatus;
    switch (status) {
      case "active" :
        newStatus = "Active"
        break;
      case "suspended" :
        newStatus = "Suspended"
        break;
      default :
        newStatus = "Invalid Status"
    }
    setUserObject(prev => ({...prev, status: newStatus}))
  }

  //firebase update for user
  const userRef = db.collection('users');

  const addUserToDB = async (id) => {
    try{
      await userRef.doc(id).update(userObject)
    } catch (e) {
      captureException({message: 'Failed to update user data', e})
    }
  }

  const signup = async (email, password) => {

    return auth.createUserWithEmailAndPassword(email, password).then(cred => {
        // setUserObject(prev => ({...prev, uid: cred.user.uid}));
        let uid = cred.user.uid;
        db.collection('users').doc(cred.user.uid).set({
            id: uid,
            // theme: 'light',
            newUser: true,
            role: 'User'
        }).then(console.log("User Created"))
        .then(addUserToDB(uid))
        .then(userRef.doc(uid).update({uid: uid}))
        .then(getUsers())
        .catch(err => captureException({message: 'Failed to create new admin user', e: err}));
    })
  }

  //function to save user to db and clean up
  const commitSave = () => {
    signup(userObject.email, tempPass)
    // addUserToDB()
    // getUsers()
    setUserChanged(false)
    setCurrentUser(null);
    setUserObject(defaultUserObject)
    onHide(false)
  }


  //Modal to confirm changing user to admin
  const AdminModal = (props) => {
    const handleAdminSave = () => {
      setShowAdminModal(false);
      commitSave();
    }

    return (
      <Modal  {...props} aria-labelledby='contained-modal-title-vcenter' size="sm">
        <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
            Confirm Admin Access
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Setting user roll as "Admin" will grant that user access to the admin portal and settings. Click confirm to save or cancel to go back.
        </Modal.Body>
        <Modal.Footer>
          <div className="px-3 py-2 mt-3 isClickable" onClick={() => {setShowAdminModal(false)}}>Cancel</div>
          <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleAdminSave()}}>Confirm</div>
        </Modal.Footer>
      </Modal>
    )
  }
  

  //Logic for saving edited user info
  const handleNewUserSave = () => {
    if(userObject.status === "Suspended") {
      onHide(false)
      setDeleteListingModalShow(true)
      // console.log("delete listings")
      setUserChanged(false)
    } else if (userObject.status === "Active" && userObject.role === "Admin") {
      setShowAdminModal(true)
    } else if (userObject.status === "Active" && userObject.role === "Program") {
      setCreateProgramModalShow(true)
    } else if(!userObject.status) {
      alert("Please set user status to continue.")
    } else {
      // console.log(userObject)
      commitSave()
    }
  }

  const updateField = (e, field) => {
    setUserObject(prev => ({...prev, [field]: e.target.value}));
  }

  const updateUserName = (e, field) => {
    field === "firstName" ? setUserFirstName(e.target.value) : setUserLastName(e.target.value)
  }

  const mergeAndSaveNames = () => {
    // console.log("merge and save")
    setUserObject(prev => ({...prev, name: userFirstName + " " + userLastName}));
  }

  const handleNameBlur = (field) => {
    // console.log("onBlur")
    setEditToggle(prev => ({...prev, [field]: false}))
    mergeAndSaveNames();
  }
  
  const removePhoto = () => {
    setUserObject(prev => ({...prev, photoUrl: ""}))
  }

  useEffect(() => {
    if(show) {
      setUserChanged(false)
      setUserObject(defaultUserObject)
      createPassword()
    }
  }, [show])

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' size="lg">
      <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
            Create new User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-start">
        <Row className="w-100">
          <Col className="col-7 pl-4">
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText">First Name:</h5>
                {editToggle.firstName ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, firstName: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, firstName: true}))} /></h5> }
              </div>
              {editToggle.firstName ? <input type="text" className="form-control mt-1 w-50" id="addSponsorName" value={userFirstName} onChange={e => updateUserName(e, "firstName")} onBlur={() => handleNameBlur("firstName")}></input> : <h4 className="checkedText">{userFirstName}</h4> }
            </Row>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText">Last Name:</h5>
                {editToggle.lastName ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, lastName: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, lastName: true}))} /></h5> }
              </div>
              {editToggle.lastName ? <input type="text" className="form-control mt-1 w-50" id="addSponsorName" value={userLastName} onChange={e => updateUserName(e, "lastName")} onBlur={() => handleNameBlur("lastName")}></input> : <h4 className="checkedText">{userLastName}</h4> }
            </Row>
            <hr></hr>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-ce d-flex flex-row justify-content-start">
                <h5 className="checkedText m-0">Email:</h5>
                {editToggle.email ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, email: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, email: true}))} /></h5> }
              </div>
                {editToggle.email ? <input type="text" className="form-control mt-1 w-50" id="addSponsorName" value={userObject.email} onChange={e => updateField(e, "email")} onBlur={() => setEditToggle(prev => ({...prev, email: false}))}></input> : <h4 className="checkedText">{userObject.email}</h4> }
            </Row>
            <hr></hr>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText m-0">Phone Number:</h5>
                {editToggle.phone ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, phone: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, phone: true}))} /></h5> }
              </div>
                {editToggle.phone ? <input type="text" className="form-control mt-1 w-50" id="addSponsorName" value={userObject.phoneNumber} onChange={e => updateField(e, "phoneNumber")} onBlur={() => setEditToggle(prev => ({...prev, phone: false}))}></input> : <h4 className="checkedText">{userObject.phoneNumber}</h4> }
            </Row>
            <hr></hr>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText m-0">Home Town:</h5>
              </div>
              <h4 className="checkedText">User must update</h4>
            </Row>
          </Col>
            <Col className="col-4 ">
              <Row className="w-100 mb-4 align-items-center d-flex flex-row justify-content-center">
                <h5 className="checkedText mr-2">Profile Image:</h5>
          
                {userObject.photoUrl ? <><h4 className="checkedText"><IoMdTrash className='pencil isClickable ml-2' onClick={() => removePhoto()} /></h4><img src={userObject.photoUrl} alt={`${userObject.name} Profile`} className="imagesize"/> </>: <h6>User must update</h6>}
              </Row>
              <hr></hr>
              <Row className="d-flex flex-column align-items-center justify-content-center">
                <h4 className="manageDropdownActiveText mb-4 text-center">Temporary Password:</h4>
                <h5 className="manageDropdownActiveText mb-4">{tempPass}</h5>
              </Row>
              <hr></hr>
              <Row class="w-100 align-items-center d-flex flex-column">
                <div className="align-items-center d-flex flex-row justify-content-start">
                    <h2 className="checkedText text-center">Forever Free Account:</h2>
                </div>
                <Row className="w-100 justify-content-center">
                  {userObject.foreverFree ? <div className="btn btn-primary" onClick={() => {handleForeverFree()}}>Disable</div> :
                    <div className="btn btn-outline-primary" onClick={() => {handleForeverFree()}}>Enable</div>}
                </Row>
              </Row>
          
              <hr></hr>
              
            </Col>
        </Row>
        <hr></hr>
        <Row className="w-100 d-flex flex-row justify-content-around align-items-center">
        <Col className="w-100 align-items-center d-flex flex-column justify-content-start">
                <div className="align-items-center d-flex flex-row justify-content-start mb-2">
                  <h5 className="checkedText m-0 pb-2">User Role:</h5>
                  <h4 className="checkedText ml-2">{userObject.role}</h4>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Change Role
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {handleRoleSelect("user")}}>User</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleRoleSelect("programUser")}}>Program User</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleRoleSelect("admin")}}>Admin</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <hr></hr>
              <Col className="w-100 align-items-center d-flex flex-column justify-content-center">
                <div className="align-items-center d-flex flex-row justify-content-start mb-2">
                  <h5 className="checkedText m-0 pb-2">Status:</h5>
                  {userObject.status === "Suspended" ? <h4 className="text-danger ml-2">{userObject.status}</h4> : <h4 className="checkedText ml-2">{userObject.status}</h4>}
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Change Status
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {handleStatusSelect("active")}}>Active</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleStatusSelect("suspended")}}>Suspended</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
        <div className="manageDropdownButton px-3 py-2 mt-3 mr-4" onClick={() => {onHide()}}>Cancel</div>
          {userChanged ? <div className="manageDropdownButton px-4 py-2 mt-3" onClick={() => {handleNewUserSave()}}>Save</div> : 
            <div className="manageDropdownButtonUnchanged px-4 py-2 mt-3" >Save</div>}
        </Modal.Footer>
        <AdminModal 
          show={showAdminModal}
          onHide={() => setShowAdminModal(false)}
        />
    </Modal>
  );
}
