import * as React from 'react';

export default function SvgEventCalendar(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400 400'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <path
        id='prefix__Path_1167'
        data-name='Path 1167'
        className='prefix__cls-1'
        d='M199.68 348.4C117.4 349 50.27 282 49.74 198.91c-.5-76.91 56.45-141.78 132.08-150.5a5.13 5.13 0 011.3 10.14C107 67.84 52.7 137.73 61.9 214.65s78.38 131.74 154.53 122.45S346.85 257.92 337.65 181C330 116.85 279.93 66.3 216.43 58.55a5.14 5.14 0 011.2-10.14c81.75 9.42 140.45 84 131.13 166.57-8.63 76.39-72.87 133.93-149 133.42'
      />
      <g id='prefix__Group_574' data-name='Group 574'>
        <path
          id='prefix__Path_1168'
          data-name='Path 1168'
          className='prefix__cls-1'
          d='M154 145.14v7.72h-4.37c-2.46 0-3.57.9-3.64 3.4-.11 4.15 0 8.3 0 12.46 0 .37.68.92 1.13 1.07a5.14 5.14 0 001.75 0h101.48c2.46 0 2.46 0 2.48 2.47v4.21c0 1-.39 1.39-1.37 1.33H148.55c-2.64 0-2.64 0-2.64 2.66v66.45c0 3.71 1.61 5.35 5.32 5.35H248a4.71 4.71 0 005.38-3.93 4.51 4.51 0 000-1.42V158a10.33 10.33 0 00-.37-2.77 3 3 0 00-3.27-2.47c-4.37 0-4.5 0-4.37-4.36 0-3.77-.52-3.32 3.36-3.34a11.33 11.33 0 0112.72 12.59v89.45a13.09 13.09 0 01-13.09 13.09h-97.63a12.41 12.41 0 01-12.81-12V157.9a18.12 18.12 0 011.55-6.91 9.06 9.06 0 018.12-5.79c2.01-.2 4.08-.06 6.41-.06z'
        />
        <path
          id='prefix__Path_1169'
          data-name='Path 1169'
          className='prefix__cls-1'
          d='M219.65 153.12h-40.39v-8c.64 0 1.27-.13 1.88-.13h36.43c1.55 0 2.19.33 2.19 2s-.11 4.01-.11 6.13z'
        />
        <path
          id='prefix__Path_1170'
          data-name='Path 1170'
          className='prefix__cls-1'
          d='M236.16 149.17v7.88a3.93 3.93 0 01-3.63 4.2h-.29a4 4 0 01-4-3.91V141a4.06 4.06 0 013.74-4.35h.3a4 4 0 013.93 4.09v.16c0 2.8-.05 5.53-.05 8.27z'
        />
        <path
          id='prefix__Path_1171'
          data-name='Path 1171'
          className='prefix__cls-1'
          d='M170.56 148.87v7.9a4.19 4.19 0 01-3.85 4.5h-.23a4 4 0 01-3.9-4v-16.12a4 4 0 013.51-4.35 4.52 4.52 0 01.52 0 4 4 0 013.94 4.13 1.93 1.93 0 010 .24c.03 2.55.01 5.12.01 7.7z'
        />
        <path
          id='prefix__Path_1172'
          data-name='Path 1172'
          className='prefix__cls-1'
          d='M170.8 186.46h7.74v7.61h-7.74z'
        />
        <path
          id='prefix__Path_1173'
          data-name='Path 1173'
          className='prefix__cls-1'
          d='M187.27 194.07v-7.57h7.66v7.57z'
        />
        <path
          id='prefix__Path_1174'
          data-name='Path 1174'
          className='prefix__cls-1'
          d='M211.38 194.12h-7.57v-7.64h7.57z'
        />
        <path
          id='prefix__Path_1175'
          data-name='Path 1175'
          className='prefix__cls-1'
          d='M228 186.35v7.7h-7.87v-6.91c0-.27.56-.75.87-.77 2.27-.04 4.54-.02 7-.02z'
        />
        <path
          id='prefix__Path_1176'
          data-name='Path 1176'
          className='prefix__cls-1'
          d='M236.71 194.18v-7.83h7c.26 0 .72.61.72.94v5.78a1.48 1.48 0 01-1 1c-2.2.16-4.34.11-6.72.11z'
        />
        <path
          id='prefix__Path_1177'
          data-name='Path 1177'
          className='prefix__cls-1'
          d='M154.33 202.93H162v7.75h-6.92a1.09 1.09 0 01-.72-.75c-.05-2.35-.03-4.64-.03-7z'
        />
        <path
          id='prefix__Path_1178'
          data-name='Path 1178'
          className='prefix__cls-1'
          d='M170.87 202.91h7.76V210c0 .24-.61.65-1 .68h-6.78z'
        />
        <path
          id='prefix__Path_1179'
          data-name='Path 1179'
          className='prefix__cls-1'
          d='M194.93 210.61h-7.57V203h7.57z'
        />
        <path
          id='prefix__Path_1180'
          data-name='Path 1180'
          className='prefix__cls-1'
          d='M211.44 203v7.59h-7.61V203z'
        />
        <path
          id='prefix__Path_1181'
          data-name='Path 1181'
          className='prefix__cls-1'
          d='M220.08 202.93h7.84v7.75h-7c-.26 0-.74-.57-.74-.9-.12-2.25-.1-4.45-.1-6.85z'
        />
        <path
          id='prefix__Path_1182'
          data-name='Path 1182'
          className='prefix__cls-1'
          d='M236.77 202.91h7.51v7.61h-7.51z'
        />
        <path
          id='prefix__Path_1183'
          data-name='Path 1183'
          className='prefix__cls-1'
          d='M162.12 227.19h-6.88c-.32 0-.89-.72-.91-1.11v-5.61c0-.91.43-1.22 1.31-1.2h5.26c.85 0 1.28.26 1.26 1.2-.07 2.12-.04 4.26-.04 6.72z'
        />
        <path
          id='prefix__Path_1184'
          data-name='Path 1184'
          className='prefix__cls-1'
          d='M170.69 227.17v-6.87a1.59 1.59 0 011.13-1.05c1.82-.11 3.63 0 5.44 0 1 0 1.37.33 1.35 1.33V226a1.54 1.54 0 01-1 1.18c-2.21-.01-4.52-.01-6.92-.01z'
        />
        <path
          id='prefix__Path_1185'
          data-name='Path 1185'
          className='prefix__cls-1'
          d='M195 219.25v7.83h-7.74V220c0-.26.59-.7.91-.72 2.29-.05 4.47-.03 6.83-.03z'
        />
        <path
          id='prefix__Path_1186'
          data-name='Path 1186'
          className='prefix__cls-1'
          d='M203.83 219.25h6.83a1.21 1.21 0 01.85.79v7h-7.72z'
        />
        <path
          id='prefix__Path_1187'
          data-name='Path 1187'
          className='prefix__cls-1'
          d='M228.05 227.15h-6.87a1.57 1.57 0 01-1.05-1.14c-.11-1.81 0-3.62 0-5.43 0-1 .33-1.37 1.33-1.35h5.43a1.54 1.54 0 011.18 1c.04 2.25-.02 4.47-.02 6.92z'
        />
        <path
          id='prefix__Path_1188'
          data-name='Path 1188'
          className='prefix__cls-1'
          d='M244.39 227.13h-7.79v-6.88a1.47 1.47 0 011.05-1h5.6c.38 0 1 .53 1 .83.18 2.36.14 4.67.14 7.05z'
        />
        <path
          id='prefix__Path_1189'
          data-name='Path 1189'
          className='prefix__cls-1'
          d='M178.65 243.55h-7.11a1.21 1.21 0 01-.8-.87v-6.87h7.09a1.19 1.19 0 01.8.83c.07 2.25.02 4.45.02 6.91z'
        />
        <path
          id='prefix__Path_1190'
          data-name='Path 1190'
          className='prefix__cls-1'
          d='M187.21 235.85H195v7c0 .27-.72.7-1.14.72a49.18 49.18 0 01-5.43 0 1.58 1.58 0 01-1.18-1c-.09-2.2-.04-4.36-.04-6.72z'
        />
        <path
          id='prefix__Path_1191'
          data-name='Path 1191'
          className='prefix__cls-1'
          d='M211.45 243.42h-7.68v-7.48h7.68z'
        />
        <path
          id='prefix__Path_1192'
          data-name='Path 1192'
          className='prefix__cls-1'
          d='M228.07 235.79v6.54c0 .94-.41 1.25-1.26 1.22h-5.44a1.75 1.75 0 01-1.24-1.15 37.85 37.85 0 010-5.43 1.57 1.57 0 011.05-1.14c2.2-.11 4.42-.04 6.89-.04z'
        />
        <path
          id='prefix__Path_1193'
          data-name='Path 1193'
          className='prefix__cls-1'
          d='M162.05 235.94v7.64h-6.83a1.25 1.25 0 01-.85-.85V236z'
        />
      </g>
    </svg>
  );
}
