import React, { useState, useEffect } from 'react';
import EditModal from '../EditModal/index';
import DeleteModal from '../DeleteModal/index';
import DeleteListingModal from '../DeleteListingModal/index';
import { MDBDataTable } from 'mdbreact';
// import { db } from '../../../../firebase';
// import { RiPencilFill } from 'react-icons/ri';
// import { IoMdTrash } from 'react-icons/io';
// import { captureException } from '../../../../utils/utility';

const Table = ({
  filterBy,
  currentUser,
  getUsers,
  setCurrentUser,
  userObject,
  setUserObject,
  // defaultUserObject,
  setCreateProgramModalShow,
  editModalShow,
  setEditModalShow,
  handleCloseEditModal,
  table,
  tableColumns,
  program,
  setProgram,
  // setTable,
  filteredTable,
  setFilteredTable,
  editForeverFree,
  setEditForeverFree,
  deleteModalShow,
  setDeleteModalShow,
}) => {
  const [tempPass, setTempPass] = useState(null);
  // const [program, setProgram] = useState(null);
  // const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteListingModalShow, setDeleteListingModalShow] = useState(false);

  // let tableColumns = {
  //   columns: [
  //     {
  //       label: 'EDIT/DELETE',
  //       field: 'edit',
  //       sort: 'asc',
  //       width: 50,
  //     },
  //     {
  //       label: 'NAME',
  //       field: 'name',
  //       sort: 'asc',
  //       width: 50,
  //     },
  //     {
  //       label: 'EMAIL',
  //       field: 'email',
  //       sort: 'asc',
  //       width: 50,
  //     },
  //     {
  //       label: 'PHONE',
  //       field: 'phone',
  //       sort: 'asc',
  //       width: 50,
  //     },
  //     {
  //       label: 'HOMETOWN',
  //       field: 'homeTown',
  //       sort: 'asc',
  //       width: 50,
  //     },
  //     {
  //       label: 'SUBSCRIPTION',
  //       field: 'subscriptionType',
  //       sort: 'asc',
  //       width: 50,
  //     },

  //     {
  //       label: 'USER ROLE',
  //       field: 'role',
  //       sort: 'asc',
  //       width: 50,
  //     },
  //     {
  //       label: 'STATUS',
  //       field: 'status',
  //       sort: 'asc',
  //       width: 50,
  //     },
  //   ],
  // };
  //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //filter array and then set table state
  // let tableRows = {
  //   rows: [{}],
  // };
  // const handleOpenEditModal = (doc) => {
  //   console.log("doc.data()", doc.data())
  //   setCurrentUser(doc);
  //   setEditModalShow(true);
  // };
  // const handleCloseEditModal = () => {
  //   setTempPass(null);
  //   setEditForeverFree(false)
  //   setProgram(null);
  //   setUserObject(defaultUserObject);
  //   setCurrentUser(null);
  //   setEditModalShow(false);
  // };
  // const handleOpenDeleteModal = (doc) => {
  //   setCurrentUser(doc);
  //   setDeleteModalShow(true);
  // };

  const filterUsers = (data) => {
    let newRows = {
      rows: [],
    };
    switch (data) {
      case 'eeUsers':
        // console.log("eeUsers")
        // console.log(table.rows[1].role)
        newRows.rows = table.rows.filter((row) => row.role === 'User');
        console.log(newRows.rows);
        setFilteredTable({ ...tableColumns, ...newRows });
        break;
      case 'admin':
        // console.log("admins")
        newRows.rows = table.rows.filter((row) => row.role === 'Admin');
        console.log(newRows.rows);
        setFilteredTable({ ...tableColumns, ...newRows });
        break;
      case 'program':
        // console.log("program")
        newRows.rows = table.rows.filter((row) => row.role === 'Program');
        console.log(newRows.rows);
        setFilteredTable({ ...tableColumns, ...newRows });
        break;
      default:
        // console.log("all");
        console.log(table.rows);
        setFilteredTable(table);
    }
  };

  //{TODO: add loading status for data table load}
  // const getUsers = async () => {
  //   try{
  //     const usersRef = db.collection('users');
  //     const snapshot = await usersRef.get();
  //     snapshot.forEach((doc) => {
  //       let user = {
  //         edit: (
  //           <article className='users-icon-group'>
  //             <div>
  //               <RiPencilFill
  //                 className='users-pencil'
  //                 onClick={() => handleOpenEditModal(doc)}
  //               />
  //             </div>
  //             <div>
  //               <IoMdTrash
  //                 className='users-pencil'
  //                 onClick={() => handleOpenDeleteModal(doc)}
  //               />
  //             </div>
  //           </article>
  //         ),
  //         name: doc.data().name ? doc.data().name : '',
  //         email: doc.data().email ? doc.data().email : '',
  //         phone: doc.data().phoneNumber ? doc.data().phoneNumber : '',
  //         homeTown: doc.data().homeTown ? doc.data().homeTown : '',
  //         subscriptionType: doc.data().subscription?.type
  //           ? doc.data().subscription.type
  //           : 'Free',
  //         subscription: doc.data().subscription
  //           ? doc.data().subscription
  //           : { type: 'Free' },
  //         programUser: doc.data()?.programUser,
  //         foreverFree: doc.data().foreverFree ? doc.data().foreverFree : false,
  //         isAdmin: doc.data()?.adminUser ? true : false,
  //         role: doc.data()?.role ? doc.data()?.role : 'User',
  //         status: doc.data()?.status ? doc.data()?.status : 'Active',
  //         uid: doc.data().uid,
  //         homeTownCoordinates: doc.data().homeTownCoordinates
  //           ? doc.data().homeTownCoordinates
  //           : { lat: '', lng: '' },
  //         program: doc.data().program
  //           ? doc.data().program
  //           : {
  //               imageUrl: null,
  //               startDate: { seconds: null },
  //               endDate: { seconds: null },
  //             },
  //       };
  //       tableRows.rows.push(user);
  //     });
  //     //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //     //filter array and then set table state
  //     setTable({ ...tableColumns, ...tableRows });
  //     setFilteredTable({ ...tableColumns, ...tableRows });

  //   } catch (e) {
  //     captureException({message: 'Failed to get user data',e});
  //   }
  // };

  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    filterUsers(filterBy);
  }, [filterBy]);

  return (
    <>
      {
        table && filteredTable && 
        <MDBDataTable
          entries={8}
          noBottomColumns
          fixed
          displayEntries={false}
          // searching={false}
          className='col-xl-12'
          data={filteredTable}
        />
      }
      <DeleteListingModal
        show={deleteListingModalShow}
        setDeleteListingModalShow={() => setDeleteListingModalShow(false)}
        currentUser={currentUser}
        getUsers={getUsers}
        userObject={userObject}
      />
      <EditModal
        show={editModalShow}
        onHide={() => handleCloseEditModal()}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        getUsers={getUsers}
        userObject={userObject}
        setUserObject={setUserObject}
        setDeleteListingModalShow={setDeleteListingModalShow}
        setCreateProgramModalShow={setCreateProgramModalShow}
        setEditModalShow={setEditModalShow}
        tempPass={tempPass}
        setTempPass={setTempPass}
        program={program}
        setProgram={setProgram}
        editForeverFree={editForeverFree}
        setEditForeverFree={setEditForeverFree}
        backdrop="static"
      />
      <DeleteModal
        show={deleteModalShow}
        getUsers={getUsers}
        userObject={userObject}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        setUserObject={setUserObject}
        onHide={() => setDeleteModalShow(false)}
      />
    </>
  );
};

export default Table;
