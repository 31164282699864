import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';
import './App.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: 'https://c2f392ed4e164953a4feb3337020b41f@o954918.ingest.sentry.io/5908720',
  integrations: [new Integrations.BrowserTracing()],
  environment: 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
   tracesSampleRate: 1.0,
});



function App() {


  return (
    <BrowserRouter>
      <div className='Site'>
        {/* <Header/> */}
        <div className='Site-content'>
          <Router />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
