import React, { useState, useEffect } from 'react';
import { MDBDataTable, MDBDataTableV5 } from 'mdbreact';

import { db } from '../../../../firebase';
import { GrCheckmark } from 'react-icons/gr';
import { BsPencil } from 'react-icons/bs';
import { VscChromeClose } from 'react-icons/vsc';
import { RiCheckboxBlankCircleLine, RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { captureException } from '../../../../utils/utility';
const Table = ({ handleEdit, handleDelete, convertTime, resetSponsors }) => {
  const [table, setTable] = useState({});


  let tableColumns = {
    columns: [
      {
        label: <h6 className="table-heading">AD IMAGES</h6>,
        field: 'image',
        sort: 'asc',
        width: 300,
      },
      {
        label: <h6 className="table-heading">SPONSOR NAME</h6>,
        field: 'name',
        sort: 'asc',
        width: 200,
      },
      {
        label: <h6 className="table-heading">SPONSOR LINK</h6>,
        field: 'link',
        sort: 'asc',
        width: 100,
      },
      {
        label: <h6 className="table-heading">FEATURED</h6>,
        field: 'featured',
        sort: 'asc',
        width: 100,
      },
      {
        label: <h6 className="table-heading">OPTIONS</h6>,
        field: 'icons',
        sort: 'asc',
        width: 100,
      },
    ],
  };
  //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //filter array and then set table state
  let tableRows = {
    rows: [{}],
  };



  const getSponsors = async () => {
    try {
      setTable({});
      tableRows = {
        rows: [{}],
      };
      const sponsorRef = db.collection('sponsors');
      const snapshot = await sponsorRef.get();
      let inactiveSponsors = [];

      snapshot.forEach((doc, index) => {
        // console.log(doc.data())
        const currentDate = Date.now();
        const startDate = doc.data()["start-date"] ? convertTime(doc.data()["start-date"].seconds) : null;
        const endDate = doc.data()["end-date"] ? convertTime(doc.data()["end-date"].seconds) : null;
        const featuredStartDate = doc.data()["featured-start-date"] ? convertTime(doc.data()["featured-start-date"].seconds): null;
        const featuredEndDate = doc.data()["featured-end-date"] ? convertTime(doc.data()["featured-end-date"].seconds) : null;

        let featured = false;

        if ((startDate && endDate) || (featuredStartDate && featuredEndDate)) {
          let sponsor;
          if(featuredStartDate && (featuredStartDate < currentDate && featuredEndDate > currentDate)) {
            featured = true
            sponsor = {
              image: <img src={doc.data().image ? doc.data().image : doc.data().carouselImage} alt={`${doc.data().name} Sponsor Ad`} className="imagesize"/>,
              name: <div className='table-group' searchvalue={doc.data().name}><h5 className="checkedText">{doc.data().name}</h5></div>,
              link: <div className='table-group'><a href={doc.data().url} target="_blank" rel="noreferrer"><h5 className="checkedText">{doc.data().url}</h5></a></div>,
              featured: <div className='table-group'><h4 className="checkedText">{featured ? <RiCheckboxBlankCircleFill /> : <RiCheckboxBlankCircleLine />}</h4></div>,
              icons: (
                <div className='.icon-group-sponsors table-group'>
                  <h4 className="checkedText"><BsPencil className='pencil isClickable' onClick={() => {handleEdit(doc)}} /></h4>
                  <h4 className="checkedText"><VscChromeClose className='isClickable' onClick={() => {handleDelete(doc)}} /></h4>
                </div>
              ),
            };
            tableRows.rows.push(sponsor);
          } else if ((startDate && !featuredStartDate) && (startDate < currentDate && endDate > currentDate)) {
            sponsor = {
              image: <img src={doc.data().image ? doc.data().image : doc.data().carouselImage} alt={`${doc.data().name} Sponsor Ad`} className="imagesize"/>,
              name: <div className='table-group' searchvalue={doc.data().name}><h5 className="checkedText">{doc.data().name}</h5></div>,
              link: <div className='table-group'><a href={doc.data().url} target="_blank" rel="noreferrer"><h5 className="checkedText">{doc.data().url}</h5></a></div>,
              featured: <div className='table-group'><h4 className="checkedText">{featured ? <RiCheckboxBlankCircleFill /> : <RiCheckboxBlankCircleLine />}</h4></div>,
              icons: (
                <div className='.icon-group-sponsors table-group'>
                  <h4 className="checkedText"><BsPencil className='pencil isClickable' onClick={() => {handleEdit(doc)}} /></h4>
                  <h4 className="checkedText"><VscChromeClose className='isClickable' onClick={() => {handleDelete(doc)}} /></h4>
                </div>
              ),
            };
            tableRows.rows.push(sponsor);
          } else {
            let sponsor = {
              image: <img src={doc.data().image ? doc.data().image : doc.data().carouselImage} alt={`${doc.data().name} Sponsor Ad`} className="imagesize inactive-image"/>,
              name: <div className='table-group' searchvalue={doc.data().name}><h5 className="uncheckedText">{doc.data().name}</h5></div>,
              link: <div className='table-group'><a href={doc.data().url} target="_blank" rel="noreferrer"><h5 className="uncheckedText">{doc.data().url}</h5></a></div>,
              featured: <div className='table-group'><h4 className="uncheckedText"><RiCheckboxBlankCircleLine /></h4></div>,
              icons: (
                <div className='.icon-group-sponsors table-group'>
                  <h4 className="uncheckedText"><VscChromeClose className='isClickable' onClick={() => {handleDelete(doc)}} /></h4>
                </div>
              ),
            };
            inactiveSponsors.push(sponsor);
          }
        } 
      });
      //table rows var will have array of current objects with current uid if needed to match for deletion or edit
      //filter array and then set table state
      if(inactiveSponsors.length > 0) {
        inactiveSponsors.forEach(sponsor => tableRows.rows.push(sponsor))
      }
      // console.log(tableRows)
      setTable({ ...tableColumns, ...tableRows });

    } catch (e) {
      captureException({message: 'Failed to show sponsors data in data table', e});
    }
  };

  // useEffect(() => {
  //   console.log(table);
  // }, [table]);
  useEffect(() => {
    getSponsors();
  }, [resetSponsors]);

  return (
    <>
      <MDBDataTable
        entries={5}
        entriesOptions={[5, 10, 25]}
        noBottomColumns
        // searching={false}
        className='col-xl-10'
        sortRows={['name']}
        data={table}

      />

    </>
  );
};

export default Table;
