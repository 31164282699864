import React, { useContext, useState, useEffect } from 'react';
import Carousel from '../../components/Carousel';
import { Header } from '../../components/Header/index';
import { COLOR_GREENISH } from '../../utils/constants';
import { AuthContext } from '../../Auth';
import { useHistory } from 'react-router-dom';
import { IPhoneX, Pixel3XL } from 'react-device-frames';
import { AppleAppStore } from '../../Icons/AppleAppStore'
import { GooglePlayStore } from '../../Icons/GooglePlayStore'
 
export default function Home() {
  const { currentUser } = useContext(AuthContext);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 720;
  const history = useHistory();

  if(currentUser) {
    history.push('./dashboard');
  }

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      <Header />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          backgroundColor: "#fff",
          paddingTop: 20,
          paddingBottom: 100,
          flexDirection: 'column',
        }}
      >

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              marginTop: 100,
              flexDirection: 'column',

            }}
          >
            <h1 className="comingSoon" style={{ color: COLOR_GREENISH, textAlign: 'center' }}>Now available for download on</h1>
            <div
              style={{
                display: 'flex', 
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginTop: 15,
              }}
            >
              <div className="isClickable" style={{ marginRight: 30, marginLeft: 30, marginBottom: 20 }} onClick={()=> window.open("https://apps.apple.com/us/app/equine-exchange/id1583419702", "_blank")}>
                <AppleAppStore height={75} />
              </div>

              <div className="isClickable" style={{ marginRight: 30, marginLeft: 30, marginBottom: 20}} onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.equineexchange.app&hl=en_US&gl=US", "_blank")}>
                <GooglePlayStore height={75} />
              </div>
            </div>
              <h1 className="comingSoon" style={{ color: COLOR_GREENISH, fontSize: 24,  textAlign: 'center' }}>Be sure to download the Equine Exchange app today!</h1>
              <h2 className="comingSoon" style={{ color: COLOR_GREENISH, fontSize: 18, marginTop: 15, textAlign: 'center' }}>Click your preferred download site to download the Equine Exchange app now!</h2>
          </div>

        <div
          style={{
            width: width < breakpoint ? '92%' : '50%',
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap'
          }}
        >

          <div
            style={{
              marginTop: 40,
              width: width < breakpoint ? '100%' : '48%',
            }}
          >
            <IPhoneX screenshot={"https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/screenshots%2FSearch%20Screen%20(Wesley%20Horses).PNG?alt=media&token=fc7d3e35-4b1e-440e-8866-58dec60af9ce"} />
          </div>
          
          <div
            style={{
              marginTop: 45,
              width: width < breakpoint ? '100%' : '47%',
            }}
          >
            <Pixel3XL screenshot={"https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/screenshots%2FDiscipline%20Select.png?alt=media&token=4925eb53-cc63-4643-b4d1-80d5e0a63db4"} />
          </div>
          <h2 className="comingSoon isClickable" style={{ color: COLOR_GREENISH, fontSize: 18, marginTop: 45 }} onClick={()=> window.open("https://flintavenue.com/", "_blank")}>Powered by © Flint Avenue Marketing</h2>
        </div>
      </div>
    </>
  );
}
