import React, { useState } from 'react';
import './transactions.css';
import { Container, Row, Col } from 'react-bootstrap';
import Table from './Table/index';
const ManageTransactions = () => {
  
  const [table, setTable] = useState({});
  return (
    <Container className='calendar-page-container'>
      <Row className='users-top-row'>
        <Col xs={8}>
          <article className='users-left-article'>
            <section className='users-title-button-section'>
              <h1>Transactions</h1>
              
            </section>
          </article>
        </Col>
      </Row>
      <Row className='transaction-content'>
      <Table table={table} setTable={setTable} className='mb-0 pb-0'/>
      </Row>
    </Container>
  );
};

export default ManageTransactions;