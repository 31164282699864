import { Switch, Route } from 'react-router';
import Home from './routes/Home';
import TermsOfService from './routes/TermsOfService';
import PrivacyPolicy from './routes/PrivacyPolicy';
import Contact from './routes/Contact';
import Dashboard from './routes/Dashboard';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './Auth';

export const Router = () => {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        <Route exact path='/terms-of-service' component={TermsOfService} />
        <Route exact path='/contact' component={Contact} />
        <PrivateRoute path='/dashboard' component={Dashboard}></PrivateRoute>
      </Switch>
    </AuthProvider>
  );
};
