import React from "react";

export const GooglePlayStore = ({ height }) => {
    return (
		<svg 
			height={height}
			viewBox="0 0 431.554 127.867"
			fill="none"
            enable-background="new 0 0 431.554 127.867"
			xmlns="http://www.w3.org/2000/svg">
<g>
	<path fill="#000" d="M415.57,2.557c7.405,0,13.427,6.023,13.427,13.427v95.9c0,7.406-6.021,13.428-13.427,13.428H15.983
		c-7.403,0-13.425-6.021-13.425-13.428v-95.9c0-7.403,6.022-13.427,13.425-13.427H415.57"/>
	<g>
		<path fill="#A7A6A6" d="M415.57,2.557c7.405,0,13.427,6.023,13.427,13.427v95.9c0,7.405-6.021,13.427-13.427,13.427H15.983
			c-7.403,0-13.425-6.021-13.425-13.427v-95.9c0-7.403,6.022-13.427,13.425-13.427H415.57 M415.57,0H15.983
			C7.192,0,0,7.192,0,15.983v95.9c0,8.791,7.192,15.983,15.983,15.983H415.57c8.791,0,15.983-7.192,15.983-15.983v-95.9
			C431.554,7.192,424.361,0,415.57,0z"/>
		<g>
			<path fill="#FFFFFF" d="M142.155,42.305c2.956,0.003,5.409-0.979,7.271-2.933l0.001,0.001c1.656-1.654,2.479-3.89,2.476-6.629
				c0-0.485-0.045-0.985-0.139-1.503l-0.049-0.264h-9.88v2.624v0.32h6.887c-0.185,1.417-0.644,2.544-1.437,3.339
				c-1.373,1.369-3.059,2.045-5.13,2.048c-1.945-0.003-3.594-0.671-5.018-2.039c-1.407-1.363-2.108-3.102-2.111-5.302
				c0.003-2.201,0.704-3.939,2.111-5.302c1.424-1.368,3.072-2.036,5.018-2.039c2.161,0.005,3.793,0.72,5.005,2.16l0.226,0.269
				l1.932-1.934l0.206-0.208l-0.187-0.225c-0.787-0.937-1.833-1.678-3.119-2.23c-1.288-0.553-2.647-0.831-4.063-0.831
				c-2.843-0.003-5.297,0.998-7.283,2.974c-1.998,1.973-3.005,4.453-3.002,7.366c-0.003,2.911,1.004,5.393,3.002,7.367
				C136.858,41.31,139.312,42.308,142.155,42.305z"/>
			<polygon fill="#FFFFFF" points="166.638,39.2 166.638,38.88 157.902,38.88 157.902,33.438 165.778,33.438 165.778,30.815 
				165.778,30.495 157.902,30.495 157.902,25.054 166.638,25.054 166.638,24.733 166.638,22.377 166.638,22.057 154.799,22.057 
				154.799,41.877 166.638,41.877 			"/>
			<polygon fill="#FFFFFF" points="176.71,41.877 177.029,41.877 177.029,41.557 177.029,25.054 182.387,25.054 182.387,22.377 
				182.387,22.057 168.566,22.057 168.566,24.733 168.566,25.054 173.924,25.054 173.924,41.877 			"/>
			<polygon fill="#FFFFFF" points="194.066,41.877 194.387,41.877 194.387,22.057 191.604,22.057 191.283,22.057 191.283,41.877 
				191.604,41.877 			"/>
			<polygon fill="#FFFFFF" points="204.999,41.877 205.319,41.877 205.319,41.557 205.319,25.054 210.676,25.054 210.676,22.377 
				210.676,22.057 196.855,22.057 196.855,24.733 196.855,25.054 202.213,25.054 202.213,41.877 			"/>
			<path fill="#FFFFFF" d="M228.273,42.305c2.875,0.003,5.322-1.005,7.261-3.003c1.938-1.992,2.919-4.459,2.916-7.334
				c0.003-2.858-0.98-5.323-2.928-7.322c-1.945-2.006-4.387-3.02-7.249-3.017c-2.875-0.003-5.323,1.007-7.259,3.005
				c-1.944,1.992-2.918,4.459-2.915,7.334c-0.003,2.875,0.971,5.343,2.915,7.334C222.95,41.299,225.398,42.308,228.273,42.305z
				 M228.273,24.626c1.979,0.003,3.618,0.681,4.982,2.061c1.36,1.38,2.038,3.115,2.041,5.28c-0.003,2.165-0.681,3.9-2.041,5.28
				c-1.364,1.38-3.003,2.058-4.982,2.061c-1.979-0.003-3.615-0.681-4.982-2.061c-1.354-1.38-2.035-3.115-2.039-5.28
				c0.004-2.165,0.685-3.9,2.039-5.28C224.658,25.307,226.294,24.629,228.273,24.626z"/>
			<polygon fill="#FFFFFF" points="244.054,41.877 244.372,41.877 244.372,29.605 244.294,26.905 253.628,41.877 256.378,41.877 
				256.697,41.877 256.697,22.057 253.912,22.057 253.594,22.057 253.594,33.605 253.672,36.306 244.769,22.057 241.591,22.057 
				241.272,22.057 241.272,41.877 241.591,41.877 			"/>
			<path fill="#FFFFFF" d="M152.622,73.705v5.769h13.803c-0.412,3.243-1.493,5.613-3.142,7.261
				c-2.009,2.008-5.151,4.221-10.662,4.221c-8.497,0-15.141-6.849-15.141-15.346c0-8.496,6.644-15.347,15.141-15.347
				c4.584,0,7.931,1.803,10.404,4.12l4.069-4.068c-3.451-3.295-8.034-5.819-14.473-5.819c-11.64,0-21.425,9.476-21.425,21.114
				c0,11.638,9.785,21.115,21.425,21.115c6.283,0,11.022-2.061,14.729-5.922c3.812-3.812,4.996-9.169,4.996-13.496
				c0-1.336-0.103-2.572-0.309-3.602H152.622z"/>
			<path fill="#FFFFFF" d="M188.037,69.534c-7.519,0-13.647,5.716-13.647,13.595c0,7.826,6.129,13.596,13.647,13.596
				c7.521,0,13.648-5.77,13.648-13.596C201.686,75.25,195.558,69.534,188.037,69.534z M188.037,91.367
				c-4.119,0-7.674-3.396-7.674-8.238c0-4.892,3.555-8.238,7.674-8.238c4.12,0,7.673,3.347,7.673,8.238
				C195.71,87.971,192.157,91.367,188.037,91.367z"/>
			<path fill="#FFFFFF" d="M362.46,69.459c-4.317,0-8.791,1.904-10.643,6.119l5.295,2.21c1.13-2.21,3.24-2.929,5.45-2.929
				c3.085,0,6.222,1.849,6.271,5.139v0.412c-1.08-0.618-3.393-1.542-6.221-1.542c-5.707,0-11.517,3.134-11.517,8.997
				c0,5.348,4.68,8.791,9.924,8.791c4.009,0,6.222-1.799,7.608-3.909h0.205v3.088h5.76V80.514
				C374.594,73.418,369.297,69.459,362.46,69.459z M361.738,91.361c-1.95,0-4.676-0.977-4.676-3.394
				c0-3.084,3.394-4.268,6.324-4.268c2.619,0,3.855,0.565,5.447,1.337C368.372,88.739,365.186,91.361,361.738,91.361z"/>
			<rect x="341.844" y="55.938" fill="#FFFFFF" width="5.963" height="39.963"/>
			<path fill="#FFFFFF" d="M217.81,69.534c-7.52,0-13.647,5.716-13.647,13.595c0,7.826,6.128,13.596,13.647,13.596
				s13.647-5.77,13.647-13.596C231.457,75.25,225.329,69.534,217.81,69.534z M217.81,91.367c-4.119,0-7.674-3.396-7.674-8.238
				c0-4.892,3.555-8.238,7.674-8.238c4.12,0,7.673,3.347,7.673,8.238C225.482,87.971,221.93,91.367,217.81,91.367z"/>
			<path fill="#FFFFFF" d="M325.441,55.938h-14.292V95.9h5.963V80.76h8.329c6.612,0,13.112-4.785,13.112-12.412
				C338.554,60.725,332.054,55.938,325.441,55.938z M325.597,75.197h-8.484V61.5h8.484c4.459,0,6.99,3.691,6.99,6.848
				C332.587,71.444,330.056,75.197,325.597,75.197z"/>
			<polygon fill="#FFFFFF" points="395.569,70.333 388.732,87.659 388.526,87.659 381.431,70.333 375.006,70.333 385.648,94.549 
				379.579,108.02 385.801,108.02 402.203,70.333 			"/>
			<path fill="#FFFFFF" d="M297.458,78.185c-1.134-3.038-4.583-8.65-11.639-8.65c-7.005,0-12.824,5.51-12.824,13.595
				c0,7.621,5.77,13.596,13.492,13.596c6.231,0,9.837-3.809,11.332-6.025l-4.636-3.09c-1.545,2.266-3.655,3.758-6.696,3.758
				c-3.037,0-5.2-1.389-6.59-4.12l18.179-7.518L297.458,78.185z M278.918,82.717c-0.153-5.254,4.07-7.932,7.107-7.932
				c2.37,0,4.377,1.186,5.048,2.884L278.918,82.717z"/>
			<path fill="#FFFFFF" d="M254.353,72.571h-0.206c-1.339-1.595-3.915-3.037-7.158-3.037c-6.8,0-13.03,5.975-13.03,13.648
				c0,7.62,6.23,13.542,13.03,13.542c3.243,0,5.819-1.442,7.158-3.091h0.206v1.958c0,5.203-2.781,7.981-7.262,7.981
				c-3.655,0-5.922-2.625-6.849-4.838l-5.201,2.16c1.492,3.605,5.457,8.035,12.05,8.035c7.006,0,12.928-4.121,12.928-14.163V70.358
				h-5.666V72.571z M247.503,91.367c-4.12,0-7.57-3.449-7.57-8.185c0-4.792,3.45-8.292,7.57-8.292c4.068,0,7.262,3.5,7.262,8.292
				C254.765,87.918,251.571,91.367,247.503,91.367z"/>
			<rect x="264.139" y="55.938" fill="#FFFFFF" width="5.973" height="39.963"/>
		</g>
		<g>
			<path fill="#EF3D4A" d="M73.2,63.937l-39.605,39.606h0.001l-0.235,0.236c0.006,0.007,0.012,0.013,0.02,0.02
				c0.061,0.063,0.122,0.125,0.186,0.184c0.007,0.007,0.015,0.013,0.023,0.022l0.005,0.003c0,0,0,0,0.001,0
				c0.752,0.678,1.717,1.039,2.824,1.039c0.001,0,0.001,0,0.002,0h0c0.08,0,0.161,0,0.242-0.006
				c1.019-0.047,2.149-0.394,3.337-1.067l46.699-26.535l-0.298-0.297L73.2,63.937z"/>
			<path fill="#50C8EC" d="M33.595,103.543L73.2,63.937L33.593,24.331l-0.234-0.234c-0.025,0.026-0.043,0.062-0.067,0.088
				c-0.127,0.141-0.242,0.299-0.353,0.461c-0.06,0.087-0.124,0.169-0.179,0.263c-0.106,0.179-0.197,0.377-0.285,0.578
				c-0.043,0.099-0.093,0.189-0.132,0.293c-0.084,0.225-0.149,0.471-0.211,0.72c-0.025,0.098-0.058,0.188-0.079,0.29
				c-0.074,0.359-0.127,0.738-0.153,1.14c0,0.001,0,0.003,0,0.005c0,0.004,0,0.009,0,0.016c-0.001,0.003-0.001,0.006-0.001,0.009
				c0,0.001,0,0.006,0,0.009c0,0.005,0,0.009-0.001,0.014c0,0.001,0,0.001,0,0.003c-0.011,0.189-0.017,0.383-0.017,0.581v0.001
				c0,0.006,0,0.013,0,0.019c0,0,0,0.001,0,0.001v0.467v69.767v0.462c0,1.982,0.549,3.509,1.479,4.492L33.595,103.543z"/>
			<path fill="#FEDA00" d="M105.691,63.944c0-0.005,0-0.009,0-0.014c-0.003-1.671-1.119-3.341-3.35-4.608l-9.974-5.667l-5.667-3.22
				H86.7l-0.297,0.296L73.2,63.937l13.203,13.205l0.298,0.297l-0.178,0.103l0.178-0.1l15.641-8.888
				c1.206-0.687,2.087-1.489,2.641-2.346c0.232-0.359,0.407-0.729,0.525-1.102c0.002-0.006,0.005-0.011,0.006-0.017
				C105.631,64.711,105.691,64.328,105.691,63.944z"/>
			<path fill="#64BE6A" d="M33.593,24.331L73.2,63.937l13.203-13.205l0.297-0.296l0.001-0.001L40.002,23.9
				c-1.284-0.729-2.5-1.074-3.582-1.074c-0.001,0-0.002,0-0.003,0c0,0-0.001,0-0.001,0c-0.129,0-0.256,0.005-0.382,0.014
				c-0.236,0.019-0.465,0.055-0.686,0.107c-0.026,0.006-0.049,0.018-0.075,0.025c-0.087,0.022-0.169,0.055-0.254,0.083
				c-0.152,0.05-0.302,0.105-0.446,0.173c-0.094,0.044-0.186,0.093-0.275,0.145c-0.125,0.071-0.245,0.15-0.362,0.235
				c-0.087,0.063-0.176,0.126-0.258,0.197c-0.025,0.022-0.055,0.038-0.08,0.061c-0.009,0.008-0.019,0.016-0.028,0.025
				c-0.072,0.065-0.142,0.134-0.209,0.206L33.593,24.331z"/>
		</g>
	</g>
</g>
		</svg>
	)
}
