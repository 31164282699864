import React from "react";

export const ApplePayMark = ({ height }) => {
    return (
		<svg 
			height={height}
			viewBox="0 0 165.521 105.965"
			fill="none"
            enable-background="new 0 0 165.521 105.965"
			xmlns="http://www.w3.org/2000/svg">
			<g>
                <path id="XMLID_4_" fill="#000000" d="M150.698,0H14.823c-0.565,0-1.133,0-1.697,0.003c-0.478,0.003-0.954,0.009-1.431,0.021
                    c-1.039,0.028-2.087,0.089-3.112,0.274C7.54,0.486,6.57,0.792,5.624,1.274c-0.931,0.473-1.782,1.092-2.521,1.83
                    s-1.357,1.589-1.83,2.52c-0.481,0.946-0.788,1.917-0.975,2.96c-0.186,1.026-0.247,2.074-0.274,3.112
                    c-0.014,0.477-0.019,0.953-0.021,1.429C-0.001,13.691,0,14.257,0,14.824v76.318c0,0.566-0.001,1.131,0.003,1.698
                    c0.003,0.477,0.008,0.953,0.021,1.429c0.027,1.037,0.089,2.086,0.274,3.111c0.187,1.043,0.493,2.014,0.975,2.96
                    c0.473,0.931,1.092,1.782,1.83,2.521s1.59,1.357,2.521,1.83c0.946,0.482,1.916,0.789,2.959,0.977
                    c1.025,0.183,2.073,0.245,3.112,0.273c0.477,0.011,0.953,0.018,1.431,0.02c0.564,0.004,1.132,0.004,1.697,0.004h135.875
                    c0.565,0,1.132,0,1.696-0.004c0.477-0.002,0.953-0.009,1.432-0.02c1.037-0.028,2.085-0.091,3.113-0.273
                    c1.041-0.188,2.011-0.494,2.957-0.977c0.932-0.473,1.781-1.092,2.521-1.83c0.737-0.738,1.356-1.59,1.83-2.521
                    c0.483-0.946,0.789-1.917,0.975-2.96c0.186-1.025,0.245-2.074,0.273-3.111c0.014-0.476,0.02-0.952,0.021-1.429
                    c0.005-0.567,0.005-1.132,0.005-1.698V14.824c0-0.567,0-1.133-0.005-1.699c-0.002-0.477-0.008-0.953-0.021-1.429
                    c-0.028-1.038-0.088-2.086-0.273-3.112c-0.186-1.043-0.491-2.014-0.975-2.96c-0.474-0.931-1.093-1.782-1.83-2.52
                    c-0.739-0.738-1.589-1.357-2.521-1.83c-0.946-0.482-1.916-0.788-2.957-0.976c-1.028-0.185-2.076-0.246-3.113-0.274
                    c-0.479-0.013-0.955-0.018-1.432-0.021C151.83,0,151.263,0,150.698,0L150.698,0z"/>
                <path id="XMLID_3_" fill="#FFFFFF" d="M150.698,3.532l1.672,0.003c0.452,0.003,0.905,0.008,1.36,0.021
                    c0.792,0.021,1.719,0.064,2.582,0.219c0.751,0.135,1.381,0.341,1.984,0.648c0.597,0.303,1.144,0.7,1.621,1.177
                    c0.479,0.479,0.876,1.027,1.184,1.63c0.306,0.599,0.51,1.226,0.645,1.982c0.154,0.854,0.197,1.783,0.219,2.58
                    c0.012,0.45,0.018,0.9,0.021,1.36c0.004,0.557,0.004,1.113,0.004,1.671v76.318c0,0.558,0,1.113-0.004,1.682
                    c-0.003,0.449-0.009,0.899-0.021,1.35c-0.021,0.797-0.064,1.725-0.221,2.59c-0.133,0.745-0.337,1.372-0.645,1.975
                    c-0.306,0.602-0.703,1.148-1.18,1.625c-0.48,0.48-1.025,0.876-1.629,1.182c-0.6,0.307-1.228,0.512-1.972,0.646
                    c-0.881,0.157-1.846,0.2-2.573,0.22c-0.457,0.01-0.912,0.017-1.379,0.019c-0.556,0.005-1.113,0.005-1.669,0.005H14.823
                    c-0.007,0-0.015,0-0.021,0c-0.55,0-1.101,0-1.66-0.005c-0.456-0.002-0.911-0.009-1.351-0.019c-0.744-0.02-1.71-0.063-2.584-0.219
                    c-0.749-0.135-1.378-0.34-1.985-0.65c-0.598-0.303-1.143-0.699-1.622-1.18c-0.477-0.476-0.873-1.021-1.179-1.623
                    c-0.307-0.602-0.512-1.23-0.646-1.985c-0.155-0.862-0.198-1.788-0.22-2.577c-0.012-0.453-0.018-0.905-0.021-1.355l-0.002-1.328
                    v-0.35V14.824v-0.351l0.002-1.325c0.003-0.453,0.009-0.905,0.021-1.357C3.576,11,3.619,10.075,3.776,9.206
                    c0.133-0.748,0.338-1.376,0.646-1.981c0.304-0.598,0.7-1.144,1.179-1.623c0.478-0.477,1.023-0.874,1.625-1.18
                    c0.601-0.306,1.23-0.511,1.98-0.646c0.864-0.155,1.791-0.198,2.585-0.219c0.452-0.012,0.905-0.017,1.354-0.021l1.677-0.003H150.698
                    "/>
                <g>
                    <g>
                        <path fill="#000000" d="M45.186,35.641c1.417-1.773,2.379-4.153,2.125-6.585c-2.074,0.103-4.606,1.369-6.072,3.143
                            c-1.315,1.519-2.48,3.999-2.177,6.329C41.391,38.729,43.718,37.363,45.186,35.641"/>
                        <path fill="#000000" d="M47.285,38.982c-3.382-0.201-6.258,1.919-7.873,1.919s-4.089-1.818-6.764-1.769c-3.482,0.051-6.713,2.02-8.479,5.151
                            c-3.635,6.264-0.959,15.554,2.575,20.656c1.716,2.524,3.784,5.303,6.51,5.203c2.574-0.101,3.584-1.667,6.714-1.667
                            c3.128,0,4.037,1.667,6.763,1.616c2.826-0.05,4.594-2.524,6.31-5.051c1.969-2.877,2.774-5.655,2.825-5.808
                            c-0.051-0.05-5.45-2.122-5.5-8.333c-0.052-5.201,4.239-7.675,4.441-7.829C52.384,39.488,48.597,39.084,47.285,38.982"/>
                    </g>
                    <g>
                        <path fill="#000000" d="M76.734,31.944c7.351,0,12.47,5.067,12.47,12.444c0,7.404-5.225,12.497-12.654,12.497h-8.139v12.943H62.53V31.944H76.734
                            z M68.411,51.949h6.747c5.12,0,8.033-2.756,8.033-7.535c0-4.778-2.913-7.508-8.007-7.508h-6.773V51.949z"/>
                        <path fill="#000000"d="M90.74,61.979c0-4.831,3.702-7.797,10.266-8.165l7.561-0.446v-2.126c0-3.072-2.074-4.91-5.539-4.91
                            c-3.282,0-5.33,1.575-5.828,4.043h-5.356c0.315-4.989,4.568-8.664,11.395-8.664c6.694,0,10.974,3.544,10.974,9.083v19.034h-5.435
                            v-4.541h-0.131c-1.602,3.071-5.094,5.014-8.716,5.014C94.521,70.301,90.74,66.94,90.74,61.979z M108.566,59.484v-2.179l-6.8,0.42
                            c-3.387,0.236-5.303,1.732-5.303,4.096c0,2.415,1.995,3.99,5.04,3.99C105.469,65.812,108.566,63.081,108.566,59.484z"/>
                        <path fill="#000000"d="M119.342,79.988v-4.594c0.419,0.104,1.364,0.104,1.837,0.104c2.626,0,4.044-1.103,4.909-3.938
                            c0-0.053,0.5-1.681,0.5-1.707l-9.977-27.646h6.143l6.984,22.474h0.104l6.984-22.474h5.985l-10.345,29.063
                            c-2.361,6.695-5.092,8.849-10.815,8.849C121.178,80.12,119.76,80.067,119.342,79.988z"/>
                    </g>
                </g>
            </g>
		</svg>
	)
}
