import * as React from 'react';

export default function SvgUser(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400 400'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <path
        className='prefix__cls-1'
        d='M270.43 202.91a38.61 38.61 0 00-15.15-15.59c-1.49-.89-3-1.65-4.56-2.46a30.31 30.31 0 005.33-9.05 29.06 29.06 0 001.53-13.2 27.87 27.87 0 00-4.46-12.38 28.64 28.64 0 00-24-13.09 29 29 0 00-3.74.24 28.47 28.47 0 00-20.69 13.17 2 2 0 00.61 2.75l3.69 2.36a1.69 1.69 0 00.36.17l.15.07a2.52 2.52 0 00.54.08H210.49a1.9 1.9 0 00.4-.14l.11-.07a1.28 1.28 0 00.25-.15l.12-.11a1.89 1.89 0 00.2-.2h.05l.88-1.14a19.65 19.65 0 0113.61-8.18 19.08 19.08 0 012.27-.14 20.72 20.72 0 012.92.22 20.21 20.21 0 0115.13 29.59 23.67 23.67 0 01-8.76 8.7c-.36.22-.7.47-1.09.75l-.7.49a2 2 0 00.53 3.56l.67.21c.83.27 1.49.47 2.19.66a40.34 40.34 0 0118.57 10.56 20 20 0 016.31 15.34c-.13 3.09-.09 6.19 0 9.19v4.14h-16.5a2.82 2.82 0 00-.48 0c-1.12.12-2.88.91-2.93 2.56a36.61 36.61 0 000 3.92 2 2 0 002 1.89h24.56a1.72 1.72 0 00.39 0 2 2 0 001.77-1.65 10.36 10.36 0 00.12-1.12v-21.33a23.81 23.81 0 00-2.65-10.62z'
      />
      <path
        className='prefix__cls-1'
        d='M217.29 202.47l.32-.31a27.73 27.73 0 001.12-39.22l-.15-.16a27.1 27.1 0 00-19.66-8.33 27.44 27.44 0 00-7.64 1.08l3.4-2.17a2 2 0 00.62-2.75 28.6 28.6 0 00-38.62-9.5 27.48 27.48 0 00-13.51 18.17 28.62 28.62 0 006.16 25.72 46.65 46.65 0 00-11.64 7.63 31.72 31.72 0 00-9.92 15.37c-.25.93-.42 1.85-.58 2.74-.08.4-.15.81-.23 1.22a1.72 1.72 0 000 .39v23.4a2 2 0 002 2h24.8a2 2 0 002-1.84c.06 3.28 0 6.59 0 9.81 0 2.09-.05 4.17-.05 6.26a2 2 0 002 2l82.59-.06a2 2 0 002-2v-20a22.6 22.6 0 00-3.36-12.17 42.42 42.42 0 00-21.65-17.28zm-8.53-4.78l-8 5.44a2 2 0 00.54 3.57l3.06.93c1.74.53 3.37 1 5 1.5a39.69 39.69 0 0118.17 10.41 18.39 18.39 0 015.87 14.17c-.12 2.74-.08 5.49 0 8.15v3.43h-69.13v-3.5c.06-2.67.12-5.44 0-8.16a16.4 16.4 0 015-12.63l.08-.08a41 41 0 0116.49-10.7c2.36-.85 4.76-1.64 7.3-2.47l3.43-1.13a2 2 0 001.35-1.66A2 2 0 00197 203l-8.31-5.11a18.74 18.74 0 019.82-34.7 18.76 18.76 0 0110.24 34.48zm-53.11 33.93a3 3 0 00-.69-1.39 3.8 3.8 0 00-2.91-1.06h-16.44v-4.45c0-3.1.08-6.31 0-9.46a18.41 18.41 0 014.62-12.83 37.64 37.64 0 0118.51-11.93l4.8-1.48a2 2 0 00.24-3.73 22.23 22.23 0 01-12.36-14.83 19.49 19.49 0 014.89-18.13 19.06 19.06 0 0114-6.51h1a20 20 0 0117 9.31 2 2 0 001.25.88 1.43 1.43 0 00.29 0 27.14 27.14 0 00-9.8 6.09 26.24 26.24 0 00-8.4 18.6 27.37 27.37 0 009.46 22 49.8 49.8 0 00-18.45 12.75 24.67 24.67 0 00-7.01 16.17z'
      />
      <path
        id='prefix__Path_1167'
        data-name='Path 1167'
        className='prefix__cls-1'
        d='M199.94 345.58c-82.27.54-149.4-66.39-149.94-149.5-.49-76.88 56.46-141.78 132.08-150.49a5.13 5.13 0 011.31 10.13C107.24 65 53 134.9 62.17 211.82s78.38 131.74 154.53 122.45 130.41-79.18 121.22-156.1C330.25 114 280.2 63.47 216.7 55.72a5.13 5.13 0 011.2-10.13C299.65 55 358.35 129.58 349 212.16c-8.63 76.39-72.88 133.92-149 133.42'
      />
    </svg>
  );
}
