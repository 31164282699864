import React, { useState, useEffect } from 'react';
import CreateModal from './CreateModal/index';
import CreateProgramModal from './CreateProgramModal/index';
import { CSVLink, CSVDownload } from "react-csv";
import './users.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Table from './Table/index';
import { db } from '../../../firebase';
import { RiPencilFill } from 'react-icons/ri';
import { IoMdTrash } from 'react-icons/io';
import { captureException } from '../../../utils/utility';

const defaultUserObject = {
  name: "",
  email: "",
  phoneNumber: "",
  temporaryPassword: "",
  homeTown: "",
  role: "",
  bio: "",
  companyName: false,
  fdmToken: "",
  contactOn: "",
  listedHorses: [],
  favourites: [],
  followers: [],
  following: [],
  savedSearches: [],
  onBoarded: false,
  seenTutorial: false,
  photoUrl: null,
  websiteURL: "",
  programUser: false,
  status: '',
  toggleCheckBox: false,
  uid: "",
  promoCode: "",
  foreverFree: false,
  subscription: {
    date: "",
    expiryDate: "",
    platform: "",
    product: "",
    receipt: "",
    type: "",
  },
  homeTownCoordinates: {
    lat: "",
    lng: "",
  }
}

const ManageUsers = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [createProgramModalShow, setCreateProgramModalShow] = useState(false);
  const [filterBy, setFilterBy] = useState("all");
  const [currentUser, setCurrentUser] = useState(null);
  const [tempPass, setTempPass] = useState(null);
  const [editForeverFree, setEditForeverFree] = useState(false)
  const [table, setTable] = useState({});
  const [filteredTable, setFilteredTable] = useState({});
  const [userObject, setUserObject] = useState(defaultUserObject)
  const [program, setProgram] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Role", key: "role" },
    { label: "Status", key: "status" },
    { label: "Home Town", key: "homeTown" },
    { label: "Home Town Latitude", key: "homeTownCoordinates.lat" },
    { label: "Home Town Longitude", key: "homeTownCoordinates.lng" },
    { label: "User ID", key: "uid" },
    { label: "Subscription Type", key: "subscription.type" },
    { label: "Subscription Platform", key: "subscription.platform" },
    { label: "Subscription Product", key: "subscription.product" },
    { label: "Admin", key: "isAdmin" },
    { label: "Program User", key: "programUser" },
    { label: "Program Start Date", key: "program.startDate.seconds" },
    { label: "Program End Date", key: "program.endDate.seconds" },
  ];

   const handleCloseCreateModal = () => {
    setTempPass(null)
    setCreateModalShow(false)
   }

   let tableColumns = {
    columns: [
      {
        label: 'EDIT/DELETE',
        field: 'edit',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'NAME',
        field: 'name',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'EMAIL',
        field: 'email',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'PHONE',
        field: 'phone',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'HOMETOWN',
        field: 'homeTown',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'SUBSCRIPTION',
        field: 'subscriptionType',
        sort: 'asc',
        width: 50,
      },

      {
        label: 'USER ROLE',
        field: 'role',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'STATUS',
        field: 'status',
        sort: 'asc',
        width: 50,
      },
    ],
  };
  //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //filter array and then set table state
   let tableRows = {
    rows: [{}],
  };

  const handleOpenEditModal = (doc) => {
    console.log("doc.data()", doc.data())
    setCurrentUser(doc);
    setEditModalShow(true);
  };
  const handleCloseEditModal = () => {
    setTempPass(null);
    setEditForeverFree(false)
    setProgram(null);
    setUserObject(defaultUserObject);
    setCurrentUser(null);
    setEditModalShow(false);
  };
  const handleOpenDeleteModal = (doc) => {
    setCurrentUser(doc);
    setDeleteModalShow(true);
  };

   const getUsers = async () => {
    try{
      const usersRef = db.collection('users');
      const snapshot = await usersRef.get();
      snapshot.forEach((doc) => {
        let user = {
          edit: (
            <article className='users-icon-group'>
              <div>
                <RiPencilFill
                  className='users-pencil'
                  onClick={() => handleOpenEditModal(doc)}
                />
              </div>
              <div>
                <IoMdTrash
                  className='users-pencil'
                  onClick={() => handleOpenDeleteModal(doc)}
                />
              </div>
            </article>
          ),
          name: doc.data().name ? doc.data().name : '',
          email: doc.data().email ? doc.data().email : '',
          phone: doc.data().phoneNumber ? doc.data().phoneNumber : '',
          homeTown: doc.data().homeTown ? doc.data().homeTown : '',
          subscriptionType: doc.data().subscription?.type
            ? doc.data().subscription.type
            : 'Free',
          subscription: doc.data().subscription
            ? doc.data().subscription
            : { type: 'Free' },
          programUser: doc.data()?.programUser,
          foreverFree: doc.data().foreverFree ? doc.data().foreverFree : false,
          isAdmin: doc.data()?.adminUser ? true : false,
          role: doc.data()?.role ? doc.data()?.role : 'User',
          status: doc.data()?.status ? doc.data()?.status : 'Active',
          uid: doc.data().uid,
          homeTownCoordinates: doc.data().homeTownCoordinates
            ? doc.data().homeTownCoordinates
            : { lat: '', lng: '' },
          program: doc.data().program
            ? doc.data().program
            : {
                imageUrl: null,
                startDate: { seconds: null },
                endDate: { seconds: null },
              },
        };
        tableRows.rows.push(user);
      });
      console.log("USERS", tableRows)
      //table rows var will have array of current objects with current uid if needed to match for deletion or edit
      //filter array and then set table state
      setTable({ ...tableColumns, ...tableRows });
      setFilteredTable({ ...tableColumns, ...tableRows });

    } catch (e) {
      captureException({message: 'Failed to get user data',e});
    }
  };
   
   useEffect(() => {
    setTimeout(() => {
      setSaveButton(true)
    }, 2000)
   }, [])

  return (
    <Container  className='col-12  align-items-start'>
      <Row className='users-top-row'>
        <Col xs={8}>
          <article className='users-left-article'>
            <section className='users-title-button-section'>
              <h1>Users</h1>
              <Button
                className='users-left-button'
                onClick={() => setCreateModalShow(true)}
              >
                ADD USER
              </Button>
            </section>
          </article>
          <Row  className="pl-5 w-100 align-items-center justify-content-start">
            <Col className="d-flex align-items-center text-center isClickable justify-content-center" sm={1}>
              {filterBy === "all" ? <h5 className="text-primary" onClick={()=>{setFilterBy("all")}}>ALL</h5> : <h5 className="text-black-50" onClick={()=>{setFilterBy("all")}}>ALL</h5> }
            </Col>
            <span style={{ marginHorizontal: '25px' }}>|</span>
            <Col className="d-flex align-items-center text-center isClickable justify-content-center" sm={3}>
              {filterBy === "eeUsers" ? <h5 className="text-primary" onClick={()=>{setFilterBy("eeUsers")}}>EQUINE EXCHANGE</h5> : <h5 className="text-black-50" onClick={()=>{setFilterBy("eeUsers")}}>EQUINE EXCHANGE</h5> }
            </Col>
            <span style={{ marginHorizontal: '25px' }}>|</span>
            <Col className="d-flex align-items-center text-center isClickable justify-content-center" sm={2}>
              {filterBy === "admin" ? <h5 className="text-primary" onClick={()=>{setFilterBy("admin")}}>ADMIN</h5> : <h5 className="text-black-50" onClick={()=>{setFilterBy("admin")}}>ADMIN</h5> }
            </Col>
            <span style={{ marginHorizontal: '25px' }}>|</span>
            <Col className="d-flex align-items-center text-center isClickable justify-content-center" sm={2}>
              {filterBy === "program" ? <h5 className="text-primary" onClick={()=>{setFilterBy("program")}}>PROGRAM</h5> : <h5 className="text-black-50" onClick={()=>{setFilterBy("program")}}>PROGRAM</h5> }
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
        </Col>
      </Row>
      <Row className='users-content'>
        <Table className='mb-0 pb-0'
          table={table}
          tableColumns={tableColumns}
          setTable={setTable}
          filteredTable={filteredTable}
          setFilteredTable={setFilteredTable}
          filterBy={filterBy}
          getUsers={getUsers}
          program={program}
          setProgram={setProgram}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          userObject={userObject}
          setUserObject={setUserObject}
          defaultUserObject={defaultUserObject}
          setCreateProgramModalShow={setCreateProgramModalShow}
          handleCloseEditModal={handleCloseEditModal}
          editModalShow={editModalShow}
          setEditModalShow={setEditModalShow}
          editForeverFree={editForeverFree}
          setEditForeverFree={setEditForeverFree}
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
        />
      </Row>
      <Row className="w-100 pr-5 d-flex justify-content-end">
        {saveButton && filteredTable && filteredTable.rows && headers &&
          <CSVLink
            data={filteredTable.rows.length ? filteredTable.rows : "Placeholder"}
            headers={headers}
            filename={filterBy === "all" ? "allEEusers.csv" : filterBy === "eeUsers" ? "EEusers.csv" : filterBy === "admin" ? "adminUsers.csv" : "programUsers.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            DOWNLOAD {filterBy === "all" ? "ALL" : filterBy === "eeUsers" ? "EE" : filterBy === "admin" ? "ADMIN" : "PROGRAM"} USERS
          </CSVLink>
        }
      </Row>
      <CreateModal
        show={createModalShow}
        onHide={() => handleCloseCreateModal()}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        userObject={userObject}
        getUsers={getUsers}
        setUserObject={setUserObject}
        defaultUserObject={defaultUserObject}
        setCreateProgramModalShow={setCreateProgramModalShow}
        tempPass={tempPass}
        setTempPass={setTempPass}
        editForeverFree={editForeverFree}
        setEditForeverFree={setEditForeverFree}
        backdrop="static"
      />
      <CreateProgramModal
        show={createProgramModalShow}
        onHide={() => setCreateProgramModalShow(false)}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        getUsers={getUsers}
        defaultUserObject={defaultUserObject}
        userObject={userObject}
        setUserObject={setUserObject}
        setEditModalShow={setEditModalShow}
        setCreateModalShow={setCreateModalShow}
        tempPass={tempPass}
        setTempPass={setTempPass}
        backdrop="static"
      />
    </Container>
  );
};

export default ManageUsers;
