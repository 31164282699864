import userEvent from '@testing-library/user-event';
import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { db, auth } from '../../firebase';
import { captureException } from '../../utils/utility';
import Spinner from 'react-bootstrap/Spinner';

export const Login = ({
  credentials,
  handleCredentials,
  setLoginOpen,
  setIsForgotPassword,
  setLoggedIn,
}) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    if(credentials.email === '')
      return;
    const checkAdmin = async () => {
      const usersRef = db.collection('users');
      const user = await usersRef.where('email', '==', credentials.email).get();
      user.forEach((doc) => {
        if (doc.data().email === credentials.email) {
          if (doc.data().role === 'Admin') {
            setIsAdmin(true);
          } else {
            setError('Must be an Admin to log in');
          }
        }
      });
    };

    checkAdmin();
  }

  const loginAdmin = async () => {
    if (isAdmin) {
      try {
        setError('');
        setIsLoading(true)
        await auth.signInWithEmailAndPassword(
          credentials.email,
          credentials.password
        );
        history.push('/dashboard');
        setLoginOpen(false);
        setLoggedIn(true);
      } catch (error) {
        setError('Failed to log in.');
        captureException({message: 'Failed to log', e: error });
      } finally {
        setIsLoading(false);
      }
    } 
  }

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  useEffect(() => {
    loginAdmin()
  }, [isAdmin])

  return (
    <div>
      
      {isLoading && 
        <div className="center-text"><Spinner animation="border" size="sm" /></div>
      }
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <label>Email address</label>
          <input
            type='email'
            className='form-control'
            placeholder='Enter email'
            name='email'
            onChange={handleCredentials}
            autoFocus
          />
        </div>

        <div className='form-group'>
          <label>Password</label>
          <input
            type='password'
            className='form-control'
            placeholder='Enter password'
            name='password'
            onChange={handleCredentials}
          />
        </div>
        <button type='submit' className='btn btn-primary btn-block'>
          Log In
        </button>
        {error !== '' && (
          <div>
            <br />
            <span style={{ color: 'red' }}>{`${error} `}</span>
          </div>
        )}
        <p className='forgot-password text-right'>
          <a onClick={handleForgotPassword} href='#'>
            Forgot password?
          </a>
        </p>
      </form>
    </div>
  );
};
