import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { IoMdArrowBack } from 'react-icons/io';
import { IoPlayCircleOutline } from 'react-icons/io5';
import { captureException } from '../../../../utils/utility';
import { storage } from '../../../../firebase';

import './stepTwo.css'

const OnboardStepTwo = (props) => {
 const [openImageModal, setOpenImageModal] = useState(null)

  
    const { closeStepOne, closeStepTwo, coachObject, setCoachObject, handleNextStep, handleBackStep, stepThree, onHide, show, setAddImageModalShow, imagePreview } = props

    const handleOpenImageModal = () => {
      setAddImageModalShow(true)
      closeStepTwo()
    }



  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="onboard-step-one-modal"
      centered
    >
      <Modal.Header closeButton className="align-items-center py-2">
        {/* <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
        Coaching Onboarding
        </Modal.Title> */}
        <IoMdArrowBack className='h4 text-secondary mb-0 mr-3 isClickable' onClick={() => handleBackStep()}/>
        <h4 className="text-center pb-1">Coaching Onboarding</h4>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
      <Row className="w-75 align-items-center justify-content-center">
          <hr className="w-25" /><h6 className="text-primary">STEP TWO</h6><hr className="w-25" />
      </Row>
      <Row className="w-75 mt-2 align-items-center justify-content-center ">
        <Col className=" w-100 d-flex flex-column justify-content-center align-items-center">
          <h5 className="text-primary">ADD PROFILE IMAGE</h5>
           <div className="d-flex align-items-center justify-content-center text-secondary isClickable overflow-hidden imageCircle" onClick={() => handleOpenImageModal()}>{imagePreview ? <img src={coachObject.profileImageUrl ? coachObject.profileImageUrl : imagePreview} alt={`Preview of user profile`} className="profilePreview"/> : "Click to add image" }</div>  
          
          <p className="text-primary text-center">Add an image you would like studentes to see. Make sure the photo is at least 500px wide for best results.</p>
        </Col>
      </Row>
      <hr className="w-75" />
      <Row className="w-75 mt-2 align-items-center justify-content-center ">
        <Col className=" w-100 d-flex flex-column justify-content-center align-items-center">
          <h5 className="text-primary">ADD VIDEO INTRODUCTION</h5>
          <div className="d-flex flex-column pt-4 align-items-center justify-content-center text-secondary isClickable videoBox" onClick={() => alert("add video")}>
            <IoPlayCircleOutline className="playIcon"/>
              Click to upload video
          </div>
          <input type="text" className="form-control text-left my-2 w-100" id="addSponsorName" placeholder="http://" value={coachObject.introductionVideoUrl} onChange={e => setCoachObject(prev => ({...prev, introductionVideoUrl: e.target.value}))}></input>
          <p className="text-primary text-center">Enter a video link or upload a video introduction for students.</p>
        </Col>
      </Row>
      <hr className="w-75" />
      <Row className="w-75 align-items-center justify-content-center">
        <textarea
          className="form-control text-left mb-2 w-100"
          // type="textarea"
          style={{resize: "none"}}
          rows={5}
          maxLength={250}
          resize={false}
          id="addCoachDescription" placeholder="Describe yourself (250 character limit)"
          value={coachObject.coachDescription}
          onChange={e => setCoachObject(prev => ({...prev, coachDescription: e.target.value}))}
          />
        {/* <input 
          mulitiLine={true}
          numberOfLines={5}
          maxLength={250}
          type="text"
          className="form-control text-center mb-2 w-100"
          id="addCoachDescription" placeholder="Phone Number"
          value={coachObject.coachDescription}
          onChange={e => setPhoneNumber(e.value)}>
        </input> */}
      </Row>
        <hr></hr>
      <Row className="w-100 align-items-center justify-content-center">

      
      </Row>

        {stepThree ? <div className="manageDropdownButton px-3 py-2 my-3" onClick={() => {handleNextStep()}}>Continue to Next Step</div> :
          <div className="manageDropdownButtonUnchanged px-3 py-2 my-3" >Continue to Next Step</div>}
        <h6>© EQUITRADE 2021</h6>
      </Modal.Body>
    </Modal>
  )
}

export default OnboardStepTwo;