import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { db } from '../../../../firebase';
import { RiPencilFill } from 'react-icons/ri';
import { IoMdTrash } from 'react-icons/io';
import Loader from "react-js-loader";
import { captureException } from '../../../../utils/utility';
const Table = () => {
  const [table, setTable] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let tableColumns = {
    columns: [
      {
        label: 'EVENT NAME',
        field: 'name',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'City',
        field: 'city',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Start Date',
        field: 'startDate',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'End DATE',
        field: 'endDate',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Type',
        field: 'type',
        sort: 'asc',
        width: 100,
      }
    ],
  };
  //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //filter array and then set table state
  let tableRows = {
    rows: [{}],
  };

  //{TODO: add loading status for data table load}
  const getEvents = async () => {
    try{
      setIsLoading(true);
      const usersHoreses = await db.collectionGroup('horses').get();
      console.log(usersHoreses.docs.length);
      let count = 0;
      await Promise.all(usersHoreses.docs.map((horse) => {
        if(horse.exists) {
          if(horse.data() && horse.data().events && horse.data().events.length > 0) {
            horse.data().events.forEach((event) => {
              let item = {
                edit: (
                  <div className='events-icon-group'>
                    <RiPencilFill className='events-pencil' />
                    <IoMdTrash />
                  </div>
                ),
                name: event.eventName,
                city: event.eventCity,
                startDate: event.eventStartDate ? event.eventStartDate : event.eventDate,
                endDate: event.eventEndDate ? event.eventEndDate : event.eventDate,
                type: event.type ? event.type : 'Admin',
                // status: event.status? event.status : null,
              };
              //table rows var will have array of current objects with current uid if needed to match for deletion or edit
              //filter array and then set table state
              tableRows.rows.push(item);
            });

          }
        }
        return tableRows.rows;
      }));
      setTable({ ...tableColumns, ...tableRows });

    } catch(error) {
      console.log(error);
      captureException({ message: 'Failed to show events data', e: error});
    } finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
     <>
      {/* { isLoading &&
        <Loader type="spinner-cub" bgColor={"#43BC93"} title={"spinner-circle"} size={100} />
      } */}
      <MDBDataTable
        entries={8}
        // entriesOptions={[8]}
        displayEntries={false}
        noBottomColumns
        fixed
        className='col-xl-10'
        data={table}
      />
     
    </>
  );
};

export default Table;
