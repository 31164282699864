import './events.css';
import { Container, Row } from 'react-bootstrap';
import Table from './Table/index';

const ManageEvents = () => {
  return (
    <Container className='events-page-container' className='col-12'>
      <Row className='events-content'>
        <Table className='col-xl-12' />
      </Row>
    </Container>
  );
};

export default ManageEvents;
