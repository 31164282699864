import React, { useState, useEffect, useRef } from 'react';
import { Modal, Container, Row, Col, Button, SplitButton } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { IoMdArrowBack } from 'react-icons/io';
import { IoTrashBin } from 'react-icons/io5';
import { BsTrashFill } from "react-icons/bs";
import { IoAddCircleOutline } from 'react-icons/io5';
import { db, auth} from '../../../../firebase';
import { captureException } from '../../../../utils/utility';

import "./stepThree.css"

const OnboardStepThree = (props) => {
    const [inPersonDisciplines, setInPersonDisciplines] = useState("")
    const [videoDisciplines, setVideoDisciplines] = useState("")
    const [discSelectedInPerson, setDiscSelectedInPerson] = useState({disciplines: "", rate: ""})
    const [discSelectedVideo, setDiscSelectedVideo] = useState({disciplines: "", rate: ""})
    const [inPerson, setInPerson] = useState(false)
    const [video, setVideo] = useState(false)
    const [disciplineRatesInPerson, setDisciplineRatesInPerson] = useState([])
    const [disciplineRatesVideo, setDisciplineRatesVideo] = useState([])


    const { closeStepOne, closeStepTwo, coachObject, setCoachObject, handleNextStep, handleBackStep, completeForm } = props



    const addOption = (item, rate) => {
        const selectItem = () => {
                if (rate === "inPerson"){ 
                    setDiscSelectedInPerson({discipline: item, rate: ""})
                } else {
                     setDiscSelectedVideo({discipline: item, rate: ""})
            }
        }

        return (
            <Dropdown.Item onClick={() => selectItem()}>{item}</Dropdown.Item>
        )
    }

    const handleRateChange = (e, rate) => {
        if(rate === "inPerson") {
            setDiscSelectedInPerson(prev => ({...prev, rate: e.target.value}))
        } else {
            setDiscSelectedVideo(prev => ({...prev, rate: e.target.value}))
        }
    }

    const handleAddInPersonRate = () => {
        setDisciplineRatesInPerson([...disciplineRatesInPerson, discSelectedInPerson])
        setCoachObject(prev => ({...prev, inPersonRates: [...coachObject.inPersonRates, discSelectedInPerson]}))
        setDiscSelectedInPerson({discipline: "", rate: ""})
    }
    const handleAddVideoRate = () => {
        setDisciplineRatesVideo([...disciplineRatesVideo, discSelectedVideo])
        setCoachObject(prev => ({...prev, videoRates: [...coachObject.videoRates, discSelectedVideo]}))
        setDiscSelectedVideo({discipline: "", rate: ""})
    }

    const handleRemoveRate = (item, rate) => {
        if (rate === 'inPerson') {
            let currentRates = [...disciplineRatesInPerson];
            let rateIndex = currentRates.findIndex(rate => rate.discipline === item.discipline)
            currentRates.splice(rateIndex, 1)
            setDisciplineRatesInPerson([...currentRates])
            setCoachObject(prev => ({...prev, inPersonRates: [...currentRates]}))
        } else {
            let currentRates = [...disciplineRatesVideo];
            let rateIndex = currentRates.findIndex(rate => rate.discipline === item.discipline)
            currentRates.splice(rateIndex, 1)
            setDisciplineRatesVideo([...currentRates])
            setCoachObject(prev => ({...prev, videoRates: [...currentRates]}))
        }
    }

    const checkInPerson = () => {
        // console.log("check", discSelectedInPerson)
        if (discSelectedInPerson.discipline && discSelectedInPerson.rate) {
            setInPerson(true)
        } else {
            setInPerson(false)
        }
    }
    const checkVideo = () => {
        // console.log("check", discSelectedVideo)
        if (discSelectedVideo.discipline && discSelectedVideo.rate) {
            setVideo(true)
        } else {
            setVideo(false)
        }
    }

    const RateLineItem = ({item, rate}) => {
        // console.log(item)
        return (
            <Row>
                <div className="rateItem disc">
                    <h6 className="m-0">{item.discipline}</h6>
                </div>
                <div className="rateItem rate">
                    <h6 className="m-0">${item.rate}</h6>
                </div>
                <div className="rateItem trash">
                    <BsTrashFill className="trashIcon isClickable" onClick={() => handleRemoveRate(item, rate)} />
                </div>
            </Row>
        )
    }

    const setOptions = (data) => {
        console.log("data", data)
        setInPersonDisciplines(data.get('disciplines').map(item => addOption(item.text, "inPerson")));
        setVideoDisciplines(data.get('disciplines').map(item => addOption(item.text, "video")));
    }

    useEffect(() => {
        const dropdownListRef = db.collection('my-horse-listing-dropdowns').doc('jXE8l6PiewX1VCfDoaKB');
        dropdownListRef.get()
        .then(data => setOptions(data))
        .catch(error => console.log(error));
    }, [])

    useEffect(() => {
        checkInPerson()
        checkVideo()
    }, [discSelectedInPerson, discSelectedVideo])

  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="onboard-step-one-modal"
      centered
    >
      <Modal.Header closeButton className="align-items-center py-2">
        {/* <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
        Coaching Onboarding
        </Modal.Title> */}
        <IoMdArrowBack className='h4 text-secondary mb-0 mr-3 isClickable' onClick={() => handleBackStep()}/>
        <h4 className="text-center pb-1">Coaching Onboarding</h4>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
      <Row className="w-75 align-items-center justify-content-center">
          <hr className="w-25" /><h6 className="text-primary">STEP THREE</h6><hr className="w-25" />
      </Row>
      <Row className="w-100 mt-2 align-items-center justify-content-center ">
        <Col className=" w-100 d-flex flex-column justify-content-center align-items-center">
          {/* <h5 className="text-primary">SET RATES AND SELECT DAYS</h5> */}
          <p className="text-primary text-center p-0 m-0">Add requested rate for each discipline of in-person or video coaching.</p>
        </Col>
      </Row>
      <hr className="w-75" />
      <Row className="w-100 mt-2 align-items-center justify-content-center ">
        <Col className=" w-100 d-flex flex-column justify-content-center align-items-center">
            <h5 className="text-primary m-0">SET IN-PERSON RATE</h5>
            <p className="text-primary text-center mt-0 mb-2 p-0">*Rate is per coaching session</p>
            <Row className="mb-3 d-flex align-items-center justify-content-center">
                <div className="selectedDiscipline">
                    <Dropdown className="mr-3">
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {inPersonDisciplines && inPersonDisciplines.map(disc => disc)}
                        </Dropdown.Menu>
                    </Dropdown>
                    {discSelectedInPerson.discipline ? discSelectedInPerson.discipline : "Select Discipline"}
                </div>
                {/* <input type="text" className="form-control w-50" id="addSponsorName" placeholder="Select Discipline" value={discSelectedInPerson.discipline}></input> */}
                <input type="text" min="0" step=".01" className="form-control ml-3 w-25" id="addSponsorName" placeholder="$" value={discSelectedInPerson.rate} onChange={e => handleRateChange(e, "inPerson")}></input>
                {inPerson ? <IoAddCircleOutline className="plusIcon isClickable" onClick={() => {handleAddInPersonRate()}} /> : <IoAddCircleOutline className="plusIconFalse" /> }
            </Row>
            {disciplineRatesInPerson && disciplineRatesInPerson.map(item => {return <RateLineItem item={item} rate={"inPerson"} />})}
        </Col>
      </Row>
      <hr className="w-75" />
      <Row className="w-100 mt-2 align-items-center justify-content-center ">
        <Col className=" w-100 d-flex flex-column justify-content-center align-items-center">
            <h5 className="text-primary m-0">SET VIDEO RATE</h5>
            <p className="text-primary text-center mt-0 mb-2 p-0">*Rate is per coaching video</p>
            <Row className="mb-3 d-flex align-items-center justify-content-center">
                <div className="selectedDiscipline">
                    <Dropdown className="mr-3">
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {videoDisciplines && videoDisciplines.map(disc => disc)}
                        </Dropdown.Menu>
                    </Dropdown>
                    {discSelectedVideo.discipline ? discSelectedVideo.discipline : "Select Discipline"}
                </div>
                {/* <input type="text" className="form-control w-50" id="addSponsorName" placeholder="Select Discipline" value={discSelectedVideo.discipline}></input> */}
                <input type="text" min="0" step=".01" className="form-control ml-3 w-25" id="addSponsorName" placeholder="$" value={discSelectedVideo.rate} onChange={e => handleRateChange(e, "video")}></input>
                {video ? <IoAddCircleOutline className="plusIcon isClickable" onClick={() => {handleAddVideoRate()}} /> : <IoAddCircleOutline className="plusIconFalse" /> }
            </Row>
            {disciplineRatesVideo && disciplineRatesVideo.map(item => {return <RateLineItem item={item} rate={"video"} />})}
        </Col>
      </Row>
      <hr className="w-75" />
      <Row className="w-100 mt-2 align-items-center justify-content-center ">
        <Col className=" w-100 d-flex flex-column justify-content-center align-items-center">
            <h5 className="text-primary">SET DAYS AVAILABLE</h5>
            <Row className="w-100 align-items-center justify-content-between mt-2">
                    {coachObject.daysAvailable.sunday ? <div className="btn btn-primary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, sunday: !coachObject.daysAvailable.sunday }}))}>Sun</div> :
                        <div className="btn btn-outline-secondary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, sunday: !coachObject.daysAvailable.sunday }}))}>Sun</div>}
                    {coachObject.daysAvailable.monday ? <div className="btn btn-primary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, monday: !coachObject.daysAvailable.monday }}))}>Mon</div> :
                        <div className="btn btn-outline-secondary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, monday: !coachObject.daysAvailable.monday }}))}>Mon</div>}
                    {coachObject.daysAvailable.tuesday ? <div className="btn btn-primary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, tuesday: !coachObject.daysAvailable.tuesday }}))}>Tue</div> :
                        <div className="btn btn-outline-secondary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, tuesday: !coachObject.daysAvailable.tuesday }}))}>Tue</div>}
                    {coachObject.daysAvailable.wednesday ? <div className="btn btn-primary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, wednesday: !coachObject.daysAvailable.wednesday }}))}>Wed</div> :
                        <div className="btn btn-outline-secondary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, wednesday: !coachObject.daysAvailable.wednesday }}))}>Wed</div>}
                    {coachObject.daysAvailable.thursday ? <div className="btn btn-primary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, thursday: !coachObject.daysAvailable.thursday }}))}>Thu</div> :
                        <div className="btn btn-outline-secondary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, thursday: !coachObject.daysAvailable.thursday }}))}>Thu</div>}
                    {coachObject.daysAvailable.friday ? <div className="btn btn-primary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, friday: !coachObject.daysAvailable.friday }}))}>Fri</div> :
                        <div className="btn btn-outline-secondary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, friday: !coachObject.daysAvailable.friday }}))}>Fri</div>}
                    {coachObject.daysAvailable.saturday ? <div className="btn btn-primary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, saturday: !coachObject.daysAvailable.saturday }}))}>Sat</div> :
                        <div className="btn btn-outline-secondary" onClick={() => setCoachObject(prev => ({...prev, daysAvailable: {...prev.daysAvailable, saturday: !coachObject.daysAvailable.saturday }}))}>Sat</div>}
            </Row>
            <p className="text-primary text-center">Select the days you will be available for coaching each week.</p>
        </Col>
      </Row>

        <hr></hr>
      <Row className="w-100 align-items-center justify-content-center">

      
      </Row>

        {completeForm ? <div className="manageDropdownButton px-3 py-2 my-3" onClick={() => {handleNextStep()}}>Complete Onboarding</div> :
          <div className="manageDropdownButtonUnchanged px-3 py-2 my-3" >Complete Onboarding</div>}
        <h6>© EQUITRADE 2021</h6>
      </Modal.Body>
    </Modal>
  )
}

export default OnboardStepThree;