import React from "react";

export const AppleAppStore = ({ height }) => {
    return (
		<svg 
			height={height}
			viewBox="0 0 119.664 40"
			fill="none"
            enable-background="new 0 0 119.664 40"
			xmlns="http://www.w3.org/2000/svg">
            <title>Download_on_the_App_Store_Badge_US-UK_RGB_blk_4SVG_092917</title>
            <g>
                <g>
                    <g>
                        <path fill="#A6A6A6" d="M110.135,0H9.535C9.168,0,8.806,0,8.44,0.002C8.134,0.004,7.83,0.01,7.521,0.015
                            C6.85,0.022,6.18,0.082,5.517,0.191C4.855,0.304,4.215,0.515,3.616,0.818C3.019,1.125,2.472,1.522,1.998,1.997
                            C1.521,2.471,1.123,3.018,0.819,3.618C0.516,4.217,0.305,4.859,0.194,5.521C0.083,6.183,0.023,6.853,0.015,7.523
                            C0.006,7.83,0.005,8.138,0,8.444v23.114c0.005,0.311,0.006,0.611,0.015,0.922c0.008,0.671,0.068,1.34,0.179,2.002
                            c0.11,0.663,0.321,1.305,0.625,1.904c0.303,0.598,0.701,1.144,1.178,1.614c0.473,0.478,1.02,0.875,1.619,1.179
                            c0.599,0.305,1.239,0.518,1.901,0.631c0.663,0.109,1.333,0.168,2.004,0.177c0.309,0.007,0.613,0.011,0.919,0.011
                            C8.806,40,9.168,40,9.535,40h100.6c0.359,0,0.725,0,1.084-0.002c0.305,0,0.617-0.004,0.922-0.011c0.67-0.009,1.339-0.067,2-0.177
                            c0.664-0.115,1.307-0.327,1.908-0.631c0.599-0.304,1.145-0.701,1.617-1.179c0.477-0.473,0.875-1.018,1.182-1.614
                            c0.302-0.6,0.511-1.242,0.619-1.904c0.111-0.662,0.174-1.331,0.186-2.002c0.004-0.311,0.004-0.611,0.004-0.922
                            c0.008-0.363,0.008-0.725,0.008-1.094V9.536c0-0.366,0-0.729-0.008-1.092c0-0.307,0-0.614-0.004-0.921
                            c-0.012-0.671-0.074-1.34-0.186-2.002c-0.109-0.662-0.317-1.304-0.619-1.903c-0.617-1.203-1.596-2.182-2.799-2.8
                            c-0.602-0.303-1.244-0.514-1.908-0.627c-0.661-0.11-1.33-0.169-2-0.177c-0.305-0.005-0.617-0.011-0.922-0.013
                            C110.859,0,110.494,0,110.135,0L110.135,0z"/>
                        <path fill="#000" d="M8.445,39.125c-0.305,0-0.602-0.004-0.904-0.011c-0.626-0.009-1.251-0.063-1.869-0.163
                            c-0.576-0.1-1.135-0.284-1.657-0.548c-0.517-0.262-0.989-0.604-1.397-1.017c-0.414-0.406-0.759-0.879-1.021-1.396
                            c-0.265-0.521-0.448-1.08-0.543-1.657c-0.103-0.62-0.159-1.247-0.167-1.875c-0.006-0.211-0.015-0.913-0.015-0.913V8.444
                            c0,0,0.009-0.691,0.015-0.895c0.008-0.627,0.063-1.253,0.166-1.872c0.095-0.579,0.279-1.139,0.543-1.662
                            C1.857,3.498,2.2,3.026,2.612,2.618C3.023,2.206,3.496,1.86,4.014,1.595c0.521-0.263,1.078-0.446,1.653-0.544
                            c0.62-0.102,1.247-0.156,1.875-0.164l0.902-0.012h102.769l0.913,0.013c0.623,0.007,1.244,0.062,1.858,0.163
                            c0.581,0.099,1.144,0.283,1.671,0.548c1.037,0.535,1.883,1.381,2.415,2.42c0.261,0.52,0.44,1.076,0.535,1.649
                            c0.104,0.624,0.162,1.255,0.174,1.887c0.003,0.283,0.003,0.587,0.003,0.89c0.008,0.375,0.008,0.732,0.008,1.092v20.929
                            c0,0.363,0,0.718-0.008,1.075c0,0.325,0,0.623-0.004,0.93c-0.012,0.621-0.068,1.241-0.171,1.854
                            c-0.093,0.581-0.275,1.145-0.54,1.67c-0.264,0.513-0.606,0.979-1.016,1.386c-0.408,0.414-0.881,0.759-1.399,1.022
                            c-0.525,0.267-1.087,0.451-1.668,0.55c-0.618,0.102-1.243,0.155-1.869,0.163c-0.293,0.007-0.6,0.011-0.897,0.011l-1.084,0.002
                            L8.445,39.125z"/>
                    </g>
                    <g id="_Group_">
                        <g id="_Group_2">
                            <g id="_Group_3">
                                <path id="_Path_" fill="#FFFFFF" d="M24.769,20.301c0.021-1.697,0.911-3.264,2.356-4.152c-0.916-1.308-2.395-2.107-3.991-2.157
                                    c-1.679-0.177-3.307,1.004-4.163,1.004c-0.872,0-2.189-0.987-3.608-0.958c-1.867,0.061-3.564,1.096-4.473,2.728
                                    c-1.934,3.348-0.492,8.27,1.361,10.977c0.927,1.324,2.01,2.805,3.428,2.753c1.387-0.058,1.905-0.885,3.58-0.885
                                    c1.659,0,2.145,0.885,3.591,0.851c1.488-0.023,2.426-1.33,3.321-2.668c0.666-0.945,1.178-1.988,1.518-3.094
                                    C25.92,23.952,24.771,22.221,24.769,20.301z"/>
                                <path id="_Path_2" fill="#FFFFFF" d="M22.037,12.211c0.812-0.974,1.211-2.227,1.115-3.491c-1.24,0.13-2.385,0.723-3.208,1.66
                                    c-0.813,0.924-1.224,2.134-1.144,3.361C20.057,13.754,21.25,13.19,22.037,12.211z"/>
                            </g>
                        </g>
                        <g>
                            <path fill="#FFFFFF" d="M42.302,27.14h-4.733l-1.137,3.356h-2.005l4.483-12.418h2.083l4.483,12.418h-2.039L42.302,27.14z
                                M38.059,25.591h3.752l-1.85-5.447H39.91L38.059,25.591z"/>
                            <path fill="#FFFFFF" d="M55.16,25.97c0,2.813-1.506,4.621-3.778,4.621c-1.175,0.062-2.281-0.554-2.849-1.584H48.49v4.484h-1.858
                                V21.442h1.799v1.506h0.034c0.593-1.024,1.701-1.639,2.883-1.601C53.645,21.348,55.16,23.164,55.16,25.97z M53.25,25.97
                                c0-1.833-0.947-3.038-2.393-3.038c-1.42,0-2.375,1.23-2.375,3.038c0,1.824,0.955,3.046,2.375,3.046
                                C52.302,29.016,53.25,27.819,53.25,25.97z"/>
                            <path fill="#FFFFFF" d="M65.125,25.97c0,2.813-1.506,4.621-3.779,4.621c-1.174,0.062-2.281-0.554-2.848-1.584h-0.043v4.484
                                h-1.858V21.442h1.799v1.506h0.034c0.593-1.024,1.7-1.639,2.883-1.601C63.609,21.348,65.125,23.164,65.125,25.97z M63.215,25.97
                                c0-1.833-0.947-3.038-2.393-3.038c-1.42,0-2.375,1.23-2.375,3.038c0,1.824,0.955,3.046,2.375,3.046
                                C62.268,29.016,63.215,27.819,63.215,25.97L63.215,25.97z"/>
                            <path fill="#FFFFFF" d="M71.711,27.036c0.137,1.231,1.334,2.04,2.969,2.04c1.566,0,2.693-0.809,2.693-1.919
                                c0-0.964-0.68-1.541-2.289-1.937l-1.609-0.388c-2.281-0.551-3.34-1.617-3.34-3.348c0-2.143,1.867-3.614,4.52-3.614
                                c2.623,0,4.422,1.472,4.482,3.614h-1.875c-0.113-1.239-1.137-1.987-2.635-1.987c-1.496,0-2.521,0.757-2.521,1.858
                                c0,0.878,0.654,1.395,2.256,1.79l1.367,0.336c2.549,0.603,3.607,1.626,3.607,3.442c0,2.323-1.852,3.778-4.795,3.778
                                c-2.754,0-4.613-1.421-4.732-3.667H71.711z"/>
                            <path fill="#FFFFFF" d="M83.346,19.3v2.143h1.723v1.472h-1.723v4.991c0,0.775,0.346,1.137,1.102,1.137
                                c0.205-0.003,0.409-0.019,0.611-0.043v1.463c-0.34,0.063-0.686,0.093-1.031,0.086c-1.834,0-2.549-0.688-2.549-2.444v-5.189
                                h-1.316v-1.472h1.316V19.3H83.346z"/>
                            <path fill="#FFFFFF" d="M86.064,25.97c0-2.849,1.678-4.639,4.295-4.639c2.625,0,4.295,1.79,4.295,4.639
                                c0,2.856-1.662,4.639-4.295,4.639S86.064,28.826,86.064,25.97z M92.76,25.97c0-1.954-0.895-3.107-2.4-3.107
                                s-2.4,1.162-2.4,3.107c0,1.962,0.895,3.106,2.4,3.106S92.76,27.932,92.76,25.97L92.76,25.97z"/>
                            <path fill="#FFFFFF" d="M96.186,21.442h1.773v1.541h0.043c0.246-0.992,1.156-1.675,2.178-1.636
                                c0.214-0.001,0.428,0.022,0.637,0.069v1.738c-0.271-0.083-0.553-0.12-0.836-0.112c-1.033-0.042-1.904,0.762-1.946,1.795
                                c-0.004,0.096-0.001,0.192,0.011,0.288v5.37h-1.859V21.442z"/>
                            <path fill="#FFFFFF" d="M109.385,27.837c-0.25,1.644-1.852,2.771-3.898,2.771c-2.635,0-4.27-1.765-4.27-4.596
                                c0-2.84,1.645-4.682,4.191-4.682c2.504,0,4.08,1.721,4.08,4.466v0.637h-6.395v0.112c-0.115,1.298,0.844,2.442,2.141,2.557
                                c0.098,0.009,0.196,0.011,0.295,0.008c0.901,0.085,1.752-0.434,2.09-1.273H109.385z M103.102,25.135h4.527
                                c0.066-1.201-0.853-2.229-2.053-2.295c-0.057-0.003-0.112-0.004-0.168-0.003c-1.266-0.008-2.299,1.013-2.307,2.278
                                C103.102,25.121,103.102,25.129,103.102,25.135z"/>
                        </g>
                    </g>
                </g>
                <g id="_Group_4">
                    <g>
                        <path fill="#FFFFFF" d="M37.826,8.731c1.454-0.104,2.717,0.99,2.822,2.444c0.013,0.174,0.008,0.348-0.014,0.521
                            c0,1.906-1.03,3.002-2.808,3.002h-2.155V8.731H37.826z M36.598,13.854h1.125c1.034,0.062,1.923-0.727,1.984-1.761
                            c0.008-0.128,0.002-0.258-0.017-0.385c0.141-1.029-0.579-1.978-1.608-2.119c-0.119-0.017-0.24-0.021-0.36-0.015h-1.125V13.854z"
                            />
                        <path fill="#FFFFFF" d="M41.681,12.444c-0.112-1.173,0.748-2.214,1.92-2.327s2.214,0.748,2.327,1.92
                            c0.013,0.135,0.013,0.271,0,0.406c0.114,1.173-0.744,2.216-1.917,2.33c-1.173,0.114-2.216-0.744-2.331-1.917
                            C41.667,12.72,41.667,12.582,41.681,12.444z M45.014,12.444c0-0.976-0.438-1.547-1.208-1.547c-0.772,0-1.207,0.571-1.207,1.547
                            c0,0.984,0.435,1.55,1.207,1.55C44.575,13.995,45.014,13.424,45.014,12.444L45.014,12.444z"/>
                        <path fill="#FFFFFF" d="M51.573,14.698h-0.922l-0.931-3.316h-0.07l-0.927,3.316h-0.913l-1.241-4.503h0.901l0.807,3.436h0.066
                            l0.926-3.436h0.853l0.926,3.436h0.07l0.803-3.436h0.889L51.573,14.698z"/>
                        <path fill="#FFFFFF" d="M53.854,10.195h0.855v0.715h0.066c0.231-0.527,0.771-0.849,1.344-0.802
                            c0.807-0.061,1.51,0.544,1.57,1.351c0.008,0.108,0.004,0.217-0.012,0.324v2.915h-0.889v-2.692c0-0.724-0.314-1.083-0.972-1.083
                            c-0.57-0.026-1.053,0.414-1.08,0.984c-0.002,0.052,0,0.105,0.005,0.157v2.634h-0.889V10.195z"/>
                        <path fill="#FFFFFF" d="M59.094,8.437h0.889v6.261h-0.889V8.437z"/>
                        <path fill="#FFFFFF" d="M61.218,12.444c-0.112-1.173,0.748-2.215,1.921-2.327c1.173-0.112,2.215,0.748,2.326,1.92
                            c0.014,0.135,0.014,0.271,0,0.406c0.115,1.173-0.744,2.216-1.916,2.33c-1.174,0.114-2.217-0.744-2.331-1.917
                            C61.205,12.72,61.205,12.582,61.218,12.444z M64.551,12.444c0-0.976-0.438-1.547-1.208-1.547c-0.772,0-1.207,0.571-1.207,1.547
                            c0,0.984,0.435,1.55,1.207,1.55C64.112,13.995,64.551,13.424,64.551,12.444z"/>
                        <path fill="#FFFFFF" d="M66.4,13.424c0-0.811,0.604-1.278,1.676-1.344l1.219-0.07v-0.389c0-0.476-0.314-0.744-0.922-0.744
                            c-0.496,0-0.84,0.182-0.938,0.5h-0.861c0.092-0.773,0.818-1.27,1.84-1.27c1.129,0,1.766,0.562,1.766,1.513v3.077h-0.855v-0.633
                            h-0.07c-0.29,0.462-0.807,0.732-1.352,0.707c-0.748,0.078-1.416-0.465-1.494-1.212C66.403,13.515,66.4,13.47,66.4,13.424z
                            M69.295,13.04v-0.376l-1.1,0.07c-0.619,0.042-0.9,0.252-0.9,0.649c0,0.405,0.352,0.641,0.834,0.641
                            c0.584,0.059,1.104-0.366,1.164-0.949C69.293,13.063,69.295,13.051,69.295,13.04z"/>
                        <path fill="#FFFFFF" d="M71.348,12.444c0-1.423,0.732-2.324,1.869-2.324c0.575-0.026,1.113,0.281,1.381,0.79h0.066V8.437h0.889
                            v6.261h-0.852v-0.711h-0.07c-0.288,0.504-0.833,0.807-1.414,0.786C72.072,14.772,71.348,13.871,71.348,12.444z M72.266,12.444
                            c0,0.955,0.451,1.53,1.203,1.53c0.75,0,1.213-0.583,1.213-1.526c0-0.938-0.469-1.53-1.213-1.53
                            C72.721,10.918,72.266,11.497,72.266,12.444L72.266,12.444z"/>
                        <path fill="#FFFFFF" d="M79.23,12.444c-0.113-1.173,0.747-2.214,1.92-2.327s2.215,0.748,2.326,1.92
                            c0.014,0.135,0.014,0.271,0,0.406c0.115,1.173-0.744,2.216-1.916,2.33c-1.173,0.114-2.217-0.744-2.33-1.917
                            C79.217,12.72,79.217,12.582,79.23,12.444z M82.563,12.444c0-0.976-0.438-1.547-1.207-1.547c-0.773,0-1.207,0.571-1.207,1.547
                            c0,0.984,0.434,1.55,1.207,1.55C82.125,13.995,82.563,13.424,82.563,12.444z"/>
                        <path fill="#FFFFFF" d="M84.67,10.195h0.855v0.715h0.066c0.23-0.527,0.77-0.849,1.344-0.802c0.807-0.061,1.51,0.544,1.57,1.351
                            c0.008,0.108,0.004,0.217-0.012,0.324v2.915h-0.889v-2.692c0-0.724-0.314-1.083-0.973-1.083c-0.569-0.026-1.053,0.414-1.08,0.984
                            c-0.002,0.052,0,0.105,0.006,0.157v2.634H84.67V10.195z"/>
                        <path fill="#FFFFFF" d="M93.516,9.074v1.142h0.975v0.749h-0.975v2.315c0,0.472,0.193,0.678,0.637,0.678
                            c0.113,0,0.227-0.007,0.338-0.021v0.74c-0.159,0.029-0.32,0.044-0.482,0.045c-0.988,0-1.383-0.348-1.383-1.216v-2.543H91.91
                            v-0.749h0.715V9.074H93.516z"/>
                        <path fill="#FFFFFF" d="M95.705,8.437h0.881v2.481h0.07c0.242-0.531,0.791-0.854,1.373-0.807c0.817-0.044,1.517,0.583,1.562,1.4
                            c0.005,0.093,0.001,0.186-0.011,0.278v2.907h-0.891V12.01c0-0.719-0.334-1.083-0.963-1.083c-0.578-0.047-1.086,0.383-1.134,0.962
                            c-0.005,0.06-0.005,0.12,0.001,0.179v2.63h-0.889V8.437z"/>
                        <path fill="#FFFFFF" d="M104.762,13.482c-0.25,0.849-1.072,1.398-1.951,1.303c-1.13,0.03-2.069-0.861-2.1-1.991
                            c-0.002-0.111,0.004-0.223,0.02-0.333c-0.152-1.137,0.646-2.182,1.782-2.334c0.097-0.013,0.195-0.019,0.294-0.018
                            c1.252,0,2.008,0.856,2.008,2.27v0.31h-3.18v0.05c-0.055,0.655,0.432,1.231,1.086,1.286c0.038,0.003,0.076,0.005,0.113,0.004
                            c0.436,0.052,0.858-0.164,1.072-0.546H104.762z M101.635,12.031h2.275c0.044-0.598-0.405-1.12-1.004-1.164
                            c-0.035-0.003-0.07-0.004-0.105-0.003c-0.635-0.008-1.158,0.501-1.166,1.137C101.635,12.011,101.635,12.021,101.635,12.031
                            L101.635,12.031z"/>
                    </g>
                </g>
            </g>
		</svg>
	)
}
