import React from 'react';
import { Link, useHistory } from "react-router-dom"
import { COLOR_GREENISH } from '../../utils/constants';
import styles from './styles'
import logo from '../../res/logo.png'
import { Image } from "react-bootstrap"

export default function PrivacyPolicy() {

const termsLink = <Link to="/terms-of-service" target={'blank'} style={{display: 'inline-block'}}>
                    <p style={{margin: 0}}>Terms of Service</p>
                  </Link>


  return (
    <>
      <h1 
        style={{ 
            color: COLOR_GREENISH, 
            textAlign: 'center', 
            marginTop: 50}}
       >
        EQUINE EXCHANGE
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          paddingLeft: 25,
          paddingRight: 25,
          justifyContent: 'center',
          background: '#fff',
          flexDirection: 'column',
        }}
      >
       
        <Image src={logo} rounded width='200'
                                height='200'
                                className="d-inline-block align-top"/>

        <p style={styles.pageTitle}>PRIVACY POLICY</p>
        
        <p style={styles.text}>
            Equine Exchange is committed to respecting your privacy.  This privacy policy ("Policy") forms a part of the {termsLink} and describes how Equine Exchange, LLC ("Equine Exchange ", "we", "us" or "our") collects, protects and uses the personally identifiable information ("Personal Information") you ("User", "you" or "your") may provide using, or is otherwise available to or through, the Equine Exchange mobile application and any related sites (“Mobile App”) and any related products or services (collectively, "Services"). It also describes the options available to you regarding our use of your personal information and how you can access and update this information. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage, including, without limitation, those of other users of the Mobile App and/or third parties accessible through the Mobile App.
        </p>
        <p style={styles.textBold}>
            BY CLICKING THE “I ACCEPT” BUTTON OR BY ACCESSING OR USING THE MOBILE APP OR ANY SERVICES, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE REVIEWED, UNDERSTOOD AND AGREED TO ALL OF THE TERMS AND CONDITIONS OF THIS POLICY.  IF YOU DO NOT AGREE TO BE BOUND BY ALL OF THE TERMS OF THIS PRIVACY POLICY, CLICK THE “DECLINE” BUTTON AND DO NOT FURTHER ACCESS OR USE THIS MOBILE APP OR ANY SERVICES.BY CLICKING THE "I ACCEPT" BUTTON OR BY ACCESSING OR USING THE TEST DATA, YOU FURTHER INDICATE THAT YOU INTEND YOUR ACT TO SERVE AS YOUR SIGNATURE TO THIS AGREEMENT AND TO HAVE THE SAME FORCE AND EFFECT AS THE USE OF A MANUAL SIGNATURE, AND IF YOU ARE ACCESSING OR USING THE MOBILE APP OR ANY SERVICES IN YOUR CAPACITY AS AN EMPLOYEE OR AGENT OF A BUSINESS ENTITY, YOU WARRANT THAT YOU HAVE ACTUAL AUTHORITY TO LEGALLY BIND SUCH ENTITY TO THIS POLICY
        </p>
        <p style={styles.textBold}>
            Collection of Personal Information
        </p>
        <p style={styles.text}>
            We receive and store any information you provide to us when you publish content, fill any online forms in the Mobile App and/or transaction any business or receive any Services. You can choose not  to  provide  us  with  certain  information,  but  such  information  may  be  needed  to  take  full advantage of the Mobile App, its features and functionality.
        </p>
        <p style={styles.textBold}>
            Collection of Non-Personal Information
        </p>
        <p style={styles.text}>
            When  you  open  the  Mobile  App  our  servers  automatically  record  information  that  your  device sends. This information may include information such as your device's IP address and location, device name and version, operating system type and version, language preferences, information you search for in our Mobile App, access times and dates, and other data and statistics.
        </p>
        <p style={styles.textBold}>
            Use of Collected Information
        </p>
        <p style={styles.text}>
            Any of the information we collect from you may be used to improve our Mobile App; improve customer service and respond to queries and emails of our customers; run and operate our Mobile App and Services; and to document and verify any transactions that occur on or via the Mobile App. Non-personal information collected is used to identify potential cases of abuse and establish statistical information regarding the Mobile App, its traffic and usage. This statistical information is  not  otherwise  aggregated  in  such  a  way  that  would  identify  you  or  any  particular  user  of  the Mobile App or the Services.   We may also disclose any information as part of a legal disclosure (see below – Legal Disclosure).
        </p>
        <p style={styles.textBold}>
            Children
        </p>
        <p style={styles.text}>
            The  Mobile  App  is  not  directed  to  children  under  13  years  of  age  (“Children”),  and  we  do  not knowingly collect any personal information from Children. If you are under the age of 13, please do not use this Mobile App and do not submit any personal information through it or our Services. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their Children never to provide personal information through our Mobile App or Service without their permission. If you have reason to believe that a child under the age of 13 has provided personal information to us through our Mobile App or a related service, please contact us.
        </p>
        <p style={styles.textBold}>
            Information Security
        </p>
        <p style={styles.text}>
            We secure information you provide to us on computer servers in a controlled, secure environment, protected  from  unauthorized  access,  use,  or  disclosure.  We  maintain  reasonable  administrative, technical,  and  physical  safeguards  in  an  effort  to  protect  against  unauthorized  access,  use, modification,  and  disclosure  of  personal  information  in  our  control  and  custody.  However,  the security and privacy of data transmitted over the Internet or wireless network cannot be guaranteed. Therefore, while we strive to protect your personal information, you acknowledge and agree that (a) there are security and privacy limitations of the Internet, including with service providers and carriers,  which  are  beyond  our  control;  (b)  the  security,  integrity,  and  privacy  of  any  and  all information and data exchanged between you and our Mobile App cannot be guaranteed; and (c) any  such  information  and  data  may  be  viewed  or  tampered  with  despite  our  reasonable  efforts, including by a third party during transmission.
        </p>
        <p style={styles.textBold}>
            Data Breach
        </p>
        <p style={styles.text}>
            In the event we become aware that the security of the Mobile App has been compromised or users’ Personal Information has been disclosed in an unauthorized manner, including, but not limited to, via  security  attacks  or  fraud,  we  reserve  the  right  to  take  reasonably  appropriate  measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify  affected  individuals  if  we  believe  that  there  is  a  reasonable  risk  of  harm  to  the  user  as  a result of the breach or if notice is otherwise required by law. When we do, we will endeavor to contact you, including, but not limited to, by sending you an email.
        </p>
        <p style={styles.textBold}>
            Legal Disclosure
        </p>
        <p style={styles.text}>
            We will also disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that  disclosure  is  necessary  to  protect  our  rights,  protect  your  safety  or  the  safety  of  others, investigate fraud, or respond to a government request.
        </p>
        <p style={styles.textBold}>
            Changes and Amendments
        </p>
        <p style={styles.text}>
            We reserve the right to update and/or modify this Policy relating to the Mobile App and/or Services at any time, effective upon posting of an updated version of this Policy in the Mobile App. When we do we will revise the updated date at the bottom of this page. Continued use of the Mobile App and/or  Services  after  any  such  changes  shall  constitute  your  consent  to  such  changes  and  your continued consent to the full Policy.
        </p>
        <p style={styles.textBold}>
            Contacting Us
        </p>
        <p style={styles.text}>
            If you have any questions about this Policy, please contact us at equineexchange3@gmail.com
        </p>
        <p style={styles.textItalics}>
            Version: This document was last updated on July 20th, 2021
        </p>
        <div
            style={{
              display: 'flex',
              flexDirection: "column",
              color: COLOR_GREENISH,
              alignSelf: "center",
              marginBottom: 20
            }}>
            {"\u00A9"} EQUINE EXCHANGE {new Date().getFullYear()}
        </div>
      </div>
    </>
  );
}