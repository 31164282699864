import React from "react";

export const GooglePayMark = ({ height }) => {
    return (
		<svg 
			height={height}
			viewBox="159.545 347.555 292.574 114.792"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g id="G_Pay_Lockup_1_">
                <g id="Pay_Typeface_3_">
                    <path id="Letter_p_3_" fill="#3C4043" d="M296.098,403.385v33.844h-10.741v-83.576h28.475c7.216,0,13.37,2.405,18.404,7.216
                        c5.146,4.811,7.72,10.685,7.72,17.622c0,7.104-2.573,12.979-7.72,17.733c-4.979,4.755-11.132,7.104-18.404,7.104h-17.734V403.385z
                        M296.098,363.945v29.146h17.958c4.251,0,7.832-1.454,10.629-4.307c2.853-2.853,4.308-6.322,4.308-10.237
                        c0-3.86-1.455-7.272-4.308-10.125c-2.797-2.965-6.321-4.419-10.629-4.419h-17.958V363.945z"/>
                    <path id="Letter_a_3_" fill="#3C4043" d="M368.039,378.155c7.943,0,14.209,2.125,18.797,6.377
                        c4.587,4.251,6.88,10.069,6.88,17.453v35.243h-10.237v-7.943h-0.447c-4.419,6.545-10.349,9.79-17.733,9.79
                        c-6.321,0-11.58-1.847-15.831-5.595c-4.252-3.748-6.378-8.391-6.378-13.985c0-5.93,2.238-10.629,6.713-14.097
                        c4.476-3.524,10.462-5.259,17.901-5.259c6.378,0,11.636,1.175,15.72,3.524v-2.462c0-3.748-1.454-6.88-4.419-9.51
                        c-2.965-2.629-6.434-3.916-10.405-3.916c-5.986,0-10.741,2.518-14.209,7.608l-9.454-5.93
                        C350.138,381.903,357.857,378.155,368.039,378.155z M354.166,419.663c0,2.797,1.175,5.146,3.58,6.993
                        c2.35,1.846,5.146,2.797,8.335,2.797c4.531,0,8.56-1.679,12.083-5.035c3.524-3.356,5.314-7.272,5.314-11.804
                        c-3.356-2.629-7.999-3.972-13.985-3.972c-4.363,0-7.999,1.063-10.908,3.133C355.62,413.957,354.166,416.587,354.166,419.663z"/>
                    <path id="Letter_y_3_" fill="#3C4043" d="M452.119,380.001l-35.803,82.346H405.24l13.314-28.81l-23.607-53.536h11.691
                        l17.006,41.061h0.225l16.559-41.061H452.119z"/>
                </g>
                <g id="G_Mark_1_">
                    <path id="Blue_500" fill="#4285F4" d="M253.376,396.56c0-3.502-0.313-6.853-0.895-10.075h-45.022v18.461l25.929,0.005
                        c-1.051,6.143-4.436,11.379-9.622,14.87v11.977H239.2C248.212,423.456,253.376,411.127,253.376,396.56z"/>
                    <path id="Green_500_1_" fill="#34A853" d="M223.771,419.82c-4.296,2.897-9.829,4.593-16.301,4.593
                        c-12.503,0-23.109-8.425-26.908-19.781h-15.921v12.352c7.888,15.652,24.1,26.394,42.829,26.394
                        c12.945,0,23.82-4.257,31.736-11.586L223.771,419.82z"/>
                    <path id="Yellow_500_1_" fill="#FABB05" d="M179.063,395.469c0-3.189,0.532-6.271,1.5-9.169v-12.352h-15.921
                        c-3.262,6.473-5.097,13.778-5.097,21.521c0,7.742,1.841,15.048,5.097,21.521l15.921-12.352
                        C179.595,401.739,179.063,398.657,179.063,395.469z"/>
                    <path id="Red_500" fill="#E94235" d="M207.47,366.519c7.065,0,13.393,2.434,18.388,7.189l13.678-13.667
                        c-8.308-7.737-19.138-12.486-32.066-12.486c-18.724,0-34.941,10.741-42.829,26.393l15.921,12.352
                        C184.361,374.943,194.967,366.519,207.47,366.519z"/>
                </g>
            </g>
		</svg>
	)
}
