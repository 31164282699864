import {useState} from 'react'
import { useHistory } from "react-router-dom"
import { db, auth } from '../../firebase'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
require('dotenv').config();

export const SignUp = ({ credentials, setCredentials, handleCredentials, setLoginOpen, setNewUser, setLoggedIn}) => {
    
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const signup = (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password).then(cred => {
            db.collection('users').doc(cred.user.uid).set({
                id: cred.user.uid,
                email: email,
                homeTown: credentials.homeTown,
                name: credentials.userName,
                phoneNumber: credentials.phoneNumber,
                theme: 'light',
                newUser: true,
                role: 'Admin'
            });
        })
    }

    async function handleSubmit(e) {
        e.preventDefault()
        console.log(credentials.email, credentials.password)

        if (credentials.password !==
            credentials.passwordConfirm) {
            return setError('Passwords do not match.')
        }

        try {
            setError('')
            setLoading(true)
            await signup(credentials.email, credentials.password, credentials.username)
                .then(() => {
                    auth.currentUser.updateProfile({ displayName: credentials.userName, hometown: credentials.homeTown, phoneNumber: credentials.phoneNumber })
                })
                .then(() => {
                    auth.signInWithEmailAndPassword(credentials.email, credentials.password)
                })
                .then(() => {
                    setLoggedIn(auth.currentUser.displayName)
                })
                .then(() => {
                    history.push('/dashboard')
                    setLoginOpen(false)
                })
                .catch((error) => console.log(error)) 
        } catch(e) {
            console.log(e);
            setError('Failed to create an account')
            setLoading(false)
        }
    }

    return (
        <div>  
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username</label>
                    <input type="text" className="form-control" placeholder="Username" name="userName" onChange={handleCredentials} autoFocus/>
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input type="email" className="form-control" placeholder="Enter email" name="email" onChange={handleCredentials} />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={handleCredentials} />
                </div>

                <div className="form-group">
                    <label>Confirm Password</label>
                    <input type="password" className="form-control" placeholder="Enter password" name="passwordConfirm" onChange={handleCredentials} />
                </div>

                <div className="form-group">
                    <label>Hometown</label>
                    <div>
                    <GooglePlacesAutocomplete
                      name="hometown"
                      apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                      selectProps={{
                        onChange: (doc) => setCredentials({ ...credentials, 'homeTown': doc.label }),
                        defaultInputValue: credentials.hometown
                      }}
                      apiOptions={{ language: 'en', region: 'us' }}
                      autocompletionRequest={{
                        types: ['(cities)'],
                        componentRestrictions: {
                          country:['us', 'ca']
                        }}}
                    />
                  </div>
                </div>

                <div className="form-group">
                    <label>Phone Number</label>
                    <input type="text" className="form-control" placeholder="Enter phone number" name="phoneNumber" onChange={handleCredentials} />
                </div>

                <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                {error !== '' && 
                <div className="mt-3">
                    <span className="text-secondary">{`${error} `}</span>
                </div>}
            </form>
        </div>
    )
}