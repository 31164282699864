import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import { FaCheck } from 'react-icons/fa';
import { BsPencil } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { BsCalendar } from 'react-icons/bs';
import { db, storage, functions } from '../../../../firebase';
import MultiSelect from "react-multi-select-component";
import DatePicker from "react-datepicker";
import { captureException } from '../../../../utils/utility';

export default function UserModal(props) {
  const { setEditModalShow, onHide, currentUser, setCurrentUser, getUsers, setDeleteListingModalShow, userObject, setUserObject, defaultUserObject, setCreateProgramModalShow, tempPass, setTempPass, program, setProgram, editForeverFree, setEditForeverFree } = props

  const [userChanged, setUserChanged] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showEditBannerModal, setShowEditBannerModal] = useState(false);
  const [startProgramDate, setStartProgramDate] = useState(null);
  const [endProgramDate, setEndProgramDate] = useState(null);
  const [editProgram, setEditProgram] = useState(false)
  const [editStart, setEditStart] = useState(false)
  const [editEnd, setEditEnd] = useState(false)
  const [userFirstName, setUserFirstName] = useState("")
  const [userLastName, setUserLastName] = useState("")

  const [disciplines, setDisciplines] = useState([]);
  const [selected, setSelected] = useState([]);

  const [editToggle, setEditToggle] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    homeTown: false,
    role: false,
    status: false,
    programUser: false,
    promoCode: false,
    subscription: false,
  });

  const updateUserStatus = (id) => {
    const enableUser = functions.httpsCallable('disableEnableUserByUidCall');
    enableUser({ "uid": id, "newStatus": false })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("disable user error: ", error, error.code, error.message));
  }

  const splitName = (name) => {
    // console.log(name)
    let first = name.split(' ')[0]
    let last = name.substring(first.length).trim()
    setUserFirstName(first);
    setUserLastName(last);
  };

  const handleRoleSelect = (role) => {
    let newRole;
    switch (role) {
      case "user" :
        newRole = "User"
        break;
      case ("programUser") :
        newRole = "Program"
        break;
      case "admin" :
        newRole = "Admin"
        break;
      default :
        newRole = "Invalid Role"
    }
    setUserChanged(true);
    setUserObject(prev => ({...prev, role: newRole}))
  }
  
  const handleStatusSelect = (status) => {
    let newStatus;
    switch (status) {
      case "active" :
        newStatus = "Active"
        break;
      case "suspended" :
        newStatus = "Suspended"
        break;
      default :
        newStatus = "Invalid Status"
    }
    setUserChanged(true);
    setUserObject(prev => ({...prev, status: newStatus}))
  }

  const handleStartDate = (date) => {
    setStartProgramDate(new Date(date))
    // }))
    setUserChanged(true);
    setEditProgram(true);
    setEditStart(false)
  }
  const handleEndDate = (date) => {
    setEndProgramDate(new Date(date))
    // }))
    setUserChanged(true);
    setEditProgram(true);
    setEditEnd(false)
  }

  const handleForeverFree = () => {
    setEditForeverFree(!editForeverFree)
    setUserObject(prev => ({...prev, foreverFree: !editForeverFree}))
    setUserChanged(true)
  }


  //firebase update for user
  const userRef = db.collection('users');

  const updateUserToDB = async (id, obj) => {
    try{
      await userRef.doc(id).update(obj)
    } catch(error) {
      console.log(error);
      captureException({ message: 'Failed to update user', e: error});
    }
  }

  const updateProgramUser = async (id, obj) => {
    try{
      await userRef.doc(id).update({program: obj})
    } catch(error) {
      console.log(error);
      captureException({ message: 'Failed to update program user', e: error});
    }
  }

  //function to save user to db and clean up
  const commitSave = () => {
    try{
      let newProgram;
      if(editProgram) {
          newProgram = {
          startDate: new Date(startProgramDate),
          endDate: new Date(endProgramDate),
          imageUrl: program.imageUrl,
          disciplines: selected.map(item => item.value)
        }
      }
      // console.log("userObject ", userObject)
      // console.log("newProgram ", newProgram)
      updateUserToDB(currentUser.id, userObject)
      program && updateProgramUser(currentUser.id, newProgram)
      onHide(false)
      setUserChanged(false)
      setCurrentUser(null);
      setTempPass(null)
      setSelected([]);
      // setUserObject(defaultUserObject);
      getUsers()
    } catch(e) {
      captureException({message: 'Failed to update user data', e});
    }
  }

  const EditBannerModal = (props) => {
    const [addImageUrl, setAddImageUrl] = useState("")
    const [imageUrlChanged, setImageUrlChanged] = useState(false);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const defaultImage = "https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/images%2FEE_NO_PROGRAM_IMAGE.jpg?alt=media&token=64d7af17-1926-4003-94c4-471d1190047c"
    
    const handleUrl = (e) => {
      setAddImageUrl(e.target.value)
      setImageUrlChanged(true)
      setEditProgram(true);
      setUserChanged(true);
    }
  
    const handleFile = (e) => {
      setImageUrlChanged(true)
      // URL.createObjectURL(event.target.files[0])
      setImagePreview(URL.createObjectURL(e.target.files[0]))
      setImage(e.target.files[0])
      setEditProgram(true);
      setUserChanged(true);
    }

    const handleSaveImage = () => {
      if (!image) {
        setUserObject(prev => ({...prev, program: {...prev.program, imageUrl: addImageUrl }}))
        // let program = {
        //   imageUrl: addImageUrl,
        //   startDate: new Date(startProgramDate),
        //   endDate: new Date(endProgramDate),
        // }
        // userRef.doc(currentUser ? currentUser.id : newUserId).set({...userObject, program: program})
        // currentUser ? userRef.doc(currentUser.id ).update({programUser: true}) : userRef.doc(newUserId).update({programUser: true, uid: newUserId})
      } else {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          () => {
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log("url", url)
                let program = {
                  imageUrl: url,
                  startDate: new Date(startProgramDate),
                  endDate: new Date(endProgramDate),
                  disciplines: selected.map(item => item.value),
                }
                setUserObject(prev => ({...prev, program: program}))
                setProgram(prev => ({...prev, imageUrl: url}))
              });
          }
        );
      }
      setShowEditBannerModal(false)
    }
    
    useEffect(() => {
      if(program) { 
        setImagePreview(program.imageUrl);
      }
    }, [])

    

    return (
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' size="md">
        <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
            {userObject.name && `Create Program User: ${userObject.name}` }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Col className="w-100">
            <Row className="w-100 align-items-center justify-content-between px-5">
              <h5 className="checkedText">Image:</h5>
              <img src={imagePreview} alt={`Program User Banner Ad`} className="imagesize"/>
              {/* <img src={userObject ? currentSponsor.data().image : defaultImage} alt={`${currentSponsor && convertTime(currentSponsor.data().name)} Sponsor Ad`} className="imagesize"/> */}
            </Row>
            <hr></hr>
          <Row className="w-100 m-0 justify-content-center">
          <h4 className="manageDropdownActiveText mb-4 text-center">Select image or paste URL for this program</h4>
          <h6 className="manageDropdownActiveText mb-4">Image size should be 480x320 pixels</h6>
            <input
              className="w-100"
              accept="image/*"
              // className={classes.fileInput}
              id="contained-button-file"
              type="file"
              onChange={handleFile}
              
            />
          <input type="text" className="form-control mt-4 w-100" id="addDisciplineInput" placeholder="http://" value={addImageUrl} onChange={e => handleUrl(e)}></input>
          </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          {imageUrlChanged ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleSaveImage()}}>Save Banner</div> : 
            <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3" >Save Banner</div>}
        </Modal.Footer>
      </Modal>
    )
  }

  //Modal to confirm changing user to admin
  const AdminModal = (props) => {
    const handleAdminSave = () => {
      setShowAdminModal(false);
      commitSave();
    }

    return (
      <Modal  {...props} aria-labelledby='contained-modal-title-vcenter' size="sm">
        <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
            Confirm Admin Access
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Setting user roll as "Admin" will grant that user access to the admin portal and settings. Click confirm to save or cancel to go back.
        </Modal.Body>
        <Modal.Footer>
          <div className="px-3 py-2 mt-3 isClickable" onClick={() => {setShowAdminModal(false)}}>Cancel</div>
          <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleAdminSave()}}>Confirm</div>
        </Modal.Footer>
      </Modal>
    )
  }
  

  //Logic for saving edited user info
  const handleEditUserSave = () => {
    if(userObject.status === "Suspended") {
      setEditModalShow(false)
      setDeleteListingModalShow(true)
      // console.log("delete listings")
      setUserChanged(false)
    } else if (userObject.status === "Active" && userObject.role === "Admin") {
      setShowAdminModal(true)
    } else if (userObject.status === "Active" && userObject.role === "Program" && !program) {
      setCreateProgramModalShow(true)
    } else if(!userObject.status) {
      alert("Please set user status to continue.")
    } else {
      // console.log(userObject)
      updateUserStatus(userObject.uid)
      commitSave()
    }
  }

  const updateField = (e, field) => {
    // console.log('update field called');
    setUserChanged(true);
    setUserObject(prev => ({...prev, [field]: e.target.value}));
  }

  
  const updateUserName = (e, field) => {
    field === "firstName" ? setUserFirstName(e.target.value) : setUserLastName(e.target.value);
    setUserChanged(true);
  }

  const mergeAndSaveNames = () => {
    // console.log("merge and save")
    setUserObject(prev => ({...prev, name: userFirstName + " " + userLastName}));
  }

  const handleNameBlur = (field) => {
    // console.log("onBlur")
    setEditToggle(prev => ({...prev, [field]: false}))
    mergeAndSaveNames();
  }
  
  const removePhoto = () => {
    setUserChanged(true);
    program ? setProgram(prev => ({...prev, imageUrl: ""})) : setUserObject(prev => ({...prev, photoUrl: "https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/users%2FcowboyAvatar.jpg?alt=media&token=8369812f-aa04-45d0-8ff0-58b9e0c95239"}));
  }

  useEffect(() => {
    setUserChanged(false)
    if(currentUser) {
      // console.log(currentUser.id)
      let editObject = {
          bio: currentUser.data().bio ? currentUser.data().bio : "",
          name: currentUser.data().name,
          email: currentUser.data().email,
          phoneNumber: currentUser.data().phoneNumber,
          homeTown: currentUser.data().homeTown,
          role: currentUser.data()?.role ? currentUser.data().role : "User",
          following: currentUser.data().following ? [...currentUser.data().following] : [],
          onBoarded: currentUser.data().onBoarded ? currentUser.data().onBoarded : false,
          fcmToken: currentUser.data().fcmToken ? currentUser.data().fcmToken : "",
          listedHorses: currentUser.data().listedHorses ? [...currentUser.data().listedHorses] : [],
          photoUrl: currentUser.data().photoUrl ? currentUser.data().photoUrl : null,
          programUser: currentUser.data().programUser ? currentUser.data().programUser : false,
          foreverFree: currentUser.data().foreverFree ? currentUser.data().foreverFree : false,
          status: currentUser.data().status ? currentUser.data().status : "",
          toggleCheckBox: currentUser.data().toggleCheckBox ? currentUser.data().toggleCheckBox : false,
          uid: currentUser.data().uid ? currentUser.data().uid : "",
          promoCode: currentUser.data().promoCode ? currentUser.data().promoCode : "",
          subscription: currentUser.data().subscription ? currentUser.data().subscription : [],
          homeTownCoordinates: currentUser.data().homeTownCoordinates ? currentUser.data().homeTownCoordinates : {lat: "", lng: ""},
          websiteURL: currentUser.data().websiteURL ? currentUser.data().websiteURL : "",
        }
      if( currentUser.data().temporaryPassword ) { console.log("has Temp"); editObject.temporaryPassword = currentUser.data().temporaryPassword; setTempPass(currentUser.data().temporaryPassword) }
      if( currentUser.data().foreverFree ) { console.log("Foooorreeevvveerrr", currentUser.data().foreverFree ); editObject.foreverFree = currentUser.data().foreverFree; setEditForeverFree(currentUser.data().foreverFree) }
      if( currentUser.data().program ) {
        console.log("has program");
        editObject.program = currentUser.data().program;
        setProgram(currentUser.data().program);
        setSelected(currentUser.data().program.disciplines ? currentUser.data().program.disciplines.map(item => addOption(item)) : []);
        console.log("date", currentUser.data().program.startDate);
        setStartProgramDate(new Date(currentUser.data().program.startDate.seconds * 1000))
        setEndProgramDate(new Date(currentUser.data().program.endDate.seconds * 1000))
      }
      console.log("editObject ", editObject)
      // if( currentUser.data().temporaryPassword ) { console.log("has Temp"); setTempPass(currentUser.data().temporaryPassword) }
      setUserObject(editObject)
      currentUser.data().name && splitName(currentUser.data().name)
    }
  }, [currentUser])

  useEffect(() => {
    if(program) { 
      const dropdownListRef = db.collection('my-horse-listing-dropdowns').doc('jXE8l6PiewX1VCfDoaKB');
      dropdownListRef.get()
      .then(data => setDisciplines(data.get('disciplines').map(item => addOption(item.text))))
      .catch(error => console.log(error));
    }
  }, [program])

  const addOption = (item) => {
    return {
      label:item, 
      value:item,
    }
  }

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' size="xl">
      <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
            Edit this User: {userObject.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-start">
        <Row className="w-100">
          <Col className="col-5 pl-4">
          <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText">First Name:</h5>
                {editToggle.firstName ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, firstName: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, firstName: true}))} /></h5> }
              </div>
              {editToggle.firstName ? <input type="text" className="form-control mt-1 w-50" id="addSponsorName" value={userFirstName} onChange={e => updateUserName(e, "firstName")} onBlur={() => handleNameBlur("firstName")}></input> : <h4 className="checkedText">{userFirstName}</h4> }
            </Row>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText">Last Name:</h5>
                {editToggle.lastName ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, lastName: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, lastName: true}))} /></h5> }
              </div>
              {editToggle.lastName ? <input type="text" className="form-control mt-1 w-50" id="addSponsorName" value={userLastName} onChange={e => updateUserName(e, "lastName")} onBlur={() => handleNameBlur("lastName")}></input> : <h4 className="checkedText">{userLastName}</h4> }
            </Row>
            <hr></hr>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-ce d-flex flex-row justify-content-start">
                <h5 className="checkedText m-0">Email:</h5>
                {editToggle.email ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, email: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, email: true}))} /></h5> }
              </div>
                {editToggle.email ? <input type="text" className="form-control mt-1 w-50" id="addUserEmail" value={userObject.email} onChange={e => updateField(e, "email")} onBlur={() => setEditToggle(prev => ({...prev, email: false}))}></input> : <h4 className="checkedText">{userObject.email}</h4> }
            </Row>
            <hr></hr>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText m-0">Phone Number:</h5>
                {editToggle.phone ? <h5 className="ml-2 checkedText"><FaCheck className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, phone: false}))} /></h5> : <h5 className="ml-2 checkedText"><BsPencil className='pencil isClickable' onClick={() => setEditToggle(prev => ({...prev, phone: true}))} /></h5> }
              </div>
                {editToggle.phone ? <input type="text" className="form-control mt-1 w-50" id="addUserPhone" value={userObject.phoneNumber} onChange={e => updateField(e, "phoneNumber")} onBlur={() => setEditToggle(prev => ({...prev, phone: false}))}></input> : <h4 className="checkedText">{userObject.phoneNumber}</h4> }
            </Row>
            <hr></hr>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
              <div className="align-items-center d-flex flex-row justify-content-start">
                <h5 className="checkedText m-0">Home Town:</h5>
              </div>
                 <h4 className="checkedText">{userObject.homeTown}</h4> 
            </Row>
          </Col>
          { userObject.subscription && <Col>
            <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
                <div className="align-items-center d-flex flex-row justify-content-start">
                  <h2 className="checkedText">Subscription:</h2>
                </div>
                  <h4 className="checkedText">Start Date: {new Date(userObject.subscription.date).toLocaleDateString('en-US')}</h4>
                  <h4 className="checkedText">Expiry Date: {userObject.subscription.expiryDate && new Date(userObject.subscription.expiryDate.seconds*1000).toLocaleDateString('en-US')}</h4>
          
              </Row>
              <hr></hr>
              <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
                <div className="align-items-center text-center d-flex flex-column justify-content-start">
                  <h5 className="checkedText mb-3">Platform: {userObject.subscription.platform}</h5>
                  <h5 className="checkedText mb-3 text-wrap word-wrap">product: {userObject.subscription.product}</h5>
                  <h5 className="checkedText mb-3">type: {userObject.subscription.type}</h5>
                </div>
                {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Change Subscription Type
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {handleSubscriptionSelect("free")}}>Free</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleSubscriptionSelect("basic")}}>Basic</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleSubscriptionSelect("unlimited")}}>Unlimited</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </Row>
              <hr></hr>
              <Row className="w-100 align-items-center d-flex flex-column justify-content-start">
                <div className="align-items-center d-flex flex-row justify-content-start">
                  <h2 className="checkedText">Promo Code:</h2>
                  <div className="align-items-center d-flex flex-row justify-content-start">
                  </div>
                </div>
                { userObject.promoCode?  <h4 className="checkedText">{userObject.promoCode}</h4> : <h4 className="checkedText">None</h4> }
              </Row>
          
            </Col>}
            <Col className="col-3 ">
              <Row className="w-100 mb-4 align-items-center d-flex flex-row justify-content-center">
                <h5 className="checkedText mr-2">Profile Image:</h5>
          
                {userObject.photoUrl ? <><h4 className="checkedText"><IoMdTrash className='pencil isClickable ml-2' onClick={() => removePhoto()} /></h4><img src={userObject.photoUrl} alt={`${userObject.name} Profile`} className="imagesize"/> </>: <h6>No Image</h6>}
              </Row>
              <hr></hr>
              <Row class="w-100 align-items-center d-flex flex-column">
                <div className="align-items-center d-flex flex-row justify-content-start">
                    <h2 className="checkedText text-center">Forever Free Account:</h2>
                </div>
                <Row className="w-100 justify-content-center">
                  {editForeverFree ? <div className="btn btn-primary" onClick={() => {handleForeverFree()}}>Disable</div> :
                    <div className="btn btn-outline-primary" onClick={() => {handleForeverFree()}}>Enable</div>}
                </Row>
              </Row>
              {tempPass && <>
              <hr></hr>
              <Row className="d-flex flex-column align-items-center justify-content-center">
                <h4 className="manageDropdownActiveText mb-4 text-center">Temporary Password:</h4>
                <h5 className="manageDropdownActiveText mb-4">{tempPass}</h5>
              </Row>
              </>
              }
          
              <hr></hr>
              
            </Col>
        </Row>
        <hr></hr>
        <Row className="w-100 d-flex flex-row justify-content-around align-items-center">
        <Col className="w-100 align-items-center d-flex flex-column justify-content-start">
                <div className="align-items-center d-flex flex-row justify-content-start mb-2">
                  <h5 className="checkedText m-0 pb-2">User Role:</h5>
                  <h4 className="checkedText ml-2">{userObject.role}</h4>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Change Role
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {handleRoleSelect("user")}}>User</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleRoleSelect("programUser")}}>Program User</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleRoleSelect("admin")}}>Admin</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <hr></hr>
              <Col className="w-100 align-items-center d-flex flex-column justify-content-center">
                <div className="align-items-center d-flex flex-row justify-content-start mb-2">
                  <h5 className="checkedText m-0 pb-2">Status:</h5>
                  {userObject.status === "Suspended" ? <h4 className="text-danger ml-2">{userObject.status}</h4> : <h4 className="checkedText ml-2">{userObject.status}</h4>}
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Change Status
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {handleStatusSelect("active")}}>Active</Dropdown.Item>
                    <Dropdown.Item onClick={() => {handleStatusSelect("suspended")}}>Suspended</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
        </Row>
        <hr></hr>
        {program && <Row>
          <Row className="w-100 d-flex flex-row justify-content-around align-items-center">
            <Col className="w-100 align-items-center d-flex flex-column justify-content-start">
              <Row className="justify-content-center align-items-center">
              <Col>
                  <h5 className="checkedText">Banner Image:</h5>
                  <img src={program && program.imageUrl} alt={`Program User Banner Ad`} className="imagesize"/>
                  </Col>
                  <Col className="pl-0 pt-4">
                  <h4 className="checkedText"><IoMdTrash className='pencil isClickable ml-1' onClick={() => removePhoto()} /></h4>
                  <h4 className="checkedText"><BsPencil className='pencil isClickable ml-1' onClick={() => setShowEditBannerModal(true)} /></h4>
                  </Col>
                  </Row>
              </Col>
              <Col className="w-100 align-items-center justify-content-between px-5">
              <h5 className="w-100 text-center checkedText m-0">Program Start Date:</h5>
              <Row className="w-100 justify-content-center align-items-center pt-2">
                {editStart ? <div className="w-100 d-flex flex-row justify-content-center"><DatePicker selected={startProgramDate} onChange={(date) => handleStartDate(date)} /></div> :  <div className="w-100 d-flex flex-row align-items-center justify-content-center"><h5 className="checkedText pr-3">{new Date(startProgramDate).toLocaleDateString('en-US')}</h5><h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditStart(true)}} /></h4></div> }
                
                {/* {editStart ? <div className="w-50"><DatePicker selected={startProgramDate} onChange={(date) => handleStartDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor ? startDate.toLocaleDateString('en-US') : new Date(sponsorObject.startDate).toLocaleDateString('en-US')}</h5> } */}
              </Row>
            </Col>
            <Col className="w-100 align-items-center justify-content-between px-5">
              <h5 className="w-100 text-center checkedText m-0">Program End Date:</h5>
              <Row className="w-100 justify-content-center align-items-center pt-2">
                {editEnd ? <div className="w-100 d-flex flex-row justify-content-center"><DatePicker selected={endProgramDate} onChange={(date) => handleEndDate(date)} /></div> : <div className="w-100 d-flex flex-row align-items-center justify-content-center"><h5 className="checkedText pr-3">{new Date(endProgramDate).toLocaleDateString('en-US')}</h5><h4 className="checkedText"><BsCalendar className='pencil isClickable' onClick={() => {setEditEnd(true)}} /></h4></div> }
                {/* {editEnd ? <div className="w-50"><DatePicker selected={endProgramDate} onChange={(date) => handleEndDate(date)} /></div> : <h5 className="checkedText pr-3">{currentSponsor ? endDate.toLocaleDateString('en-US') : new Date(sponsorObject.endDate).toLocaleDateString('en-US')}</h5> } */}
                
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center w-100">
            <Col className="w-50"></Col>
            <Col className="justify-content-center w-50">
                <h5 className="checkedText">Disciplines</h5>
                <MultiSelect
                  className="justify-content-center multi-select w-100"
                  options={disciplines}
                  value={selected}
                  onChange={(values) => {
                    setSelected(values);
                    setEditProgram(true);
                    setUserChanged(true);
                  }}
                  labelledBy="Select Disciplines"
                />
            </Col>
            <Col className="w-50"></Col>
          </Row>
        </Row>
        }
        </Modal.Body>
        <Modal.Footer>
            <div className="manageDropdownButton px-3 py-2 mt-3 mr-4" onClick={() => {onHide()}}>Cancel</div>
          {userChanged ? <div className="manageDropdownButton px-4 py-2 mt-3" onClick={() => {handleEditUserSave()}}>Save</div> : 
            <div className="manageDropdownButtonUnchanged px-4 py-2 mt-3" >Save</div>}
        </Modal.Footer>
        <AdminModal 
          show={showAdminModal}
          onHide={() => setShowAdminModal(false)}
        />
        <EditBannerModal 
          show={showEditBannerModal}
          onHide={() => setShowEditBannerModal(false)}
        />
    </Modal>
  );
}
