import * as React from 'react';

export default function SvgCoaches(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400 400'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <path
        id='prefix__Path_1167'
        data-name='Path 1167'
        className='prefix__cls-1'
        d='M199.94 345.58c-82.27.54-149.4-66.39-149.94-149.5-.49-76.88 56.46-141.78 132.08-150.49a5.13 5.13 0 011.31 10.13C107.24 65 53 134.9 62.17 211.82s78.38 131.74 154.53 122.45 130.41-79.18 121.22-156.1C330.25 114 280.2 63.47 216.7 55.72a5.13 5.13 0 011.2-10.13C299.65 55 358.35 129.58 349 212.16c-8.63 76.39-72.88 133.92-149 133.42'
      />
      <g id='prefix__oXcxaR_tif'>
        <g id='prefix__Group_589' data-name='Group 589'>
          <path
            id='prefix__Path_1203'
            data-name='Path 1203'
            className='prefix__cls-1'
            d='M216.88 202.53a94.36 94.36 0 011 9.63 42.57 42.57 0 01-82.66 14.12 1.59 1.59 0 00-1.85-1.22 55.87 55.87 0 01-6.47-.19 9.24 9.24 0 01-8.23-9c0-2.6-.09-5.21 0-7.82a9.41 9.41 0 019.34-9.25c1.72 0 3.44-.09 5.16 0a1.91 1.91 0 002.11-1.52 41.6 41.6 0 0130.26-26.85 49.87 49.87 0 0110-1.17c5.11-.18 10.21 0 15.32 0 3 0 4 1 4 4v2.66a3.13 3.13 0 003 3.24h.25a3.18 3.18 0 003.27-3.09v-3c0-2.67 1.09-3.7 3.7-3.7h67.22c3.69 0 5 1.7 4.14 5.29q-2.94 11.76-5.87 23.54c-.94 3.79-1.66 4.35-5.54 4.35h-46.78a7.73 7.73 0 00-1.37-.02zM188.27 176c-5.13 0-10.19-.3-15.21.09a39.76 39.76 0 00-11 2.41 36.06 36.06 0 1049.24 34.18 38.57 38.57 0 00-1.74-12.12c-1-3.25.19-4.86 3.7-4.88h51.22l4.86-19.53h-61c-1.37 6.54-4.45 9.56-9.82 9.67s-8.62-3.01-10.25-9.82zm-55.49 29.5h-4.86a2.79 2.79 0 00-2.91 2.64v.17c-.13 2.42-.11 4.88 0 7.4a2.8 2.8 0 002.72 2.85c1.76.15 3.51 0 5 0z'
          />
          <path
            id='prefix__Path_1204'
            data-name='Path 1204'
            className='prefix__cls-1'
            d='M195 146.47v-6.33c0-2.11 1.2-3.51 3.28-3.53s3.27 1.22 3.29 3.53v12.48a3.33 3.33 0 01-2.94 3.68 2.36 2.36 0 01-.37 0 3.19 3.19 0 01-3.28-3.11 2.64 2.64 0 010-.49c.02-2.13.02-4.18.02-6.23z'
          />
          <path
            id='prefix__Path_1205'
            data-name='Path 1205'
            className='prefix__cls-1'
            d='M205.93 154.81a11.57 11.57 0 01.52-1.5q2.53-5.42 5.12-10.82a3.29 3.29 0 014.14-2.09l.32.13a3.28 3.28 0 011.52 4.4 1.51 1.51 0 01-.15.28c-1.85 3.86-3.7 7.73-5.44 11.58a2.92 2.92 0 01-3.46 1.64 3.29 3.29 0 01-2.57-3.62z'
          />
          <path
            id='prefix__Path_1206'
            data-name='Path 1206'
            className='prefix__cls-1'
            d='M181.94 139.9c.84.7 2 1.24 2.45 2.15 2 3.82 3.69 7.75 5.54 11.68a3.19 3.19 0 01-1.25 4.35l-.3.15a3.08 3.08 0 01-4.09-1.47l-.15-.38q-2.76-5.74-5.54-11.56a3.53 3.53 0 011.86-4.64 3.58 3.58 0 011.48-.28z'
          />
          <path
            id='prefix__Path_1207'
            data-name='Path 1207'
            className='prefix__cls-1'
            d='M175.19 234.84a22.8 22.8 0 01-22.8-22.84 22.85 22.85 0 0122.73-23 22.84 22.84 0 0123.15 22.53v.18a23 23 0 01-22.78 23.16zm0-6.57a16.15 16.15 0 0016.41-15.88v-.23a16.39 16.39 0 00-16.13-16.64 16.19 16.19 0 00-16.55 15.84v.32a16.15 16.15 0 0015.67 16.61h.7z'
          />
        </g>
      </g>
    </svg>
  );
}
