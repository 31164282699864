import React from "react";

export const StripeWorldmark = ({ height }) => {
    return (
		<svg 
			height={height}
			viewBox="0 0 360 149.839"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g>
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#635BFF" d="M360,77.377c0-25.575-12.38-45.763-36.102-45.763
					c-23.758,0-38.212,20.196-38.212,45.629c0,30.112,17.044,45.348,41.365,45.348c11.935,0,20.895-2.716,27.678-6.474V95.998
					c-6.807,3.437-14.614,5.524-24.528,5.524c-9.732,0-18.308-3.448-19.424-15.149h48.886C359.774,85.022,360,79.792,360,77.377
					M310.558,67.936c0-11.252,6.938-15.964,13.181-15.964c6.132,0,12.633,4.724,12.633,15.964H310.558z"/>
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#635BFF" d="M247.077,31.613c-9.788,0-16.106,4.606-19.561,7.832l-1.299-6.172
					h-21.989l0.009,116.566l24.973-5.308l0.051-28.258c3.586,2.626,8.928,6.316,17.665,6.316c17.865,0,34.206-14.377,34.206-46.106
					C281.131,47.41,264.615,31.613,247.077,31.613 M241.11,100.527c-5.856,0-9.354-2.112-11.778-4.738l-0.148-37.108
					c2.604-2.852,6.196-4.947,11.927-4.947c9.137,0,15.426,10.235,15.426,23.299C256.536,90.475,250.345,100.527,241.11,100.527"/>
				<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#635BFF" points="169.801,25.667 194.869,20.338 194.869,0 169.801,5.329 	
					"/>
				<rect x="169.8" y="33.273" fill-rule="evenodd" clip-rule="evenodd" fill="#635BFF" width="25.068" height="87.532"/>
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#635BFF" d="M142.944,40.656l-1.565-7.383H119.79v87.532h24.956v-59.34
					c5.9-7.749,15.862-6.255,19.041-5.229V33.283C160.46,32.087,148.787,29.91,142.944,40.656"/>
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#635BFF" d="M92.867,11.591l-24.38,5.182l-0.106,80.118
					c0,14.784,11.117,25.7,25.937,25.7c8.164,0,14.194-1.483,17.525-3.31V98.953c-3.199,1.279-18.957,5.861-18.957-8.858V54.569h18.965
					V33.273H92.886L92.867,11.591z"/>
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#635BFF" d="M25.256,58.685c0-3.898,3.248-5.412,8.481-5.412
					c7.596,0,17.24,2.327,24.836,6.427V36.183c-8.271-3.3-16.542-4.569-24.813-4.569C13.504,31.614,0,42.193,0,59.871
					c0,27.646,37.962,23.155,37.962,35.077c0,4.614-4.01,6.107-9.581,6.107c-8.271,0-18.946-3.417-27.327-7.975v23.842
					c9.284,3.994,18.696,5.669,27.305,5.669c20.761,0,35.068-10.26,35.068-28.235C63.427,64.581,25.256,69.923,25.256,58.685"/>
			</g>
		</svg>
	)
}
