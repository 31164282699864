import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { IoMdArrowBack } from 'react-icons/io';
import { IoPlayCircleOutline } from 'react-icons/io5';
import { captureException } from '../../../../utils/utility';
import { storage } from '../../../../firebase';


const AddImageModal = (props) => {
    const [addImageUrl, setAddImageUrl] = useState("")
    const [imageUrlChanged, setImageUrlChanged] = useState(false);
    const defaultImage = "https://firebasestorage.googleapis.com/v0/b/equine-trader.appspot.com/o/coaches%2Fcowboy-avatar.png?alt=media&token=61f232af-5132-4be7-bc23-df89c0a7b920"

    const { 
        closeImageModal,
        coachObject,
        setCoachObject,
        url,
        setUrl,
        image,
        setImage,
        imagePreview,
        setImagePreview,
        onHide,
        show,
        handleSaveImageModal
    } = props

    const handleUpload = () => {
        const uploadTask = storage.ref(`coaches/${image.name}`).put(image);
        uploadTask.on(
        "state_changed",
        () => {
            storage
            .ref("coaches")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
                console.log("url", url)
                setCoachObject(prev => ({...prev, profileImageUrl: url }));
            })
            .catch(e => captureException({ message: 'Failed to upload image and get its url', e}));
        }
        );
        handleSaveImageModal()
    }

    const handleFile = (e) => {
        setImageUrlChanged(true)
        setImagePreview(URL.createObjectURL(e.target.files[0]))
        setImage(e.target.files[0])
        console.log(e)
    }

    const handleSaveImage = () => {
        handleUpload()
    }


    return (
        <Modal
        {...props}

        aria-labelledby="edit-sponsors-modal"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText justify-content-center text-center">
            Add Coach Profile Image
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="w-100 m-0 justify-content-center">
            <Row className="w-75 align-items-center justify-content-between">
                <h5 className="checkedText">Image:</h5>
                <div className="d-flex align-items-center justify-content-center text-secondary overflow-hidden imageCircle">
                    <img src={imagePreview ? imagePreview : addImageUrl ? addImageUrl : defaultImage} alt={`Preview of user profile`} className="profilePreview"/>
                </div>
            </Row>
            </Row>
            <hr></hr>
            <Row className="w-100 m-0 justify-content-center">
            <h4 className="manageDropdownActiveText mb-4 text-center">Select image or paste URL for this this sponsor</h4>
            <h6 className="manageDropdownActiveText mb-4">Image size should be 428x200 pixels</h6>
            <input
                accept="image/*"
                // className={classes.fileInput}
                id="contained-button-file"
                type="file"
                onChange={handleFile}
                
            />
            </Row>
        </Modal.Body>
        <Modal.Footer className="w-100 d-flex flex-row align-items-center justify-content-between">
            <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleSaveImageModal()}}>Cancel</div>
            {imageUrlChanged ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleSaveImage()}}>Save</div> :
            <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3">Save</div>}
        </Modal.Footer>
        </Modal>
    )
}

export default AddImageModal