export default {
    text: {

    },
    textBold: {
        fontWeight: 'bold'
    },
    textItalics: {

    },
    pageTitle: {
        fontWeight: 'bold',
        fontSize: 20
    }



}