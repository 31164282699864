import React, {useState, useEffect} from 'react';
import './listings.css';
import { Container, Row, Button } from 'react-bootstrap';
import Table from './Table/index';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { db, timestamp } from '../../../firebase';

import { MDBModal, MDBModalBody} from 'mdbreact';
import EventItem from './event-item/index';
import {currencyFormat} from '../../../utils/utility';
import MarkSoldModal from '../../../components/mark-sold-modal';

const ManageListings = () => {

  const [selectedRowData, setSelectedRowData] = useState();
  const [isMoldelOpen, setIsModelOpen] = useState(false);
  const [markSoldModalOpen, setMarkSoldModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [refresh, setRefresh] = useState('');
  const onRowClick = (row) => {
    console.log(row.id);
    setIsModelOpen(!isMoldelOpen);
    setSelectedRowData(row);
  }

  const onDeleteListing = () => {
    setIsModelOpen(!isMoldelOpen);
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure, you want to delete this listing',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onConfirmed(),
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    });
  }

  const onConfirmed = async() => {

    try{
      await db.collection('competing-horses').doc(selectedRowData.id).delete();
      await db.collection('my-horses')
      .doc(selectedRowData.data().ownerUid).collection('horses')
      .doc(selectedRowData.id).delete();
      setRefresh(selectedRowData.id);
    } catch (error) {
      console.log(error);
    }
  }

  const onListingStatusChange = () => {
    setIsModelOpen(!isMoldelOpen);
    confirmAlert({
      title: 'Confirm!',
      message: `Are you sure, you want to change listing status ${selectedRowData.data().status === 'Active' ? 'InActive' : 'Active'}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => onConfirmedStatusChange(),
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    });
  }

  const onMarkSold = async (horseData) => {
    setIsLoading(true)
    try {
      await db.collection('competing-horses')
      .doc(horseData.id)
      .update({
        markedSold: true, 
        listHorse: false, 
        listed: horseData.data().listed ? horseData.data().listed
                : timestamp
        }, {merge: true})
      .then((res) => console.log("UPDATED COMPETING HORSE DOC", res))
      .catch((error) => {
        console.log("error updating competing horse doc", error)
      })
      await db.collection('my-horses')
      .doc(horseData.data().ownerUid)
      .collection("horses")
      .doc(horseData.id)
      .update({
        markedSold: true, 
        listHorse: false, 
        listed: horseData.data().listed ? horseData.data().listed
                : timestamp
        }, {merge: true})
      .then((res) => console.log("UPDATED MYHORSES DOC", res))
      .catch((error) => {
        console.log("error updating myhorses doc", error)
      })
    } catch (error) {
      console.log("error marking horse as sold", error)
    }
    setRefresh("jnfjsndf")
    setMarkSoldModalOpen(false)
  }

  const onConfirmedStatusChange = async() => {
    try{
      await db.collection('competing-horses')
      .doc(selectedRowData.id)
      .update({status: selectedRowData.data().status === 'Active' ? 'InActive' : 'Active', listHorse: selectedRowData.data().status === 'Active' ? false : true})
      await db.collection('my-horses')
      .doc(selectedRowData.data().ownerUid)
      .collection('horses')
      .doc(selectedRowData.id)
      .update({status: selectedRowData.data().status === 'Active' ? 'InActive' : 'Active', listHorse: selectedRowData.data().status === 'Active' ? false : true})
      setRefresh(selectedRowData.id + selectedRowData.data().status);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <Container className='page-container'>
      <Row className='content'>
        <Table 
          className='col-xl-12' 
          onRowClick={onRowClick} 
          refresh={refresh} 
          markSoldModalOpen={markSoldModalOpen} 
          setMarkSoldModalOpen={setMarkSoldModalOpen}
          setSelectedRowData={setSelectedRowData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Row>
      <MDBModal isOpen={isMoldelOpen} toggle={() => setIsModelOpen(!isMoldelOpen)}>
        <MDBModalBody>
          <div className="modal-body">
              <div className="close-button" onClick={() => setIsModelOpen(!isMoldelOpen)}>X</div>
              <div className="horse-container">
                <img
                  src={`${(selectedRowData && selectedRowData.data().media) ? selectedRowData.data().media[0].url : ''}`}
                  className='horse-image-preview'
                  onClick={(event) => event.target.requestFullscreen()}
                >
                </img>
                <h4 class="price-text">{(selectedRowData && selectedRowData.data()) ? currencyFormat(selectedRowData.data().price) : ''}</h4>
              </div>
              <div className="row text-center">
                <h4 className="horse-name">{(selectedRowData && selectedRowData.data()) ? selectedRowData.data().name : ''}</h4>
              </div>
              <div className="wrapper">
                { (selectedRowData && selectedRowData.data().media) &&
                   selectedRowData.data().media.map((item) => {
                     if(item.type === 'image') {
                     return <div className="gallery-view">
                       <img src={item.url} width="70"
                        height="70"
                        onClick={(event) => event.target.requestFullscreen()}/>
                      </div>
                     } else {
                      return <div className="video-view">
                        <video width="70"
                          height="70"
                          controls controlslist={'nodownload'}
                          disablePictureInPicture={true}
                          onPlay={(event) => event.target.requestFullscreen()}
                        >
                          <source src={item.url}></source>
                        </video>
                      </div>
                     }
                   })
                }
              </div>
              <div>
                <div className="horse-data">
                  <h4 className="row">Description:</h4>
                  <p className="row text-style">
                    {(selectedRowData && selectedRowData.data()) ? selectedRowData.data().description : ''}
                  </p>
                </div>
                <div className="horizontal-line"></div>
                <div className="horse-data">
                  <div className="row content-details">
                    <div>
                    <h6>Owner: </h6> {(selectedRowData && selectedRowData.data()) ?  selectedRowData.data().owner : ''}
                      <h6>Age: </h6> {(selectedRowData && selectedRowData.data()) ?  selectedRowData.data().age : ''}
                      <h6>Color: </h6> {(selectedRowData && selectedRowData.data()) ?  selectedRowData.data().color : ''}
                    </div>
                    
                    <div>
                      <h6>Type: </h6> {(selectedRowData && selectedRowData.data()) ?  selectedRowData.data().horseDiscipline + (selectedRowData.data().disciplineType && '-' + selectedRowData.data().disciplineType) : ''}
                      <h6>Gender: </h6> {(selectedRowData && selectedRowData.data()) ?  selectedRowData.data().gender : ''}
                      <h6>Competion Level: </h6> {(selectedRowData && selectedRowData.data()) ?  selectedRowData.data().competitionLevel : ''}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="horizontal-line"></div>
              
                <h4 className="center-text">EVENTS</h4>
            
              <div className="horizontal-line"></div>
              {(selectedRowData && selectedRowData.data().events) ? 
                <>
                {
                  selectedRowData.data().events.map((item) => {
                  return (<>
                    <EventItem event={item}/>
                    <div className="horizontal-line"></div>
                  </>)
                  })
                }
                </>
                :
                <>
                  <p className="text-center">No Upcoming Events</p>
                  <div className="horizontal-line"></div>
                </>
              }
              <div className="row">
                <img
                    className='owner-photo'
                    src={(selectedRowData && selectedRowData.data()) ?  selectedRowData.data().ownerPhoto : ''}
                />
              </div>
              <div className="owner-info">
                <h2 className="user-detail">{(selectedRowData && selectedRowData.data()) ? selectedRowData.data().owner : ''}</h2>
                <p className="user-detail">Email: <a href={`mailto:${(selectedRowData && selectedRowData.data()) ? selectedRowData.data().ownerEmail : ''}`}>{(selectedRowData && selectedRowData.data()) ? selectedRowData.data().ownerEmail : ''}</a></p>
                <p className="user-detail">Contact:<a href={`tel:+1 ${(selectedRowData && selectedRowData.data()) ? selectedRowData.data().ownerPhone : ''}`}>{(selectedRowData && selectedRowData.data()) ? selectedRowData.data().ownerPhone : ''}</a></p>
                <p className="user-detail">Home Town: {(selectedRowData && selectedRowData.data()) ? selectedRowData.data().ownerHomeTown : ''}
                </p>
              </div>
              <div className="listing-buttons">
                <Button
                  className="button-style"
                  variant="outline-danger"
                  onClick={onDeleteListing}
                >DELETE LISTING</Button>
                <Button
                  className="button-style"
                  
                  onClick={onListingStatusChange}
                >{(selectedRowData && selectedRowData.data().status === 'Active') ? 'Listing Active' : 'Listing InActive'}</Button>
              </div>
            </div>
        </MDBModalBody>
      </MDBModal>
      <MarkSoldModal 
        modalOpen={markSoldModalOpen} 
        setModalOpen={setMarkSoldModalOpen} 
        onConfirm={() => onMarkSold(selectedRowData)}
        horseDocument={selectedRowData}
      />
    </Container>
  );
};

export default ManageListings;
