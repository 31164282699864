import { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import {LoginModal} from "../Login/LoginModal"
import { auth } from '../../firebase'
import logo from '../../res/logo.png'
import { Nav, Navbar, Image } from "react-bootstrap"
// import {LoginModal} from "../Login/LoginModal"
import styles from './styles'



export const Header = () => {
    const [signUpBtn, setSignUpBtn] = useState()
    const [loginBtn, setLoginBtn] = useState()
    const [loginOpen, setLoginOpen] = useState(false)
    const [isLogin, setIsLogin] = useState(true)
    const [loggedIn, setLoggedIn] = useState(false)
    const history = useHistory()
    const handleLoginOpen = () => {
        // setSignUpBtn(false)
        if (!signUpBtn) {
            setIsLogin(true)
        }
        setLoginOpen(!loginOpen)
        //loginOpen ? setLoginOpen(false) : setLoginOpen(true)
    }

    useEffect(() => {
        if(auth.currentUser !== null) {
            setLoggedIn(true)
        } else {
            setLoggedIn(false)
        }
    }, [])

    useEffect(() => {
        if (signUpBtn) {
            setIsLogin(false)
        } 
    }, [signUpBtn])

    useEffect(() => {
        if (loginBtn) {
            setIsLogin(true)
        }
    }, [loginBtn])

    useEffect(() => {
        if (!loginOpen) {
            setSignUpBtn()
            setLoginBtn()
        }
    }, [loginOpen])

    const handleSignupOpen = () => {
        setSignUpBtn(true)
        // setIsLogin(false)
        handleLoginOpen()
    }

    const handleLogout = () => {
        auth.signOut()
        setLoggedIn(false)
        history.push('/')
    }

    // const handleSettings = () => {
    //     setSettingsOpen(true)
    // }

    return (
        <header >
            <Navbar className="py-2 d-flex justify-content-end align-items-center" collapseOnSelect expand="lg" bg="black" variant="light" style={{height: 65}}>
                <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <div style={{borderRadius: 100, backgroundColor: 'black', width: 200, height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft:20, paddingTop: 50, marginLeft: 25 }}>
                        <Navbar.Brand href="#home">
                            <Link to="/">
                                <Image src={logo} rounded width='175'
                                height='175'
                                className="d-inline-block align-top"/>
                            </Link>
                        </Navbar.Brand>
                    </div>


                    
                </div>
                
                <div style={{position: 'relative'}}>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{background: '#fff', color: 'black'}}/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav activeKey="/home" >
                            <Nav.Item className="px-3 mt-3">
                                <Link style={styles.navItem}  to="/">Home</Link>
                            </Nav.Item>
                            {loggedIn && 
                                <Nav.Item className="px-3 mt-3">
                                    <Link style={styles.navItem}  to="/dashboard">Dashboard</Link>
                                </Nav.Item>
                            }
                            <Nav.Item className="px-3 mt-3">
                                {loggedIn ?
                                    <Link style={styles.navItem} to="/" onClick={handleLogout}>Log Out</Link> :
                                    <Link style={styles.navItem} to="/" onClick={handleLoginOpen}>Log In</Link>
                                }
                            </Nav.Item>
                        </Nav>
                        <LoginModal loginOpen={loginOpen} setLoginOpen={setLoginOpen} isLogin={isLogin} setIsLogin={setIsLogin} setLoggedIn={setLoggedIn}/>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            {loggedIn && 
            <div style={{display: 'flex', justifyContent: 'flex-start', background: '#DBDBDB', height: 25}}>
                <h7>Logged in as: {auth.currentUser.displayName}</h7>
            </div>
            }
            
        </header>
    )
}
