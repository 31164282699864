import React from 'react'
import { COLOR_GREENISH } from '../../utils/constants';
import styles from './styles'
import logo from '../../res/logo.png'
import { Image } from "react-bootstrap"

export default function index() {
    return (
        <>
            <h1 
              style={{ 
                  color: COLOR_GREENISH, 
                  textAlign: 'center', 
                  marginTop: 50}}
             >
              EQUINE EXCHANGE
            </h1>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                paddingLeft: 25,
                paddingRight: 25,
                justifyContent: 'center',
                background: '#fff',
                flexDirection: 'column',
              }}
            >
                <Image 
                    src={logo} rounded width='175'
                    height='175'
                    className="d-inline-block align-top"
                />
                <p style={styles.pageTitle}>CONTACT US</p>

                <p style={styles.text}>
                    For support or questions, please contact us at equineexchange3@gmail.com
                </p>
      
                <div
                    style={{
                      display: 'flex',
                      flexDirection: "column",
                      color: COLOR_GREENISH,
                      alignSelf: "center",
                      marginBottom: 20
                    }}>
                    {"\u00A9"} EQUINE EXCHANGE {new Date().getFullYear()}
                </div>
            </div>
        </>
    )
}
