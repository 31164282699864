import React, { useState } from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { db, auth, functions } from '../../../../firebase';
import { captureException } from '../../../../utils/utility';

const DeleteModal = (props) => {
  
  const { onHide, currentUser, getUsers, setUserObject, setCurrentUser } = props
  const [deleteConfirm, setDeleteConfirm] = useState("")

  const userRef = db.collection('users');
  const listingRef = db.collection('competing-horses');

  const deleteUserData = (id) => {
    const deleteAuth = functions.httpsCallable('deleteUserAuthByUidCall');
    const deleteUserData = functions.httpsCallable('deleteUserDataByUidCall');
    const deleteUserFollowers = functions.httpsCallable('deleteUserFollowerByUidCall');
    const deleteUserListedHorses = functions.httpsCallable('deleteUserListedHorsesByUidCall');
    const deleteUserHorses = functions.httpsCallable('deleteUserHorsesByUidCall');
    const deleteUserFavoritedHorses = functions.httpsCallable('deleteUserFavoriteHoresesByUidCall');
    deleteUserFollowers({ "uid": id })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("Error deleting user followers and following: ", error, error.code, error.message));
    deleteUserHorses({ "uid": id })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("Error deleting user horses: ", error, error.code, error.message));
    deleteUserListedHorses({ "uid": id })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("Error deleting user listed horses: ", error, error.code, error.message));
    deleteUserFavoritedHorses({ "uid": id })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("Error deleting uid from favorited horses: ", error, error.code, error.message));
    deleteUserData({ "uid": id })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("Error deleting user data: ", error, error.code, error.message));
    deleteAuth({ "uid": id })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("Error deleting user auth: ", error, error.code, error.message));
  }

  const handleDeleteUser = async () => {
    try{
      const userID = currentUser.id;
      console.log(userID)
      deleteUserData(userID)
      setDeleteConfirm("")
      onHide()
      getUsers()

    } catch (e) {
      captureException({message: 'Failed to delete user', e});
    }
  }

 

  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="edit-sponsors-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
        {currentUser && `Delete this user: ${currentUser.data().name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
      <Row className="w-75 align-items-center justify-content-between">
        <h5 className="text-center text-danger">Warning: This will permanently delete this user and their listings and cannot be undone.</h5>
        <h6 className="text-center mt-3 mb-0">Type "DELETE" into the input below to confirm</h6>
      </Row>
        <hr></hr>
      <Row className="w-100 align-items-center justify-content-center">

        <input type="text" className="form-control mt-1 w-50" id="deleteConfrim" placeholder="Type 'DELETE' here" value={deleteConfirm} onChange={e => setDeleteConfirm(e.target.value)} /> 
      
      </Row>

      </Modal.Body>
      <Modal.Footer>
        {deleteConfirm === "DELETE" ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleDeleteUser()}}>Delete</div> :
          <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3" >Delete</div>}
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal;