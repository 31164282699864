import React from 'react';
import { Link, useHistory } from "react-router-dom"
import { COLOR_GREENISH } from '../../utils/constants';
import styles from './styles'
import logo from '../../res/logo.png'
import { Image } from "react-bootstrap"

export default function TermsOfService() {

const privacyLink = <Link to="/privacy-policy" target={'blank'} style={{display: 'inline-block'}}>
                    <p style={{margin: 0, fontWeight: 'bold'}}>Privacy Policy</p>
                  </Link>
const privacyLinkQuotes = <Link to="/privacy-policy" target={'blank'} style={{display: 'inline-block'}}>
<p style={{margin: 0, fontWeight: 'bold'}}>(the “Privacy Policy”)</p>
</Link>
const adminSiteLink = <Link to="/" target={'blank'} style={{display: 'inline-block'}}>
<p style={{margin: 0, fontWeight: 'bold'}}>http://equine-exchange.com</p>
</Link>


  return (
    <>
      <h1 
        style={{ 
            color: COLOR_GREENISH, 
            textAlign: 'center', 
            marginTop: 50}}
       >
        EQUINE EXCHANGE
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          paddingLeft: 25,
          paddingRight: 25,
          justifyContent: 'center',
          background: '#fff',
          flexDirection: 'column',
        }}
      >
        <Image src={logo} rounded width='200'
                                height='200'
                                className="d-inline-block align-top"/>

        <p style={styles.pageTitle}>TERMS OF SERVICE</p>
        
        <p style={styles.text}>
        This Terms of Service (“Agreement”) is a legally binding agreement between you (“User”, “you” or “your”) andEquine Exchange, LLC (“Equine Exchange ", "we", "us" or "our") , and governs your access and use of the Equine Exchange mobile application, any related web presence(s), updates, documentation and all content, products and services (“Services”) provided or viewed by or through any of the foregoing (collectively, the “Mobile App”). It includes important information about your legal rights, and covers areas such as automatic subscription renewals, warranty disclaimers, limitations of liability, governing law and venue, and a class action waiver.  The Mobile App is being provided to you subject to all of the terms and conditions of this Agreement and Our {privacyLink}.  Without limiting the foregoing, we and our licensors retain all rights, title and interests in and to the Mobile App.
        </p>
        <p style={styles.textBold} className={'liiii'}>
        BY CLICKING THE “I ACCEPT” BUTTON OR BY ACCESSING OR USING THE MOBILE APP OR ANY SERVICES, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE REVIEWED, UNDERSTOOD AND AGREED TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.  IF YOU DO NOT AGREE TO BE BOUND BY ALL OF THE TERMS OF THIS AGREEMENT (OR IF YOU ARE YOUNGER THAN 13 YEARS OF AGE), CLICK THE “DECLINE” BUTTON AND DO NOT FURTHER ACCESS OR USE THIS MOBILE APP OR ANY SERVICES.BY CLICKING THE "I ACCEPT" BUTTON OR BY ACCESSING OR USING THE MOBILE APP OR ANY SERVICES, YOU FURTHER INDICATE THAT YOU INTEND YOUR ACT TO SERVE AS YOUR SIGNATURE TO THIS AGREEMENT AND TO HAVE THE SAME FORCE AND EFFECT AS THE USE OF A MANUAL SIGNATURE, AND IF YOU ARE ACCESSING OR USING THE MOBILE APP OR ANY SERVICES IN YOUR CAPACITY AS AN EMPLOYEE OR AGENT OF A BUSINESS ENTITY, YOU WARRANT THAT YOU HAVE ACTUAL AUTHORITY TO LEGALLY BIND SUCH ENTITY TO THIS AGREEMENT.
        </p>
        <div>
            <ol type={1} style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                
                <li style={styles.li}>
                    <p style={styles.textBold}>General</p>
                    <ol type={'a'}>
                        <li style={{display: 'inline'}}/> 
                        <li style={styles.li}>
                            
                                <b>Your Responsibilities.</b> You are solely responsible for: (a) your selection and use of the Mobile App for your purposes, including all of its features and functionality; (b) any content you generate or otherwise utilize via the Mobile App, including as to any products or services offered by or consumed by you <b>(“Your Content”); </b>                            and (c) any consequences of or associated with any of the foregoing.  Do not copy, upload, or share content unless you are confident you have the necessary rights to do so. We are not responsible for the conduct of, or content (including Your Content) provided by, you or of any other user of the Mobile App.
                            
                        </li>
                        <li style={styles.li}>
                            
                                <b>Signing Up. </b>To use many parts of the Mobile App and Services, you must first create an account <b>(“Account”). </b>Different parts of the Services may require different Accounts. You agree to provide us with accurate, complete and at all times up to date information for your Accounts. We may need to use this information to contact you.
                            
                        </li>
                        <li style={styles.li}>
                            
                                <b>Safeguards. </b>Please safeguard your Account and make sure others do not have access to your Account or passwords and other authentication credentials (collectively, “passwords”). You are solely responsible for any activity on your Account and for maintaining the confidentiality and security of your passwords. We are not liable for any acts or omissions by you or anyone else in connection with your Account. You must immediately notify us if you know or have any reason to suspect that your Account or passwords have been stolen, misappropriated or otherwise compromised or in case of any actual or suspected unauthorized use of your Account.
                            
                        </li>
                    </ol>
                </li>
                <li style={styles.li}>
                    <p style={styles.textBold}>Limited License to You.</p>
                    <ol type={'a'}>
                        <li style={{display: 'inline'}}/> 
                        <li style={styles.li}>
                            
                                <b>License Grant.</b> Subject to your continued compliance with all of the terms of this Agreement, we grant you a limited, non-exclusive, personal, non-transferable, revocable, license <b>(the “License”)</b> to access, stream, download and install the Mobile App on a personal computing device or mobile phone that you own or control <b>(a “Mobile Device”)</b>, and to use the Mobile App, and its various features and functionality, solely as permitted and intended by the Mobile App, and solely for your personal, non-commercial use, as further outlined herein.
                            
                        </li>
                        <li style={styles.li}>
                            <p>
                             <b>License Restrictions.</b> You will not, and you will not permit or assist any other person or entity to:
                            </p>
                             <ol type={'i'}>
                                <li style={{display: 'inline'}}/> 
                                <li style={styles.li}>copy the Mobile App or related content or documentation, in whole or in part;</li>
                                <li style={styles.li}>modify, translate, adapt, or otherwise create derivative works or improvements of the Mobile App;</li>
                                <li style={styles.li}>remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Mobile App; </li>
                                <li style={styles.li}>transmit, access, or communicate any data or material (including, without limitation, Your Content) that infringes any patent, trademark, trade secret, copyright, publicity right, privacy right, or other proprietary right of ours or any other person; </li>
                                <li style={styles.li}>rent, lease, lend, use for timesharing, service bureau, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Mobile App or any features or functionality of the Mobile App, including, without limitation, any content, to any third party for any reason, including by making the Mobile App available on a network where it is capable of being accessed by more than one device at any time; </li>
                                <li style={styles.li}>reverse engineer, decompile, disassemble, decode or otherwise attempt to derive or gain access to all or any part of the source code of the Mobile App and/or any content available through the Mobile App; </li>
                                <li style={styles.li}>impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; </li>
                                <li style={styles.li}>send, allow access to, or otherwise provide to anyone any obscene, pornographic or other age-inappropriate content or materials; </li>
                                <li style={styles.li}>forge headers or otherwise manipulate or modify identifiers in order to disguise the origin of any transmissions; </li>
                                <li style={styles.li}>transmit, access, view or communicate any data that you do not have a right to transmit;</li>
                                <li style={styles.li}>interfere with or disrupt any other person’s rightful use of the Mobile App;</li>
                                <li style={styles.li}>violate local, state, national or international law, rules, regulations, executive orders or policies relating to the use of the Mobile App;</li>
                                <li style={styles.li}>knowingly transmit, access, or communicate any data that contains software viruses or any other malicious computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or tele-communications equipment; or</li>
                                <li style={styles.li}>monitor traffic or make search requests in order to accumulate information about individual users of the Mobile App.</li>
                             </ol>
                            
                        </li>
                        <li style={styles.li}>
                        You hereby acknowledge and agree that Equine Exchange reserves the rights, but undertakes no obligation, to remove any content (e.g., images, video), Services, Third Party Materials (defined below) or other materials provided or viewed by or through the Mobile App, in Equine Exchange’s sole discretion and without notice to you.
                        </li>
                    </ol>
                </li>
                <li style={styles.li}>
                    <b>License to Equine Exchange.</b> You hereby freely and irrevocably grant to Equine Exchange, our affiliates and our and their successor or assigns, a nonexclusive, fully paid-up, royalty-free, perpetual, irrevocable, unlimited, worldwide right and license to, directly or indirectly, make, have made, copy, modify, create derivative works of, publicly display, perform, publish, use, and otherwise distribute and/or utilize any information, content (e.g., images, video) or other material, including, without limitation, Your Content, that you submit to the Mobile App or otherwise provide or make accessible using the Mobile App, and for Equine Exchange to authorize others to do any of the foregoing via a sublicense, subject only to the express limitations in this Agreement and in the {privacyLink} discussed below.  Without limiting the foregoing, you acknowledge and agree that Equine Exchange reserves the right, but undertakes no obligations, to modify, delete or remove, delete Your Content, in whole or in part, in our sole discretion, including, without limitation, for any violation of the restrictions of your limited License (see Section 2(b) above).   Any feedback, comments, proposed improvements or modifications or other suggestions you may provide regarding the Mobile App is entirely voluntary and Equine Exchange will be free to use such feedback, proposed improvements, modifications, comments or suggestions as it sees fit without any obligation or
                </li>
                <li style={styles.li}>
                    <b>Collection and Use of Your Information; Technical Information.</b> Our collection and use of content you generate, upload, submit or otherwise provide in connection with your access, streaming, downloading, installing, posting or any use of the Mobile App, including Your Content, is described in the Privacy Policy {privacyLinkQuotes}. By accessing, streaming, downloading, installing, posting or otherwise using in any way the Mobile App or any of the Services, you consent to the Privacy Policy.
                </li>
                <li style={styles.li}>
                    <b>Retention Policy</b>
                    <ol type={'a'}>
                        <li style={styles.li}>We reserve the right, but undertakes no obligation, to retain indefinitely any information you generate or otherwise make accessible in connection with the Mobile App, including any of Your Content, along with any basic profile/personal information, your transactions, Mobile Device information and other information outlined in the Privacy Policy, in our sole discretion.  Without limiting the foregoing, we also reserve the right, but undertakes no obligations, to delete such materials and any accompanying information.  Please note this voluntary retention policy is subject to change to accommodate our current storage capacity and processing abilities without any notice to you.</li>
                        <li style={styles.li}>Notwithstanding Section 5(a) above, we are not responsible or otherwise liable for the loss of, deletion of, unauthorized access to, or the failure to store or otherwise protect any of Your Content or information. You are encouraged to keep an alternative back-up copy of any of Your Content and/or other information, if retaining a copy is important to you.</li>
                    </ol>
                </li>
                <li style={styles.li}><b>Geographic Restrictions.</b>  The Mobile App is intended for use by persons only while located within the United States of America, its territories and possessions (collectively, the <b>“United States”</b>). The Mobile App may not comply with the laws of other countries or jurisdictions. If you access or use the Mobile App while outside the United States, you are solely responsible for complying with all applicable local laws.</li>
                <li style={styles.li}><b>Updates.</b>  We may, but undertake no obligations to, at any time add, remove, change or other modify features and/or functionality of the Mobile App, including, without limitation, certain content and/or any Services, through the use of bug fixes, patches and other updates (the <b>“Updates”</b>), with no notice to you.  Based on your Mobile Device settings, for any such Updates when your Mobile Device is connected to the internet either: (a) the Mobile App will automatically download and install all available Updates; or (b) you may receive notice of or be prompted to download and install available Updates. If you do not promptly download and install all Updates, the Mobile App may not operate properly.</li>
                <li style={styles.li}>
                    <b>Third Party Sites, Services and Materials.</b>
                    <ol type={'a'}>
                        <li style={styles.li}><b>Third Party Materials.</b>  The Mobile App may display, include or otherwise make available third-party content (including, without limitation, property, images, data, information, applications and other products services and/or materials) or provide links to third-party websites or services, including through third-party advertising <b>(“Third Party Materials”)</b>, such as Twitter or other social media sources.  We are not responsible for any Third Party Materials or their sources, including, without limitation, their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect.  We do not assume and will not otherwise have any liability or responsibility to you or to any other person or entity for any Third Party Materials.  Third Party Materials and any links to Third Party Materials are provided solely as a convenience and courtesy to you and you access and use them at entirely your own risk and subject to the third parties’ applicable terms and conditions.</li>
                        <li style={styles.li}><b>Third Party Services.</b> You may be provided with various third party services and applications (collectively, <b>“Third Party Services”</b>) via the Mobile App that may make their content, products or services available to you. Examples of Third Party Services include e-commerce payment processors and other integration partners and service providers. These Third Party Services may have their own terms and policies, and your use of them will be governed by those terms and policies. Any information that a Third Party Service collects, stores and processes from you will be subject to such Third Party Service’s terms of service, privacy notice, or similar terms, and will not be subject to our {privacyLink}. Therefore, please evaluate and ensure you trust each Third Party Service prior to interacting or providing any information with same. Each Third Party Service is solely responsible for providing all support, maintenance and technical assistance to you with respect to their services. When using Third Party Services, your security is your responsibility. We don't control Third Party Services, and are not and will not be liable for Third Party Services or for any transaction you may enter into with them, or for what they do. We may receive a revenue share from Third Party Services that we recommend to you or that you otherwise engage via the Services. You agree that we may, at any time and in our sole discretion, and without any notice to you, suspend, disable access to or remove any Third Party Services. We are not and will not be liable for any such suspension, disabling or removal, including without limitation for any loss of profits, revenue, data, goodwill or other intangible losses, or business disruption, costs or expenses you may incur or otherwise experience as a result (except where prohibited by applicable law).</li>
                    
                    </ol>
                </li>
                <li style={styles.li}>
                    <b>Fees.</b>
                    <ol type={'a'}>
                        <li style={styles.li}><b style={{background: '#fff'}}>Fees.</b> You can access certain portions and features of the Mobile App/Services by subscribing and selecting Your tier and associated level of payment (such features and services, <b>“Paid Services”</b>). Paid Services will remain in effect until cancelled or terminated in accordance with this Agreement. You may cancel Paid Services at any time through the Apple App Store or Google Play Store..  If you do not make timely payments for the Paid Services, we reserve the right to suspend or cancel your access to the Paid Services/Account. Transaction fees and additional fees may also apply to certain portions of the Services. Our fees are billed through your mobile app store (e.g., Apple Store), unless otherwise indicated. Please note that different tiers of Paid Services have different associated fees.</li>
                        <li style={styles.li}><b>Taxes.</b> All fees are exclusive of applicable national, provincial, state, local or other taxes <b>(“Taxes”)</b>, unless explicitly stated otherwise. You are and will remain responsible for all applicable Taxes, and we will charge Taxes in addition to the fees for the Services when required to do so. If you are exempt from certain Taxes, you must provide us with valid tax exemption documentation. We reserve the right to determine if the documentation provided is valid. Our application of Tax exemption will only apply from and after the date we receive such valid documentation.</li>
                        <li style={styles.li}><b>Automatic Subscription Renewals.</b> To ensure uninterrupted Services we will automatically bill you for Your current tier level for Paid Services each month from the date you select Your tier until cancellation or you change Your tier level of subscription. We will automatically charge you the applicable amount through Your mobile app store ((e.g., Apple Store) and by agreeing to this Agreement, you authorize us to do this.</li>
                        <li style={styles.li}><b>Fee Changes.</b> We may change our fees for any subscription tier level of Paid Services at any time by updating our Subscription Tier Level section. New fees will not apply retroactively. If you do not agree to the change in fees, you may cancel or change subscription tier levels of the applicable Paid Service before your next payment date.  If you do not cancel or change, the new fees will apply to you upon your next monthly payment.</li>
                        <li style={styles.li}><b>Our Payment Processor.</b> We use third party payment processors (each, a <b>“Payment Processor”</b>) to bill you through a payment account linked to your Account, such as your mobile app store (e.g., Apple Store). The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor, in addition to this Agreement.  You agree to pay us, through the Payment Processor, all charges at the prices then in effect for any Paid Services and any purchases in accordance with the applicable payment terms. We reserve the right to 
correct, or to instruct our Payment Processor to correct, any errors or mistakes, even if payment has already been requested or received.</li>
                    </ol>
                </li>
                <li style={styles.li}>
                    <b>Term; Termination; Effect of Termination.</b>
                    <ol type={'a'}>
                        <li style={styles.li}>
                            <p>The License described in Section (2) (a) above begins when you download the Mobile App and indicate your agreement to the terms of this Agreement, and will continue until it has terminated as follows:</p>
                            <ol type={'i'}>
                                <li style={styles.li}>the License will terminate immediately and automatically without any notice if you terminate this Agreement or otherwise attempt to or revoke your agreement to same;</li>
                                <li style={styles.li}>we may terminate the License at any time without notice, for any reason or for no reason, in our sole discretion; or</li>
                                <li style={styles.li}>you may terminate the License at any time by uninstalling and making no further use of the Mobile App, provided that the terms of this Agreement shall still apply to your use of the Mobile App that occurred prior to your termination of access/use.</li>
                            </ol>

                        </li>
                        <li style={styles.li}>Upon termination of the License for any reason, you agree to immediately stop accessing or otherwise using the Mobile App and will erase the Mobile App from your Mobile Device. </li>
                        <li style={styles.li}>The other provisions of this Agreement will continue in full force and effect after termination of the License.  Without limiting the foregoing, termination of the License will not limit our rights or remedies at law or in equity or release you from any claim for breach of the License, liability or other provision of this Agreement that arose before the termination of the License.</li>

                    </ol>

                </li>
                <li style={styles.li}>
                    <b>No Warranty; No Liability for Damages.</b>
                    <ol type={'a'}>
                        <li style={styles.li}><i>No Warranty.</i> THE MOBILE APP AND ANY RELATED CONTENT OR MATERIALS ARE PROVIDED “AS IS” AND “AS AVAILABLE”, AND EQUINE EXCHANGE MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO ANY OF THE SAME, INCLUDING, WITH RESPECT TO THE MOBILE APP, THAT THE MOBILE APP WILL BE COMPATIBLE OR OTHERWISE WORK WITH ANY DEVICE OR ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OR IS FIT FOR A PARTICULAR PURPOSE, AND WITH RESPECT TO THE INFORMATION CONTENT, INCLUDING THE ACCURACY OR COMPLETENESS OF SUCH INFORMATION. EQUINE EXCHANGE, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS THE WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR ANY PARTICULAR PURPOSE, TITLE, COURSE OF DEALING, AND USAGE IN THE TRADE. THESE DISCLAIMERS ARE AN ESSENTIAL PART OF THIS AGREEMENT. EQUINE EXCHANGE CANNOT AND DOES NOT WARRANT THE MOBILE APP AND RELATED SERVICES WILL ALWAYS BE SECURE OR ERROR-FREE OR THAT THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS OR IMPERFECTIONS.</li>
                        <li style={styles.li}><i>No Liability for Damages.</i> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL EQUINE EXCHANGE, ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS AND/OR DIRECTORS, BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR: (i) ANY LOST PROFITS, LOSS OF USE, COST OF CURE, DIMINUTION OF VALUE, LOSS OF DATA, OR FOR ANY DIRECT, INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND, HOWEVER CAUSED, ARISING OUT OF OR RELATED TO YOUR USE OR MISUSE OF OR INABILITY TO USE THE MOBILE APP, YOUR PURCHASE OF GOODS OR SERVICES IN CONNECTION WITH THE MOBILE APP, OR ANY RELATED INFORMATION, CONTENT OR MATERIALS, INCLUDING YOUR CONTENT, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, EVEN IF THOSE DAMAGES WERE FORESEEABLE AND EVEN IF EQUINE EXCHANGE WAS ADVISED OF THE POSSIBILITY OF THOSE DAMAGES; OR (ii) MONEY DAMAGES, HOWEVER CAUSED, ARISING OUT OF OR RELATED TO THIS AGREEMENT OR TO YOUR USE OR MISUSE OF OR INABILITY TO USE THE MOBILE APP, OR ANY RELATED INFORMATION, CONTENT OR MATERIALS, IN A TOTAL AMOUNT GREATER THAN $10.00 IN THE AGGREGATE.</li>


                    </ol>

                </li>
                <li style={styles.li}><b>Choice of Law; Venue; Injunctive Relief.</b>  This Agreement will be interpreted under, and any dispute arising out of this Agreement or the Mobile App will be governed by, the laws of the State of Texas, without reference to its or any other conflict of laws principles. You hereby consent to the exclusive jurisdiction of and venue in the state and federal courts located in Dallas County, Texas, and will not assert any claim against Equine Exchange in any other courts or jurisdictions throughout the world. Any dispute will be limited to the dispute between Equine Exchange and you individually. To the full extent permitted by applicable law, no legal proceeding will be joined with any other person or entity or decided on a class-action basis.  Your breach or threatened breach of this Agreement could cause irreparable injury to Equine Exchange for which money damages alone would be an inadequate remedy. In that case, Equine Exchange will, in addition to its remedies at law, be entitled to temporary or permanent injunctive or other equitable relief against you, without the posting of any bond or other security.</li>
                <li style={styles.li}><b>Indemnification.</b> You hereby agree that you (a) will indemnify, defend (at our sole option) and hold harmless, and (b) hereby release and hold harmless Equine Exchange, its affiliates and licensors, and its and their respective officers, directors, members, employees, agents, affiliates, successors and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, obligations, costs and/or expenses (including reasonable legal fees) which result from or arise out of your: use or misuse of the Mobile App; use or misuse of any related information, content (including, without limitation, Your Content), materials; related activities; or violation of this Agreement.  Equine Exchange will not represent you in any dispute or arising out of the use of the Mobile App.  We reserve the right to assume control of the defense of any third-party claim that is subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.  Furthermore, Equine Exchange assumes no responsibility for any goods or Services you purchase or utilize through or in connection with the Mobile App and/or the content you submit or make available through or utilizing the Mobile App, including, without limitation, Your Content.</li>
                <li style={styles.li}>
                    <b>Miscellaneous.</b>
                    <ol type={'a'}>
                        <li style={styles.li}><i>Severability.</i>  If any provision of this Agreement is held invalid or unenforceable, in whole or in part, that provision will be modified to the minimum extent necessary to make it valid and enforceable, and the validity and enforceability of all other provisions of this Agreement will not be affected thereby.</li>
                        <li style={styles.li}><i>Entire Agreement.</i>  This Agreement constitutes the entire agreement between you and Equine Exchange relating to the subject matter of this Agreement, and it supersedes all prior oral and written and contemporaneous oral negotiations, commitments and understandings of the parties.</li>
                        <li style={styles.li}><i>Amendments.</i>  Equine Exchange may amend this Agreement at any time by posting an updated copy of this Agreement on its website, currently located at [{adminSiteLink}] or by providing notice to you in any other way.  Those amendments will be effective upon notice to you or by your continued use of the Mobile App.  Equine Exchange will not be bound by any amendment to this Agreement unless that amendment is in writing and has been signed by an authorized representative of Equine Exchange.</li>
                    </ol>
                </li>
                <li style={styles.li}><b>Contacting Us.</b>  If you have any questions about this Policy, please contact us at equineexchange3@gmail.com</li>
            </ol>
        </div>
        
        <p style={styles.textItalics}>
            Version: This document was last updated on July 20th, 2021
        </p>
        <div
            style={{
              display: 'flex',
              flexDirection: "column",
              color: COLOR_GREENISH,
              alignSelf: "center",
              marginBottom: 20
            }}>
            {"\u00A9"} EQUINE EXCHANGE {new Date().getFullYear()}
        </div>
      </div>
    </>
  );
}