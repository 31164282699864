import React, { useState, useEffect } from 'react';
import { MDBDataTable, MDBBtn, MDBIcon} from 'mdbreact';
import { db } from '../../../../firebase';
import { IoMdTrash } from 'react-icons/io';
import Loader from "react-js-loader";
import {currencyFormat} from '../../../../utils/utility';

const Table = ({onRowClick, refresh='', setMarkSoldModalOpen, markSoldModalOpen, setSelectedRowData, isLoading, setIsLoading}) => {
  const [table, setTable] = useState({});

  const renderButton = (horseDocument) => {
    return (
      <div>
        {horseDocument &&
          <MDBBtn 
            color={horseDocument.data().markedSold ? "gray" : "primary"} 
            style={{paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, width: 90, fontWeight: "bold"}} 
            disabled={horseDocument.data().markedSold}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              setMarkSoldModalOpen(() => !markSoldModalOpen)
              setSelectedRowData(horseDocument)
            }}
          >
            {horseDocument.data().markedSold ? "SOLD" : "MARK SOLD"}
          </MDBBtn>
        }
      </div>
    )
  }

  let tableColumns = {
    columns: [
      {
        label: 'LISTING NAME',
        field: 'name',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'PRICE',
        field: 'price',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'OWNER',
        field: 'owner',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'EMAIL',
        field: 'email',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'DISCIPLINES',
        field: 'disciplines',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'STATUS',
        field: 'status',
        sort: 'asc',
        width: 75,
      },
      {
        label: 'ACTIONS',
        field: 'actions',
        sort: 'asc',
        width: 90,
      },
    ],
  };
  //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //filter array and then set table state

  let tableRows = {
    rows: [{}],
  };
  
  const getListing = async () => {
    try{
    setIsLoading(true);
    const snapshot = await db.collection('competing-horses').get();
    snapshot.docs.map(async(doc) => {
      if(doc !== undefined && doc.data() !== undefined) {
        let listing = {
          name: (
            <div>
              <span>
                {doc.data().media &&
                <img
                  src={`${doc.data().media ? doc.data().media[0].url : ''}`}
                  className='horse-image'
                ></img>
                }
              </span>
              {doc.data().name}
            </div>
          ),
          price: currencyFormat(doc.data().price),
          owner: doc.data().owner,
          email: doc.data().ownerEmail,
          disciplines: doc.data().horseDiscipline,
          status: doc.data().status,
          actions: renderButton(doc),
          uid: doc.data().uid,
          clickEvent: () => onRowClick(doc)
        };
        tableRows.rows.push(listing);

      }
    });
    // console.log(array);

    //table rows var will have array of current objects with current uid if needed to match for deletion or edit
    //filter array and then set table state
    setTable({ ...tableColumns, ...tableRows });
  }
  catch (error) {

  }
  finally {
    setIsLoading(false);
  }
  };

  useEffect(() => {
    console.log('getListing is called ');
    getListing();
  }, [refresh]);

  return (
    <div className="loader">
    { isLoading &&
      <Loader type="spinner-cub" bgColor={"#43BC93"} title={"spinner-circle"} size={100} />
    }
    <MDBDataTable
      className="hover-row col-xl-10"
      entries={8}
      entriesOptions={[8]}
      noBottomColumns
      fixed
      data={table}
    />
    </div>
  );
};

export default Table;
