import * as React from 'react';

export default function SvgDropdown(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400 400'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <path
        id='prefix__Path_1167'
        data-name='Path 1167'
        className='prefix__cls-1'
        d='M199.68 348.4C117.4 349 50.27 282 49.74 198.91c-.5-76.91 56.45-141.78 132.08-150.5a5.13 5.13 0 011.3 10.14C107 67.84 52.7 137.73 61.9 214.65s78.38 131.74 154.53 122.45S346.85 257.92 337.65 181C330 116.85 279.93 66.3 216.43 58.55a5.14 5.14 0 011.2-10.14c81.75 9.42 140.45 84 131.13 166.57-8.63 76.39-72.87 133.93-149 133.42'
      />
      <path
        className='prefix__cls-1'
        d='M150.96 199.19h128.53v6.74H150.96zM150.96 159.65h128.53v6.74H150.96zM150.96 238.76h128.53v6.74H150.96z'
      />
      <circle className='prefix__cls-1' cx={130.4} cy={202.59} r={9.89} />
      <circle className='prefix__cls-1' cx={130.4} cy={163.02} r={9.89} />
      <circle className='prefix__cls-1' cx={130.4} cy={242.13} r={9.89} />
    </svg>
  );
}
