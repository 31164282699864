import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { StripeWorldmark} from '../../../Icons/StripeWorldmark'
import { ApplePayMark } from '../../../Icons/ApplePayMark'
import { GooglePayMark } from '../../../Icons/GooglePayMark'

import "./finances.css"

const ManageFinances = () => {
  return (
    <div className="w-100 m-0 vh-100 position-relative align-items-start justify-content-center">
      <Row className="w-100 m-5 position-absolute top-0 start-0">
        <div className="w-100 text-left titleFont">FINANCES</div>
      </Row>
      <Row className="w-100 vh-100 m-0 align-items-center">
        <Col className="m-0 d-flex justify-content-around">
          <div className="isClickable" onClick={()=> window.open("https://dashboard.stripe.com/login", "_blank")}>
            <StripeWorldmark height={75} />
          </div>
          <div className="isClickable" onClick={()=> window.open("https://www.apple.com/apple-pay/", "_blank")}>
            <ApplePayMark height={75} />
          </div>
          <div className="isClickable" onClick={()=> window.open("https://console.cloud.google.com/home/dashboard?authuser=2&project=equine-trader ", "_blank")}>
            <GooglePayMark height={75} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ManageFinances;
