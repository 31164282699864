import React, { useState, useEffect } from 'react'
import { db, auth } from '../../../firebase';
import { captureException } from '../../../utils/utility';

export default function ManageOnboarding() {
    const dbRef = db.collection('upload-requirements').doc('onboard-video')
    const [videoId, setVideoId] = useState("")
    const [url, setUrl] = useState("")
    const [savedUrl, setSavedUrl] = useState("")
    const [message, setMessage] = useState({type: '', text: ''})
 
    useEffect(() => {
        // getVideoInfo()
    },[])

    const getVideoInfo = () => {
        dbRef.get()
        .then((res) => {
            setSavedUrl(res.data().url)
        })
    }

    const updateUrl = (value) => {
        let newVideoId
        const newUrl = value
        const startIndex = newUrl.indexOf('=')

        newVideoId = value.slice(startIndex + 1)
        
        setUrl(newUrl)
        setVideoId(newVideoId)
    }

    const handleSave = () => {
        if(url.length) {
            dbRef.update({videoId: videoId, url: url})
            .then((res) => {
                setMessage({type: 'success', text: `Changes Saved`})
                setSavedUrl(url)
            })
            .catch((error) => {
                console.log('error', error)
                setMessage({type: 'error', text: `Error ${error}`})
                captureException({message: 'Failed to save on boarding video', e: error});
            })
        } else {
            setMessage({type: 'error', text: `Please enter a valid Url`})
        }
    }

    useEffect(() => {
        console.log('video id', videoId)
    }, [videoId])

    return (
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '100%'}}>
            <div style={{width: 500, height: 400, marginLeft: 100}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <p style={{marginRight: 20, fontSize: 20, borderBottom: '3px solid gray'}}>Youtube Video Url:</p>
                </div>
                <p style={{fontWeight: 'bold'}}>{savedUrl}</p>
                <input  type="text" className="form-control mt-1" id="editUrl" value={url} onChange={e => updateUrl(e.target.value)} />
                <div style={{borderRadius: 6}} className="manageDropdownButton px-3 py-2 mt-3" onClick={handleSave}>Save</div>
                <p style={message.type === 'success' ? {margin: 10, color: "#43BC93"} : {margin: 10, color: "red"}}>{message.text}</p>
            </div>
        </div>
        
    )
}
