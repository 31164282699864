import './calendar.css';
import { Container, Row } from 'react-bootstrap';
// import Table from './Table/index';
import PopularEvents from './Popular Events';

const ManageCalendar = () => {
  return (
    <Container className='calendar-page-container'>
      <Row className='calendar-content'>
        <PopularEvents />
      </Row>
    </Container>
  );
};

export default ManageCalendar;
