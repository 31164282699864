import React, { useState, useEffect } from 'react';
import "./manageDropDown.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { db, storage } from '../../../firebase';

const ManageDropDown = () => {
  const [addDisciplines, setAddDisciplines] = useState("")
  const [addColor, setAddColor] = useState("")
  const [isChanged, setIsChanged] = useState(false);
  const [disciplinesTypesModalShow, setDisciplinesTypesModalShow] = useState(false);
  const [disciplinesLevelsModalShow, setDisciplinesLevelsModalShow] = useState(false);
  const [disciplinesImageModalShow, setDisciplinesImageModalShow] = useState(false);
  const [disciplinesColorsModalShow, setDisciplinesColorsModalShow] = useState(false);
  const [disciplinesAgesModalShow, setDisciplinesAgesModalShow] = useState(false);
  const [deleteDisciplinesModalShow, setDeleteDisciplinesModalShow] = useState(false);
  const [addAge, setAddAge] = useState("")
  const [newTypes, setNewTypes] = useState([]);
  const [newLevels, setNewLevels] = useState([]);
  const [newColors, setNewColors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadingDiscipline, setLoadingDiscipline] = useState(false);
  const [loadingColor, setLoadingColor] = useState(false);
  const [loadingAge, setLoadingAge] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [newDisciplines, setNewDisciplines] = useState([]);
  const [tableID, setTableID] = useState(null)
  const [currentDropdown, setCurrentDropdown] = useState(null)
  const [currentDropdownType, setCurrentDropdownType] = useState(null)
  
  const [table, setTable] = useState({});

  //Function to get dropdown info from firebase
  const getDropdowns = async () => {
    const dropdownRef = db.collection('my-horse-listing-dropdowns');
    const snapshot = await dropdownRef.get();
    console.log("snapshot", snapshot)
    snapshot.forEach((doc) => {
      setTableID(doc.id)
      //console.log(doc.data())
      setTable(doc.data())
      setLoaded(true)
    });
  };

  const handleDeleteItem = (item, type) => {
    setCurrentDropdown(item)
    setCurrentDropdownType(type)
    setDeleteDisciplinesModalShow(true)
  }

  const handleDeleteNewItem = (item, type) => {
    if (type === "newType") {
      const editTypes = newTypes
      const itemIndex = newTypes.findIndex(type => type.text === item.text)
      // console.log(itemIndex)
      editTypes.splice(itemIndex, 1)
      // console.log(editTypes)
      setNewTypes([...editTypes])
    } else if (type === "newLevel") {
      const editLevel = newLevels
      const itemIndex = newLevels.findIndex(type => type.text === item.text)
      editLevel.splice(itemIndex, 1)
      setNewLevels([...editLevel])
    }
  }

  const updateDropdowns = async (obj) => {
    setLoadingSave(true)
    const dropdownRef = db.collection('my-horse-listing-dropdowns').doc(tableID)
    
    return dropdownRef.update(obj)
    .then(() => {
      console.log("Document successfully updated!");
      setIsChanged(false)
      setLoadingSave(false)
  })
  .catch((error) => {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
      setIsChanged(false)
  });
  }

  const uploadNewDropdowns = async () => {
    const dropdownRef = db.collection('my-horse-listing-dropdowns').doc('jXE8l6PiewX1VCfDoaKB');
    const res = await dropdownRef.update(prev => ({
      ...prev,
      disciplines: [...table, ...newDisciplines]
    }));
  }

  useEffect(() => {
    getDropdowns();
  }, []);

  //Function for adding to disciplines, types, levels, or colors
  const handleAdd = (title, inputValue) => {
    let newArray = [];
    if(title === "disciplines") {
      if (addDisciplines !== "") {
        newArray = [...table.disciplines];
        const newDisciplines = {
          text: addDisciplines,
          "competition-levels": [],
          types: [],
          image: "",
        }
        newArray.push(newDisciplines)
        setTable(prev => ({
          ...prev,
          disciplines: [...newArray]
        }))
        setAddDisciplines("")
      }
        } else if(title === "types") {
          if (inputValue !== "") {
            let newArray = [];
            if (newTypes.length > 0) {
              newArray = [...newTypes]
            }
            const newType = {
              text: inputValue,
            }
            newArray.push(newType)
            setNewTypes([...newArray])
          }
        } else if(title === "levels") {
          if (inputValue !== "") {
            let newArray = [];
            if (newLevels.length > 0) {
              newArray = [...newLevels]
            }
            const newType = {
              text: inputValue,
            }
            newArray.push(newType)
            setNewLevels([...newArray])
          }
        } else if(title === "colors") {
          if (inputValue !== "") {
            newArray = [];
            if (newColors.length > 0) {
              newArray = [...newColors]
            }
            const newColor = {
              color: inputValue,
              url: "",
            }
            newArray.push(newColor)
            setNewColors([...newArray])
          }
        } 
  }
  
  //Component to render each list item with checkbox logic
  // Logic not in use currently due to DB object - all items return checked
  // DisciplineListItem will still return nested list item for Types and Levels
  const DisciplineListItem = ({item, title}) => {

      //checkbox logic for disciplines
      if(title === "disciplines") {
        return (
          <div className="manageDropdownListItem checkedText my-2">
            <div className="manageDropdownCheckbox checked-discipline" onClick={() => {handleDeleteItem(item, "Discipline")}}></div>
            <a href={item.image} rel="noreferrer" target="_blank">{item.text}</a>
          </div>
        )
      }
      //checkbox logic for types
      if(title === "types") {        
        return (
          <div className="subListContainer mb-2">
            <div className="manageDropdownListItem checkedText">
              <div className="manageDropdownCheckbox checked"></div>
              {item.text}
            </div>
              <div className="pl-4">
                {item.types.map(type => {
                  return <div className="manageDropdownListItem checkedText my-1">
                    <div className="manageDropdownCheckbox checked-light-discipline"></div>
                    {type.text}
                  </div>
                })}
              </div>
          </div>
        )
      }
      //checkbox logic for new types in Modal
      if(title === "new types") {        
        return (
          <div className="manageDropdownListItem checkedText my-2">
            <div className="manageDropdownCheckbox checked-discipline" onClick={() => {handleDeleteNewItem(item, "newType")}}></div>
            {item.text}
          </div>
        )
      }
      //checkbox logic for colors
      if(title === "colors") {
        if(item.url === "") {
          return (
            <div className="manageDropdownListItem checkedText my-2">
              <div className="manageDropdownCheckbox checked-discipline" onClick={() => {handleDeleteItem(item, "Color")}}></div>
              {item.color}
            </div>
          )
        } else {
          return (
            <div className="manageDropdownListItem checkedText my-2">
              <div className="manageDropdownCheckbox checked-discipline" onClick={() => {handleDeleteItem(item, "Color")}}></div>
              <a href={item.url} rel="noreferrer" target="_blank">{item.color}</a>
            </div>
          )
        }
      }
      //checkbox logic for new colors
      if(title === "new colors") {        
        return (
          <div className="manageDropdownListItem checkedText my-2">
            <div className="manageDropdownCheckbox checked-discipline"></div>
            {item.color}
          </div>
        )
      }
      //checkbox logic for level
      if(title === "level") {
        const levels = item["competition-levels"]
        return (
          <div className="subListContainer mb-2">
            <div className="manageDropdownListItem checkedText">
              <div className="manageDropdownCheckbox checked"></div>
              {item.text}
            </div>
              <div className="pl-4">
                {levels.map(level => {
                  return <div className="manageDropdownListItem checkedText my-1">
                    <div className="manageDropdownCheckbox checked-light-discipline"></div>
                    {level.text}
                  </div>
                })}
              </div>
          </div>
        )
      }
      //checkbox logic for new levels in Modal
      if(title === "new levels") {        
        return (
          <div className="manageDropdownListItem checkedText my-2">
            <div className="manageDropdownCheckbox checked checked-discipline" onClick={() => {handleDeleteNewItem(item, "newLevel")}}></div>
            {item.text}
          </div>
        )
      }
      //checkbox logic for ages
      if(title === "ages") {
        return (
          <div className="manageDropdownListItem checkedText my-2">
            <div className="manageDropdownCheckbox checked-discipline" onClick={() => {handleDeleteItem(item, "Age")}}></div>
            {item}
          </div>
        )
      }
      if (!isChanged) {
        setIsChanged(true)
      }
  }

  const handleGoToNextStep = (step) => {
    if(step === "levels") {
      console.log(step)
      setDisciplinesTypesModalShow(false);
      setDisciplinesLevelsModalShow(true)
    } else if (step === "image") {
      console.log(step)
      setDisciplinesLevelsModalShow(false)
      setDisciplinesImageModalShow(true);
    } else if (step === "types") {
      console.log(step)
      setDisciplinesLevelsModalShow(false)
      setDisciplinesTypesModalShow(true);
    } else if (step === "levels-back") {
      console.log(step)
      setDisciplinesImageModalShow(false)
      setDisciplinesLevelsModalShow(true);
    } 
  }

  
  //Modal for adding Discipline Types
  const AddDisciplinesModalTypes = (props) => {
    const [addType, setAddType] = useState("")

    const handleNextStep = (step) => {
      setAddType("")
      handleGoToNextStep(step)
    }
    
    if (addDisciplines !== "") {
      return (
        <Modal
          {...props}
          size="med"
          aria-labelledby="add-disciplines-modal-add-types"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-types" className="manageDropdownActiveText">
              Add Disciplines
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center">
            <h4 className="manageDropdownActiveText">Add discipline types for {addDisciplines}</h4>
            <div className="w-25">
              {newTypes && newTypes.map(type => {
                  return <DisciplineListItem item={type} title={"new types"} />
                } )}
            </div>
            <input type="text" className="form-control mt-4 w-50" id="addDisciplineInput" value={addType} onChange={e => setAddType(e.target.value)}></input>
                <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleAdd("types", addType)}>ADD</div>
          </Modal.Body>
          <Modal.Footer>
            <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleNextStep("levels")}>Next Step</div>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (
        <Modal
          {...props}
          size="med"
          aria-labelledby="add-disciplines-modal-add-types"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-types" className="manageDropdownActiveText">
              Error
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center">
            <h4 className="manageDropdownActiveText">Please enter a new discipline name first</h4>
          </Modal.Body>
          <Modal.Footer>
            <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => setDisciplinesTypesModalShow(false)}>Close</div>
          </Modal.Footer>
        </Modal>
      )
    }
  }


  const AddDisciplinesModalLevels = (props) => {
    const [addLevel, setAddLevel] = useState("")

    const handleNextStep = (step) => {
      setAddLevel("")
      handleGoToNextStep(step)
    }

    return (
      <Modal
        {...props}
        size="med"
        aria-labelledby="add-disciplines-modal-add-levels"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText">
            Add Disciplines
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
          <h4 className="manageDropdownActiveText">Add competition levels for {addDisciplines}</h4>
          <div className="w-25">
            {newLevels && newLevels.map(level => {
                return <DisciplineListItem item={level} title={"new levels"} />
              } )}
          </div>
          <input type="text" className="form-control mt-4 w-50" id="addDisciplineInput" value={addLevel} onChange={e => setAddLevel(e.target.value)}></input>
              <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleAdd("levels", addLevel)}>ADD</div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleNextStep("types")}>Back</div><div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleNextStep("image")}>Next Step</div>
        </Modal.Footer>
      </Modal>
    );
  }

  //Modal for adding Discipline Image url
  const AddDisciplinesModalImage = (props) => {
    const [addDisciplineUrl, setAddDisciplineUrl] = useState("");
    const [imageUrlChanged, setImageUrlChanged] = useState(false);
    const [image, setImage] = useState(false);

    const handleFile = (e) => {
      setImageUrlChanged(true)
      setImage(e.target.files[0])
    }

    const handleNextStep = (step) => {
      setAddDisciplineUrl("")
      setImage(false)
      setImageUrlChanged(false)
      handleGoToNextStep(step)
    }

    const handleUpload = (e) => {
      const uploadTask = storage.ref(`sponsors/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        () => {
          storage
            .ref("sponsors")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
              console.log("url", url)
              setAddDisciplineUrl(url);
              setImageUrlChanged(true);
            });
        }
      );
    }

    const handleSaveDiscipline = async () => {
      setLoadingDiscipline(true)
      let imageUrl;
      if (image) {
        const uploadTask = storage.ref(`disciplines/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          () => {
            storage
              .ref("disciplines")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log("url", url)
                const newDisciplineObject = {
                  text: addDisciplines,
                  "competition-levels": [...newLevels],
                  types: [...newTypes],
                  image: url,
                }
                let newArray = [...table.disciplines, newDisciplineObject]
                console.log("newArray", newArray);
                setTable(prev => ({
                  ...prev,
                  disciplines: [...newArray]
                }))
                setNewDisciplines([...newDisciplines, newDisciplineObject])
                setLoadingDiscipline(false)
              });
          }
        );
      } else {
        const newDisciplineObject = {
          text: addDisciplines,
          "competition-levels": [...newLevels],
          types: [...newTypes],
          image: addDisciplineUrl,
        }
        let newArray = [...table.disciplines, newDisciplineObject]
        console.log("newArray", newArray);
        setTable(prev => ({
          ...prev,
          disciplines: [...newArray]
        }))
        setNewDisciplines([...newDisciplines, newDisciplineObject])
        setLoadingDiscipline(false)
      }
      setDisciplinesImageModalShow(false)
      setAddDisciplines("")
      setAddDisciplineUrl("")
      setNewLevels([])
      setNewTypes([])
      setImageUrlChanged(false)
      setIsChanged(true)
    }

    const handleUrl = (e) => {
      setAddDisciplineUrl(e.target.value)
      setImageUrlChanged(true)
    }

    return (
      <Modal
        {...props}
        size="med"
        aria-labelledby="add-disciplines-modal-add-levels"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText">
            Add Disciplines
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
        <h4 className="manageDropdownActiveText">Add image or paste URL for this this color</h4>
            <input
              accept="image/*"
              // className={classes.fileInput}
              id="contained-button-file"
              type="file"
              onChange={handleFile}
              
            />
            <input type="text" className="form-control mt-4 w-50" id="addDisciplineInput" placeholder="http://" value={addDisciplineUrl} onChange={e => handleUrl(e)}></input>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleNextStep("levels-back")}>Back</div>
          {imageUrlChanged ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={handleSaveDiscipline}>{loadingDiscipline && <Spinner animation="grow" size="sm" />}Save</div> :
          <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3">Save</div>}
        </Modal.Footer>
      </Modal>
    );
  }

  //Modal for adding color url
  const AddDisciplinesModalColors = (props) => {
    const [addColorUrl, setAddColorUrl] = useState("")
    const [imageUrlChanged, setImageUrlChanged] = useState(false);
    const [image, setImage] = useState(false);

    const handleFile = (e) => {
      setImageUrlChanged(true)
      setImage(e.target.files[0])
    }

    const handleUrl = (e) => {
      setAddColorUrl(e.target.value)
      setImageUrlChanged(true)
    }

    const handleSaveColor = async () => {
      setLoadingColor(true)
      let newColorObject;
      
  
      if(image){
        const uploadTask = storage.ref(`color_icons/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          () => {
            storage
              .ref("color_icons")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                console.log("url", url)
                newColorObject = {
                  color: addColor,
                  url: url,
                }
                let newArray = [...table.colors, newColorObject]
                // console.log("newArray", newArray);
                setTable(prev => ({
                  ...prev,
                  colors: [...newArray]
                }))
                setLoadingColor(false);
              });
          }
        );
      } else {
        const newColorObject = {
          color: addColor,
          url: addColorUrl,
        }
        let newArray = [...table.colors, newColorObject]
        // console.log("newArray", newArray);
        setTable(prev => ({
          ...prev,
          colors: [...newArray]
        }))
        setLoadingColor(false)
      }
      setDisciplinesColorsModalShow(false)
      setAddColor("")
      setIsChanged(true)
    }

    if(addColor !== "") {
      return (
        <Modal
          {...props}
          size="med"
          aria-labelledby="add-disciplines-modal-add-levels"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText">
              Add Image For {addColor}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center">
            <h4 className="manageDropdownActiveText">Add image or paste URL for this this color</h4>
            <input
              accept="image/*"
              // className={classes.fileInput}
              id="contained-button-file"
              type="file"
              onChange={handleFile}
              
            />
            <input type="text" className="form-control mt-4 w-50" id="addDisciplineInput" placeholder="http://" value={addColorUrl} onChange={e => handleUrl(e)}></input>
                {/* <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleAdd("colors", addColorUrl)}>ADD</div> */}
          </Modal.Body>
          <Modal.Footer>
          {imageUrlChanged ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={handleSaveColor}>{loadingColor && <Spinner animation="grow" size="sm" />}Save</div> :
          <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3">Save</div>}
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (
        <Modal
          {...props}
          size="med"
          aria-labelledby="add-disciplines-modal-add-types"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-types" className="manageDropdownActiveText">
              Error
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center">
            <h4 className="manageDropdownActiveText">Please enter a new color name first</h4>
          </Modal.Body>
          <Modal.Footer>
            <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => setDisciplinesColorsModalShow(false)}>Close</div>
          </Modal.Footer>
        </Modal>
      )
    }
  }

  //Modal for adding new age
  const AddAgeModal = (props) => {
    const hasIndex = loaded && table.ages.indexOf(addAge)
    // console.log("addAge: ", addAge)
    // console.log("ages: ", table.ages)
    // console.log(hasIndex)

    const handleSaveAge = () => {
      let newArray = [...table.ages, addAge]
      // console.log("newArray", newArray);
      setTable(prev => ({
        ...prev,
        ages: [...newArray]
      }))
      setDisciplinesAgesModalShow(false)
      setAddAge("")
      setIsChanged(true)
    }
    
    const handleCancelAge = () => {
      setAddAge("");
      setDisciplinesAgesModalShow(false)
    }

    if(addAge !== "" && hasIndex === -1) {
      return (
        <Modal
          {...props}
          size="med"
          aria-labelledby="add-disciplines-modal-add-levels"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText">
              Add Age: {addAge}
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
          <div className="manageDropdownButton px-3 py-2 mt-3" onClick={handleSaveAge}>Save</div>
          <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3 isClickable" onClick={handleCancelAge}>Cancel</div>
          </Modal.Footer>
        </Modal>
      );
    } else if (addAge !== "" && hasIndex >= 0){
      return (
        <Modal
          {...props}
          size="med"
          aria-labelledby="add-disciplines-modal-add-types"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-types" className="manageDropdownActiveText">
              Error
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center">
            <h4 className="manageDropdownActiveText">{addAge} is already in use</h4>
          </Modal.Body>
          <Modal.Footer>
            <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => setDisciplinesAgesModalShow(false)}>Close</div>
          </Modal.Footer>
        </Modal>
      )
    } else {
      return (
        <Modal
          {...props}
          size="med"
          aria-labelledby="add-disciplines-modal-add-types"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="add-disciplines-modal-types" className="manageDropdownActiveText">
              Error
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center">
            <h4 className="manageDropdownActiveText">Please enter a new age first</h4>
          </Modal.Body>
          <Modal.Footer>
            <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => setDisciplinesAgesModalShow(false)}>Close</div>
          </Modal.Footer>
        </Modal>
      )
    }
  }

  //Modal for deleting Discipline
  const DeleteDisciplineModal = (props) => {
    const [deleteConfirm, setDeleteConfirm] = useState("")


    const handleDeleteDiscipline = () => {
      let tempUpdate = {...table};
      if (currentDropdownType === "Discipline") {
        // console.log(currentDropdown)
        let disciplineArray = [...table.disciplines]
        const hasIndex =  table.disciplines.indexOf(currentDropdown)
        // console.log(hasIndex)
        if (hasIndex > -1) {
          disciplineArray.splice(hasIndex, 1)
          setTable(prev => ({
            ...prev,
            disciplines: disciplineArray
          }))
          tempUpdate.disciplines = [...disciplineArray]
        }
      } else if (currentDropdownType === "Color") {
        let colorArray = [...table.colors]
        const hasIndex =  table.colors.indexOf(currentDropdown)
        if (hasIndex > -1) {
          colorArray.splice(hasIndex, 1)
          setTable(prev => ({
            ...prev,
            colors: colorArray
          }))
          tempUpdate.colors = [...colorArray]
        }
      } else if (currentDropdownType === "Age") {
        let ageArray = [...table.ages]
        const hasIndex =  table.ages.indexOf(currentDropdown)
        // console.log(hasIndex)
        if (hasIndex > -1) {
          ageArray.splice(hasIndex, 1)
          setTable(prev => ({
            ...prev,
            ages: ageArray
          }))
          tempUpdate.ages = [...ageArray]
        }
        
      }
      updateDropdowns(tempUpdate)
      setDeleteDisciplinesModalShow(false)
      setIsChanged(true);
      setCurrentDropdown(null)
      setCurrentDropdownType(null)
    }

  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="edit-sponsors-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="add-disciplines-modal-levels" className="manageDropdownActiveText text-center">
        {currentDropdown && `Delete this ${currentDropdownType}: ${currentDropdownType === "Discipline" ? currentDropdown.text : currentDropdownType === "Color" ? currentDropdown.color : currentDropdown}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
      <Row className="w-75 align-items-center justify-content-between">
        <h5 className="text-center text-danger">Warning: This will permanently delete this {currentDropdownType === "Discipline" ? `discipline with all associated types and levels` : currentDropdownType === "Color" ? `color` : `age`} and cannot be undone.</h5>
        <h6 className="text-center mt-3 mb-0">Type "DELETE" into the input below to confirm</h6>
      </Row>
        <hr></hr>
      <Row className="w-100 align-items-center justify-content-center">

        <input type="text" className="form-control mt-1 w-50" id="deleteConfrim" placeholder="Type 'DELETE' here" value={deleteConfirm} onChange={e => setDeleteConfirm(e.target.value)} /> 
      
      </Row>

      </Modal.Body>
      <Modal.Footer>
        {deleteConfirm === "DELETE" ? <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => {handleDeleteDiscipline()}}>Delete</div> :
          <div className="manageDropdownButtonUnchanged px-3 py-2 mt-3" >Delete</div>}
      </Modal.Footer>
    </Modal>
  )
}

  //Return for Dropdown List component
  return (
    <Container id="manageDropdownBody">
      <Row className="manageDropdownHeader mt-5">
        <h3 className="manageDropdownActiveText manageDropdownPageTitle">Dropdown Lists</h3>
        { isChanged ? <div className="manageDropdownButton px-5 py-2" onClick={() => updateDropdowns(table)} >{loadingSave && <Spinner animation="grow" size="sm" />}SAVE</div> : <div className="manageDropdownButtonUnchanged px-5 py-2">SAVE</div> }
      </Row>
      <Row className="mt-5 w-100">
        <Container id="manageDropdownLists" >
          <Col className="manageDropDownListColumn pl-0">
            <div className="w-100 text-left mb-4 manageDropdownActiveText manageDropdownColTitle">DISCIPLINES</div>
            {loaded && table.disciplines.map(item => {
              return <DisciplineListItem item={item} title={"disciplines"} />
            } )}
              <input type="text" className="form-control mt-4" id="addDisciplineInput" placeholder="add discipline name" value={addDisciplines} onChange={e => setAddDisciplines(e.target.value)}></input>
              <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => setDisciplinesTypesModalShow(true)}>ADD</div>
              {/* <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => handleAdd("disciplines")}>ADD</div> */}
              <AddDisciplinesModalTypes
                show={disciplinesTypesModalShow}
                onHide={() => setDisciplinesTypesModalShow(false)}
              />
              <AddDisciplinesModalLevels
                show={disciplinesLevelsModalShow}
                onHide={() => setDisciplinesLevelsModalShow(false)}
              />
              <AddDisciplinesModalImage
                show={disciplinesImageModalShow}
                onHide={() => setDisciplinesImageModalShow(false)}
              />
              <DeleteDisciplineModal
                show={deleteDisciplinesModalShow}
                onHide={() => setDeleteDisciplinesModalShow(false)}
              />
          </Col>
          <Col className="manageDropDownListColumn">
            <div className="w-100 text-left mb-4 manageDropdownActiveText manageDropdownColTitle">DISCIPLINE TYPES</div>
            {loaded && table.disciplines.map(item => {
              if(item.types.length !== 0) {
                return <DisciplineListItem item={item} title={"types"} />
              }
            })}
          </Col>
          <Col className="manageDropDownListColumn">
            <div className="w-100 text-left mb-4 manageDropdownActiveText manageDropdownColTitle">COLORS</div>
              {loaded && table.colors.map(item => {
                return <DisciplineListItem item={item} title={"colors"} />
              } )}
              <input type="text" className="form-control mt-4" id="addColorInput" placeholder="add color name" value={addColor} onChange={e => setAddColor(e.target.value)}></input>
              <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => setDisciplinesColorsModalShow(true)}>ADD</div>
              <AddDisciplinesModalColors
                show={disciplinesColorsModalShow}
                onHide={() => setDisciplinesColorsModalShow(false)}
              />
          </Col>
          <Col className="manageDropDownListColumn">
            <div className="w-100 text-left mb-4 manageDropdownActiveText manageDropdownColTitle">COMPETITION LEVEL</div>
              {loaded && table.disciplines.map(item => {
                const levels = item["competition-levels"]
                if(levels.length !== 0) {
                  return <DisciplineListItem item={item} title={"level"} />
                }
              })}  
          </Col>
          <Col className="manageDropDownListColumn">
            <div className="w-100 text-left mb-4 manageDropdownActiveText manageDropdownColTitle">AGES</div>
              {loaded && table.ages.map(item => {
                return <DisciplineListItem item={item} title={"ages"} />
              } )}
              <input type="text" className="form-control mt-4" id="addAgeInput" placeholder="add age" value={addAge} onChange={e => setAddAge(e.target.value)}></input>
              <div className="manageDropdownButton px-3 py-2 mt-3" onClick={() => setDisciplinesAgesModalShow(true)}>ADD</div>
              <AddAgeModal
                show={disciplinesAgesModalShow}
                onHide={() => setDisciplinesAgesModalShow(false)}
              />
          </Col>
        </Container>
      </Row>
    </Container>
  );
};

export default ManageDropDown;
