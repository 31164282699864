import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { db, storage, functions } from '../../../../firebase';
import { captureException } from '../../../../utils/utility';
// import { Body } from 'react-bootstrap/lib/Media';




const DeleteListingModal = (props) => {
  const {
    setDeleteListingModalShow,
    currentUser,
    getUsers,
    userObject,
  } = props;
  const [deleteConfirm, setDeleteConfirm] = useState('');

  const listingRef = db.collection('competing-horses');
  const sponsorRef = db.collection('users');

  const updateUserToDB = async (id, obj) => {
    try {
      await sponsorRef.doc(id).update(obj);
    } catch (e) {
      captureException({ message: 'Failed to update user data', e });
    }
  };

  const updateUserStatus = (id) => {
    // console.log("id", id)
    // const requestOptions = {
    //   method: 'POST',
    //   // credentials: 'include',
    //   mode: 'no-cors',
    //   headers: { 'Content-Type': 'application/json', 'Host': 'us-central1-equine-development.cloudfunctions.net', Accept: "*/*", 'Connection': 'keep-alive' },
    //   body: { uid: id, newStatus: true }
    // };
    // console.log(requestOptions)

    // fetch("https://us-central1-equine-development.cloudfunctions.net/disableEnableUserByUid", requestOptions).then(response => console.log(response)).catch(error => console.log(error))

    const disableUser = functions.httpsCallable('disableEnableUserByUidCall');
    disableUser({ "uid": id, "newStatus": true })
      .then((result) => {
        console.log("id", id, "result", result)
      })
      .catch(error => console.log("disable user error: ", error, error.code, error.message));
  }

  const handleDeleteListings = () => {
    try {
      const userID = currentUser.id;
      const listing_query = listingRef.where('ownerUid', '==', userID);
      console.log(listing_query);
      listing_query
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            console.log(
              'Delete Horse Listing: ',
              doc.data().name,
              'horse id: ',
              doc.id
            );
            await doc.ref.delete();
          });
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
          captureException({
            message: `Failed to delete listed horse for user ${userID}`,
            e: error,
          });
        });
      updateUserToDB(userID, userObject);
        updateUserStatus(userID)
      setDeleteListingModalShow(false);
      getUsers();
      // console.log("UserObject", userObject)
    } catch (e) {
      captureException({ message: `Failed in handleDeleteListings`, e });
    }
  };

  return (
    <Modal {...props} size='med' aria-labelledby='edit-sponsors-modal' centered>
      <Modal.Header closeButton onHide={setDeleteListingModalShow}>
        <Modal.Title
          id='add-disciplines-modal-levels'
          className='manageDropdownActiveText text-center'
        >
          {userObject && `Suspend user: ${userObject.name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-center'>
        <Row className='w-75 align-items-center justify-content-between'>
          <h5 className='text-center text-danger'>
            Warning: Suspending user will delete all horse listings associated
            with this user. This cannot be undone.
          </h5>
          <h6 className='text-center mt-3 mb-0'>
            Type "CONFIRM" into the input below to confirm
          </h6>
        </Row>
        <hr></hr>
        <Row className='w-100 align-items-center justify-content-center'>
          <input
            type='text'
            className='form-control mt-1 w-50'
            id='deleteConfrim'
            placeholder="Type 'CONFIRM' here"
            value={deleteConfirm}
            onChange={(e) => setDeleteConfirm(e.target.value)}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {deleteConfirm === 'CONFIRM' ? (
          <div
            className='manageDropdownButton px-3 py-2 mt-3'
            onClick={() => {
              handleDeleteListings();
            }}
          >
            Suspend
          </div>
        ) : (
          <div className='manageDropdownButtonUnchanged px-3 py-2 mt-3'>
            Suspend
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteListingModal;
