import React from 'react';
import { MDBModal, MDBModalBody, MDBBtn} from 'mdbreact';
import {COLOR_GREENISH} from "../../utils/constants";

export default function MarkSoldModal({modalOpen, setModalOpen, onConfirm, horseDocument}) {
    const styles = {
        modalStyle: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        modalText: {
            textAlign: "center",
            // fontWeight: "bold"
        },
        buttonStyleConfirm: {
            border: `2px solid red`,
            width: "80%",
            marginBottom: 20,
            color: "red",
        },
        buttonStyleCancel: {
            border: `2px solid ${COLOR_GREENISH}`,
            width: "80%",
            marginBottom: 20,
            color: COLOR_GREENISH,
        },
    }


    return (
        <MDBModal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
            <MDBModalBody style={styles.modalStyle}>
                    <p style={styles.modalText}>Are you sure you want to mark <strong>{horseDocument?.data().name.toUpperCase()}</strong> as sold? This can't be undone!</p>
                    <MDBBtn color="#fff" style={styles.buttonStyleConfirm} onClick={onConfirm}>CONFIRM</MDBBtn>
                    <MDBBtn color="#fff" style={styles.buttonStyleCancel} onClick={() => setModalOpen(!modalOpen)}>CANCEL</MDBBtn>
            </MDBModalBody>
        </MDBModal>
    )
}
