import React, { useState } from 'react';
import { auth } from '../../firebase';
import { NavLink, useHistory } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import UserIcon from '../../Icons/UserIcon';
import StudentIcon from '../../Icons/StudentIcon';
import CoachesIcon from '../../Icons/CoachesIcon';
import ListingsIcon from '../../Icons/ListingsIcon';
import EventListIcon from '../../Icons/EventListIcon';
import EventCalendar from '../../Icons/Event_Calendar_Icon';
import DropdownIcon from '../../Icons/Dropdown_Icon';
import FinancesIcon from '../../Icons/Finances_Icon';
import SponsorsIcon from '../../Icons/Sponsors_Icon';
import logo from './logo.png';
import './sideNav.css';

const SideNav = () => {
  const history = useHistory();

  const handleLogout = () => {
    auth.signOut();
    history.push('/');
  };

  return (
    <Container id='container'>
      <Row id='row' className='header-row'>
        <article id='header'>
          <img src={logo} className='image' alt='' />
          <h2 id='icon-title'>ADMIN PORTAL</h2>
        </article>
      </Row>
      <Row id='row'>
        <article id='link-group'>
          <h2 id='title'>MANAGE USERS</h2>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/users'
          >
            <span className='svg-container'>
              <UserIcon className='svg-icon' />
            </span>
            Users
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/students'
          >
            <span className='svg-container'>
              <StudentIcon className='svg-icon' />
            </span>
            Students
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/coaches'
          >
            <span className='svg-container'>
              <CoachesIcon className='svg-icon' />
            </span>
            Coaches
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/transactions'
          >
            <span className='svg-container'>
              <FinancesIcon className='svg-icon' />
            </span>
            Payment Transactions
          </NavLink>
        </article>
      </Row>
      <Row id='row'>
        <article id='link-group'>
          <h2 id='title'>MANAGE PAGES</h2>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/listings'
          >
            <span className='svg-container'>
              <ListingsIcon className='svg-icon' />
            </span>
            Listings
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/events'
          >
            <span className='svg-container'>
              <EventListIcon className='svg-icon' />
            </span>
            Event List
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/calendar'
          >
            <span className='svg-container'>
              <EventCalendar className='svg-icon' />
            </span>
            Competitions
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/dropdowns'
          >
            <span className='svg-container'>
              <DropdownIcon className='svg-icon' />
            </span>
            Dropdown Lists
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/sponsors'
          >
            <span className='svg-container'>
              <SponsorsIcon className='svg-icon' />
            </span>
            Sponsors
          </NavLink>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/onboard'
          >
            <span className='svg-container'>
              <SponsorsIcon className='svg-icon' />
            </span>
            User Onboarding
          </NavLink>
        </article>
      </Row>
      <Row id='row'>
        <article id='link-group'>
          <h2 id='title'>MANAGE REVENUE</h2>
          <NavLink
            className='link'
            activeClassName='active-link'
            to='/dashboard/finances'
          >
            <span className='svg-container'>
              <FinancesIcon className='svg-icon' />
            </span>
            Finances
          </NavLink>
        </article>
      </Row>
      <Row id="row">
        <article
          id='link-group'
          className='link logout'
          onClick={()=>handleLogout()}
        >
          Log Out
        </article>
      </Row>
      <Row id='footer'>
        <p className='trade-mark'>
          &copy;<span>EQUITRADE </span>
          {new Date().getFullYear()}
        </p>
      </Row>
    </Container>
  );
};

export default SideNav;
