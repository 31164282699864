import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { db } from '../../../../../firebase';
import { RiPencilFill } from 'react-icons/ri';
import { IoMdTrash } from 'react-icons/io';
import Loader from "react-js-loader";
const Table = ({onEditRow, onDeleteRow, refresh=''}) => {
  const [table, setTable] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let tableColumns = {
    columns: [
      {
        label: 'EDIT/DELETE',
        field: 'edit',
        sort: 'asc',
        width: '50px',
      },
      {
        label: 'EVENT NAME',
        field: 'name',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'DATE RANGE',
        field: 'date_range',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'URL',
        field: 'url',
        sort: 'asc',
        width: 75,
      },
    ],
  };
  //table rows var will have array of current objects with current uid if needed to match for deletion or edit
  //filter array and then set table state
  let tableRows = {
    rows: [{}],
  };

  //{TODO: add loading status for data table load}
  
  const getAdminEvents = async () => {
    try{
      setIsLoading(true);
      const eventsRef = db.collection('admin-events');
      const snapshot = await eventsRef.get();
      snapshot.forEach((doc) => {
        let event = {
          edit: (
            <div className='calendar-icon-group'>
              <RiPencilFill className='calendar-pencil' onClick={() => onEditRow(doc)}/>
              <IoMdTrash onClick={() => onDeleteRow(doc.id)}/>
            </div>
          ),
          name: doc.data().name,
          date_range: (
            <div>{`${doc.data().startDate} - ${doc.data().endDate}`}</div>
          ),
          time: doc.data().time,
          location: doc.data().city,
          description: doc.data().description,
          url: doc.data().url,
          uid: doc.data().uid,
        };
        //table rows var will have array of current objects with current uid if needed to match for deletion or edit
        //filter array and then set table state
        tableRows.rows.push(event);
      });

      setTable({ ...tableColumns, ...tableRows });

    } catch(error) {
      console.log(error);

    } finally {
      setIsLoading(false);
    }
  
  };

  useEffect(() => {
    console.log('refreshed')
    getAdminEvents();
  }, [refresh]);

  return (
    <div className="loader">
      { isLoading &&
        <Loader type="spinner-cub" bgColor={"#43BC93"} title={"spinner-circle"} size={100} />
      }
      <MDBDataTable
        entries={8}
        entriesOptions={[8]}
        noBottomColumns
        fixed
        className='col-xl-10'
        data={table}
      />
    </div>
  );
};

export default Table;
