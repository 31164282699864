import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import OnboardStepOne from './OnboardStepOne/index'
import OnboardStepTwo from './OnboardStepTwo/index'
import OnboardStepThree from './OnboardStepThree/index'
import AddImageModal from './AddImageModal/index'

const defaultCoachObject = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  state: "",
  city: "",
  zipCode: "",
  profileImageUrl: "",
  introductionVideoUrl: "",
  coachDescription: "",
  coachDisciplines: [],
  inPersonRates: [],
  videoRates: [],
  daysAvailable: {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
},
}

const ManageCoaches = () => {
  const [stepTwo, setStepTwo] = useState(false)
  const [stepThree, setStepThree] = useState(false)
  const [completeForm, setCompleteForm] = useState(false)
  const [onboardStepOneShow, setOnboardStepOneShow] = useState(false)
  const [onboardStepTwoShow, setOnboardStepTwoShow] = useState(false)
  const [addImageModalShow, setAddImageModalShow] = useState(false)
  const [onboardStepThreeShow, setOnboardStepThreeShow] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [coachObject, setCoachObject] = useState(defaultCoachObject)
  const [image, setImage] = useState(null)
  const [imagePreview, setImagePreview] = useState(null);

  const handleCloseStepOne = () => {
    setOnboardStepOneShow(false)
    setCoachObject(defaultCoachObject)
    setImage(null)
    setImagePreview(null)
    setCurrentStep(1)
  }
  const handleCloseStepTwo = () => {
    setOnboardStepTwoShow(false)
    setCoachObject(defaultCoachObject)
    setImage(null)
    setImagePreview(null)
    setCurrentStep(1)
  }
  const handleCloseAddImage = () => {
    setAddImageModalShow(false)
    setOnboardStepTwoShow(true)
    setImage(null)
    setImagePreview(null)
    // setCoachObject(defaultCoachObject)
    // setCurrentStep(1)
  }
  const handleSaveImageModal = () => {
    setAddImageModalShow(false)
    setOnboardStepTwoShow(true)
  }
  const handleCloseStepThree = () => {
    setOnboardStepThreeShow(false)
    setCoachObject(defaultCoachObject)
    setImage(null)
    setImagePreview(null)
    setCurrentStep(1)
  }
  const handleNextStep = () => {
    switch(currentStep) {
      case 1: 
      console.log(coachObject)
        setOnboardStepOneShow(false)
        setOnboardStepTwoShow(true)
        setCurrentStep(2)
        break;
      case 2: 
        setOnboardStepTwoShow(false)
        setOnboardStepThreeShow(true)
        setCurrentStep(3)
        break;
      case 3: 
        console.log("done")
        setOnboardStepThreeShow(false)
        setCoachObject(defaultCoachObject)
        setCurrentStep(1)
        break;
      default: 
        console.log("invalid option")
    }
  }
  const handleBackStep = () => {
    switch(currentStep) {
      case 1: 
        setOnboardStepOneShow(false)
        break;
      case 2: 
        setOnboardStepOneShow(true)
        setOnboardStepTwoShow(false)
        setCurrentStep(1)
        break;
      case 3: 
        setOnboardStepTwoShow(true)
        setOnboardStepThreeShow(false)
        setCurrentStep(2)
        break;
      default: 
        console.log("invalid option")
    }
  }

  useEffect(() => {
    var hasTrueKeys = Object.keys(coachObject.daysAvailable).some(key => coachObject.daysAvailable[key]);
    // console.log("days", hasTrueKeys)
    if(
      coachObject.firstName &&
      coachObject.lastName &&
      coachObject.phoneNumber &&
      coachObject.address &&
      coachObject.state &&
      coachObject.city &&
      coachObject.zipCode
    ) {
      setStepTwo(true)
    } else {
      setStepTwo(false)
    }
    if(coachObject.coachDescription) {
      setStepThree(true)
    } else {
      setStepThree(false)
    }
    if((coachObject.inPersonRates.length > 0 || coachObject.videoRates.length > 0) && hasTrueKeys) {
      setCompleteForm(true)
    } else {
      setCompleteForm(false)
    }
  }, [coachObject])

  return (
    <Container className='col-12 h-100'>
      <Row className="pl-5 w-100 h-25 align-items-start justify-content-start">
        <Row className='d-flex w-100 pt-5 justify-content-center align-items-center checkedText'>
          <h3>Mangage Coaches</h3>
        </Row>
        <Row  className="w-100 align-items-center justify-content-start">
          <Col className="d-flex align-items-center text-center isClickable justify-content-center" sm={2}>
            <h5 className="text-black-50" onClick={()=>{console.log("News")}}>NEWS FEED</h5>
          </Col>
          <span style={{ marginHorizontal: '25px' }}>|</span>
          <Col className="d-flex align-items-center text-center isClickable justify-content-center" sm={2}>
            <h5 className="text-black-50" onClick={()=>{setOnboardStepOneShow(true)}}>ADD COACH</h5>
          </Col>
        </Row>
      </Row>
      <OnboardStepOne
        backdrop="static"
        show={onboardStepOneShow}
        onHide={() => handleCloseStepOne()}
        coachObject={coachObject}
        setCoachObject={setCoachObject}
        handleNextStep={() => {handleNextStep()}}
        handleBackStep={() => {handleBackStep()}}
        stepTwo={stepTwo}
      />
      <OnboardStepTwo
        backdrop="static"
        show={onboardStepTwoShow}
        onHide={() => handleCloseStepTwo()}
        closeStepTwo={() => setOnboardStepTwoShow(false)}
        coachObject={coachObject}
        setCoachObject={setCoachObject}
        setAddImageModalShow={setAddImageModalShow}
        handleNextStep={() => {handleNextStep()}}
        handleBackStep={() => {handleBackStep()}}
        imagePreview={imagePreview}
        stepThree={stepThree}
      />
      <AddImageModal
        backdrop="static"
        show={addImageModalShow}
        onHide={() => handleCloseAddImage()}
        closeImageModal={() => setAddImageModalShow(false)}
        handleSaveImageModal={handleSaveImageModal}
        coachObject={coachObject}
        setCoachObject={setCoachObject}
        image={image}
        setImage={setImage}
        imagePreview={imagePreview}
        setImagePreview={setImagePreview}
      />
      <OnboardStepThree
        backdrop="static"
        show={onboardStepThreeShow}
        onHide={() => handleCloseStepThree()}
        closeStepTwo={() => handleCloseStepTwo()}
        coachObject={coachObject}
        setCoachObject={setCoachObject}
        handleNextStep={() => {handleNextStep()}}
        handleBackStep={() => {handleBackStep()}}
        completeForm={completeForm}
      />
    </Container>
  );
};

export default ManageCoaches;
