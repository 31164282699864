import * as React from 'react';

export default function SvgFinances(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400 400'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <path
        id='prefix__Path_1167'
        data-name='Path 1167'
        className='prefix__cls-1'
        d='M199.81 349.24c-82.27.55-149.4-66.39-149.94-149.49C49.37 122.86 106.33 58 182 49.25a5.13 5.13 0 011.3 10.14C107.11 68.68 52.84 138.56 62 215.48s78.38 131.75 154.52 122.45S347 258.75 337.78 181.84c-7.67-64.15-57.72-114.71-121.22-122.45a5.14 5.14 0 01-3.86-6.12 5.08 5.08 0 015.07-4c81.74 9.42 140.45 84 131.12 166.57-8.62 76.39-72.87 133.93-149 133.42'
      />
      <g id='prefix__Group_559' data-name='Group 559'>
        <path
          id='prefix__Path_1160'
          data-name='Path 1160'
          className='prefix__cls-1'
          d='M196.41 264.31v-12.93a47.84 47.84 0 01-15.76-4.33A27.49 27.49 0 01170.18 237a36.11 36.11 0 01-5.15-17l13-2.44a33 33 0 005.35 15.3 18.92 18.92 0 0013.07 7.69v-41.23a53.83 53.83 0 01-16.31-6.18 23.84 23.84 0 01-9.52-9.55 30.43 30.43 0 016.43-36.2 33.92 33.92 0 0119.4-7v-6.18h7.6v6.18A31.9 31.9 0 01221.9 147a29.37 29.37 0 0110.2 19.32l-13.36 2a22.18 22.18 0 00-4.74-11.7 17.57 17.57 0 00-9.94-5.35v37.27a102.23 102.23 0 0112.92 3.8 31.75 31.75 0 019.84 6.46 26.12 26.12 0 015.85 9 30.78 30.78 0 012.05 11.35 31 31 0 01-30.66 32v13.07zm0-113.2a19.13 19.13 0 00-11.88 6 16.8 16.8 0 00-4.36 11.57 16.64 16.64 0 003.7 11.06 25.48 25.48 0 0012.49 7.14zm7.59 89.35a19.17 19.17 0 0012.49-6.53 20.24 20.24 0 004.93-13.87 17.36 17.36 0 00-3.57-11.32 30.2 30.2 0 00-13.85-7.66z'
        />
      </g>
    </svg>
  );
}
