import * as React from 'react';

export default function SvgStudent(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400 400'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <path
        id='prefix__Path_1167'
        data-name='Path 1167'
        className='prefix__cls-1'
        d='M199.94 345.58c-82.27.54-149.4-66.39-149.94-149.5-.49-76.88 56.46-141.78 132.08-150.49a5.13 5.13 0 011.31 10.13C107.24 65 53 134.9 62.17 211.82s78.38 131.74 154.53 122.45 130.41-79.18 121.22-156.1C330.25 114 280.2 63.47 216.7 55.72a5.13 5.13 0 011.2-10.13C299.65 55 358.35 129.58 349 212.16c-8.63 76.39-72.88 133.92-149 133.42'
      />
      <path
        className='prefix__cls-1'
        d='M272.35 221.09v-11.8c-.11-5.64 0-11.28-.62-16.92-.28-2.55.36-3.38 2.54-4.24 5.43-2.18 10.75-4.67 16.12-7l-.13-3.59a21.27 21.27 0 00-4.13-2.33q-41.61-12.83-83.29-25.39a10.52 10.52 0 00-5.73 0q-41.85 12.59-83.59 25.56a6.54 6.54 0 00-3.76 3.66c-.77 2.41 1.52 3.5 3.55 4.29 9.7 3.75 19.36 7.63 29.09 11.27a4.2 4.2 0 013.35 4.68c-.12 8.1 0 16.22-.12 24.32a12 12 0 004.44 10.4 60.33 60.33 0 0014.51 8.49 115.2 115.2 0 0058.86 3.35 60.22 60.22 0 0021.74-7.84 16.9 16.9 0 009.17-16.27c-.26-5.53 0-11 0-16.67 0-9.23 0-9.23 8.55-12.48a7.13 7.13 0 011.2-.19 13.59 13.59 0 01.59 2.69c.15 10.07.32 20.12.32 30.2 0 1-1.06 1.9-1.41 2.91-.75 2.11-2.35 4.47-1.88 6.35 1.75 6.84 4.19 13.5 6.37 20.2h1.26c2.16-6.4 4.49-12.77 6.37-19.28a6.92 6.92 0 00-1-5.84 10 10 0 01-2.37-8.53zm-25.76 3.36c-.11 4-3.36 5.85-6.33 7.73a57.66 57.66 0 01-21.62 7.1 107.54 107.54 0 01-52.64-3.91 38.74 38.74 0 01-3.75-1.6 14.07 14.07 0 01-9.5-15.16c.59-6.28.12-12.67.12-19.62 5.43 2 10.24 3.85 15 5.73 9.27 3.63 18.55 7.22 27.76 11a10.74 10.74 0 008.64 0c14-5.61 28-11.1 42.53-16.74.07 8.88.2 17.17-.21 25.47zm16.1-42.53a155.72 155.72 0 00-22.21-3.49c-12.25-1.64-24.53-3.16-36.78-4.74a13.8 13.8 0 00-1.71-.24c-2.52-.26-4.86.07-5.33 3-.51 3.29 2 4.12 4.52 4.49 4 .61 8 1 12 1.53l38.49 4.94a7.12 7.12 0 011.62.84l-32.87 12.84c-6 2.37-12 4.77-18.12 7a6.15 6.15 0 01-4 .19q-35.55-13.86-71.09-27.78a19 19 0 01-1.88-1l18.67-5.62c17.68-5.39 35.38-10.81 53.11-16.12a9.18 9.18 0 015-.25q35.07 10.52 70 21.28c.62.18 1.24.43 2.87 1-4.58 1.53-7.57 3.49-12.29 2.13z'
      />
    </svg>
  );
}
