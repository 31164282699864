import React, {useState} from 'react'
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { db, auth } from '../../firebase'
import {Login} from './Login'
import {SignUp} from './SignUp'
import {ForgotPassword} from './ForgotPassword'


export const LoginModal = ({loginOpen, setLoginOpen, isLogin, setIsLogin, setLoggedIn}) => {
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    const [credentials, setCredentials] = useState({
      email: '',
      username: '',
      password: '',
      passwordConfirm: '',
      hometown: '',
      phoneNumber: '',
    })

    const handleCredentials = (event) => {
      setCredentials({ ...credentials, [event.target.name]: event.target.value })
      // console.log(credentials)
    };


    const handleClose = () => {
        loginOpen && setLoginOpen(false)
    }

    const handleIsLogin = () => {
        setCredentials({})
        isLogin ? setIsLogin(false) : setIsLogin(true)
    }

    const handleForgotPassword = () => {
        setCredentials({})
        setIsForgotPassword(false)
    }

  return (
    <Modal show={loginOpen} onHide={() => {setLoginOpen(false)}}>
      <Modal.Header>
      {isForgotPassword 
      ? <h3>Forgot Password</h3>
      : isLogin
      ? <h3>Log In</h3>
      : <h3>Get Started Today!</h3>}
        <button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
        <Modal.Body>
          {isForgotPassword
          ? <ForgotPassword credentials={credentials} handleCredentials={handleCredentials}/>
          : isLogin 
            ? <Login credentials={credentials} handleCredentials={handleCredentials} setLoginOpen={setLoginOpen} setIsForgotPassword={setIsForgotPassword} setLoggedIn={setLoggedIn}/>
            : <SignUp credentials={credentials} setCredentials={setCredentials} handleCredentials={handleCredentials} setLoginOpen={setLoginOpen} setLoggedIn={setLoggedIn}/> }
        </Modal.Body>
      {isForgotPassword
      && <Modal.Footer>Go back to <a onClick={handleForgotPassword} href="#">Log In</a></Modal.Footer>
      // : isLogin 
      // ?
      //   <Modal.Footer>Need an account? <a onClick={handleIsLogin} href="#">Sign Up</a></Modal.Footer>
      // :
      //   <Modal.Footer>Already have an account? <a onClick={handleIsLogin} href="#">Log In</a></Modal.Footer>
      }
    </Modal>
  )
}